import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
// REDUX
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from '../utils/sliceHelpers';
export const initialState = {
    loading: false,
    hasErrors: false,
    allLanguages: [],
    dateFormats: []
};
// Slice
const slice = createSlice({
    name: 'languages',
    initialState,
    reducers: {
        fetchLanguagesRequest: state => {
            isLoadingRequest(state);
        },
        fetchLanguagesSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.allLanguages = payload.results;
            finishedLoadingSuccess(state);
        },
        fetchDateFormatsSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            state.dateFormats = payload.results;
            finishedLoadingSuccess(state);
        },
        fetchLanguagesFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const languagesLoading = state => state.languages.loading;
export const languagesSelector = state => state.languages.allLanguages;
export const dateFormatsSelector = state => state.languages.dateFormats;
// Actions
export const { fetchLanguagesRequest, fetchLanguagesSuccess, fetchLanguagesFailure, fetchDateFormatsSuccess } = slice.actions;
export const fetchLanguages = () => async (dispatch) => {
    try {
        dispatch(fetchLanguagesRequest());
        const languages = await api.get({
            endpoint: `/languages`
        });
        dispatch(fetchLanguagesSuccess(languages));
    }
    catch (e) {
        dispatch(fetchLanguagesFailure());
        return console.error(e);
    }
};
export const fetchDateFormats = () => async (dispatch) => {
    try {
        dispatch(fetchLanguagesRequest());
        const languages = await api.get({
            endpoint: `/languages?type=DATE_FORMAT`
        });
        dispatch(fetchDateFormatsSuccess(languages));
    }
    catch (e) {
        dispatch(fetchLanguagesFailure());
        return console.error(e);
    }
};
