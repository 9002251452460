export const maxReservationObjectOptions = [{
        label: '25 (Low)',
        value: 25
    }, {
        label: '50 (Normal)',
        value: 50
    }, {
        label: '75 (High)',
        value: 75
    }, {
        label: '100 (Extreme)',
        value: 100
    }, {
        label: '125 (Wild thing)',
        value: 125
    }, {
        label: "127 (You've got to be kidding me)",
        value: 127
    }];
export const maxImportFieldValuesOptions = [{
        label: '15 (Normal)',
        value: 15
    }, {
        label: '20',
        value: 20
    }, {
        label: '30',
        value: 30
    }, {
        label: '40',
        value: 40
    }, {
        label: '50',
        value: 50
    }];
export const maxEmailMemberOptions = [{
        label: '50 (Low)',
        value: 50
    }, {
        label: '100 (Normal)',
        value: 100
    }, {
        label: '300 (High)',
        value: 300
    }, {
        label: '500 (Extreme)',
        value: 500
    }];
