export const renderNumber = (num, str) => {
    if (typeof num === 'undefined' || Number.isNaN(num))
        return str;
    return num;
};
export const matchString = (text, query) => {
    if (!query)
        return true;
    if (!text)
        return false;
    return text.toLowerCase().includes(query.toLowerCase());
};
export const arrayIncludes = (list, query) => {
    return list.some(item => item === query);
};
