import { createSlice } from '@reduxjs/toolkit';
import { createEmptyPeriod } from '@timeedit/types';
import { compact, keyBy } from 'lodash';
import { createAppAsyncThunk } from 'slices/utils';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const initialState = {
    loading: false,
    hasErrors: false,
    allPeriods: [],
    map: {},
    firstDrawer: null,
    secondDrawer: null
};
// Slice
const slice = createSlice({
    name: 'periods',
    initialState,
    reducers: {
        fetchPeriodsRequest: state => {
            isLoadingRequest(state);
        },
        fetchPeriodsSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.allPeriods = payload.results;
            state.map = keyBy(payload.results, 'id');
            finishedLoadingSuccess(state);
        },
        fetchPeriodsFailure: state => {
            finishedLoadingFailure(state);
        },
        createPeriodRequest: state => {
            isLoadingRequest(state);
        },
        createPeriodFailure: state => {
            finishedLoadingFailure(state);
        },
        deletePeriodRequest: state => {
            isLoadingRequest(state);
        },
        deletePeriodSuccess: (state, _ref2) => {
            let { payload: periodId } = _ref2;
            state.allPeriods = state.allPeriods.filter(period => period.id !== periodId);
            finishedLoadingSuccess(state);
        },
        deletePeriodFailure: state => {
            finishedLoadingFailure(state);
        },
        createUnsavedPeriod: state => {
            if (state.firstDrawer?.id === 0)
                return;
            const newPeriod = createEmptyPeriod();
            state.firstDrawer = newPeriod;
            state?.allPeriods?.unshift(newPeriod);
            state.map[0] = newPeriod;
        },
        changeDataDrawer: (state, _ref3) => {
            let { payload } = _ref3;
            const { isFirstDrawer, key, value } = payload;
            if (isFirstDrawer) {
                state.firstDrawer = {
                    ...state.firstDrawer,
                    [key]: value
                };
            }
            else {
                state.secondDrawer = {
                    ...state.secondDrawer,
                    [key]: value
                };
            }
        },
        saveSuccess: (state, _ref4) => {
            let { payload: isFirstDrawer } = _ref4;
            if (isFirstDrawer === true) {
                state.firstDrawer = undefined;
            }
            if (isFirstDrawer === false) {
                state.secondDrawer = undefined;
            }
            finishedLoadingSuccess(state);
        },
        discardChanges: (state, _ref5) => {
            let { payload } = _ref5;
            const { isFirstDrawer, id } = payload;
            if (isFirstDrawer) {
                state.firstDrawer = state.map[id];
            }
            else {
                state.secondDrawer = state.map[id];
            }
        },
        setFirstDrawer: (state, _ref6) => {
            let { payload } = _ref6;
            const previousFirstDrawer = state.firstDrawer;
            state.firstDrawer = payload;
            if (!payload && previousFirstDrawer?.id === 0) {
                state.allPeriods = state.allPeriods.filter(p => p.id !== 0);
            }
        },
        setSecondDrawer: (state, _ref7) => {
            let { payload } = _ref7;
            state.secondDrawer = payload;
        },
        duplicatePeriod: (state, _ref8) => {
            let { payload } = _ref8;
            if (state.firstDrawer?.id === 0)
                return;
            const newItem = {
                ...payload,
                id: 0,
                name: payload.name + '-copy',
                history: []
            };
            state.firstDrawer = newItem;
            state.allPeriods.unshift(newItem);
            state.map[0] = newItem;
        }
    }
});
export default slice.reducer;
// Selectors
export const periodsLoading = state => state.periods.loading;
export const periodsSelector = state => state.periods.allPeriods;
export const periodsMapSelector = state => state.periods.map;
export const firstDrawerSelector = state => state.periods.firstDrawer;
export const secondDrawerSelector = state => state.periods.secondDrawer;
export const selectedIdsSelector = state => compact([state?.periods?.firstDrawer, state?.periods?.secondDrawer]).map(pr => pr.id);
// Actions
export const { fetchPeriodsRequest, fetchPeriodsSuccess, fetchPeriodsFailure, createPeriodRequest, createPeriodFailure, deletePeriodRequest, deletePeriodSuccess, deletePeriodFailure, createUnsavedPeriod, changeDataDrawer, saveSuccess, discardChanges, setFirstDrawer, setSecondDrawer, duplicatePeriod } = slice.actions;
export const fetchPeriods = createAppAsyncThunk('periods/fetchPeriods', async (_, _ref9) => {
    let { dispatch } = _ref9;
    try {
        dispatch(fetchPeriodsRequest());
        const periods = await api.get({
            endpoint: '/periods'
        });
        dispatch(fetchPeriodsSuccess(periods));
    }
    catch (e) {
        dispatch(fetchPeriodsFailure());
        return console.error(e);
    }
});
export const savePeriod = createAppAsyncThunk('periods/savePeriod', async (_ref10, _ref11) => {
    let { isFirstDrawer, period } = _ref10;
    let { dispatch } = _ref11;
    try {
        dispatch(createPeriodRequest());
        const { id, history, intervals, ...periodBody } = period;
        const bodySave = periodBody;
        if (intervals && intervals.length > 0) {
            bodySave.intervals = intervals;
        }
        if (id === 0) {
            await api.post({
                endpoint: `/periods`,
                data: bodySave
            });
        }
        else {
            await api.patch({
                endpoint: `/periods/${period.id}`,
                data: bodySave
            });
        }
        dispatch(saveSuccess(isFirstDrawer));
        dispatch(fetchPeriods());
    }
    catch (e) {
        dispatch(createPeriodFailure());
        return console.error(e);
    }
});
export const deletePeriod = createAppAsyncThunk('periods/deletePeriod', async (_ref12, _ref13) => {
    let { isFirstDrawer, periodId } = _ref12;
    let { dispatch } = _ref13;
    try {
        dispatch(deletePeriodRequest());
        await api.delete({
            endpoint: `/periods/${periodId}`
        });
        dispatch(deletePeriodSuccess(periodId));
        dispatch(saveSuccess(isFirstDrawer));
    }
    catch (e) {
        dispatch(deletePeriodFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const checkPeriodsSaveDisabled = isFirstDrawer => (_, getState) => {
    const { periods } = getState();
    const { firstDrawer, secondDrawer, map: periodsMap } = periods;
    if (isFirstDrawer && firstDrawer && periodsMap[firstDrawer.id]) {
        return checkSavedDisabled(firstDrawer, periodsMap[firstDrawer.id]);
    }
    if (!isFirstDrawer && secondDrawer && periodsMap[secondDrawer.id]) {
        return checkSavedDisabled(secondDrawer, periodsMap[secondDrawer.id]);
    }
    return true;
};
export const checkFirstDrawerExist = () => (_, getState) => !!getState().periods.firstDrawer;
