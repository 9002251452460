export const EXAM_CONFIG_FIELD_DROPDOWN_TYPE = {
    SOURCE: 'source',
    MODE: 'mode',
    FIELD: 'field'
};
export const EXAM_CONFIGURATION_FIELD_MODE = {
    OBJECT_TYPES: 'OBJECT_TYPES',
    RESERVATION_MODE: 'RESERVATION_MODE'
};
const SELECT_SOURCE_FOR_TYPE = {
    [EXAM_CONFIGURATION_FIELD_MODE.OBJECT_TYPES]: 'Object Types',
    [EXAM_CONFIGURATION_FIELD_MODE.RESERVATION_MODE]: 'Reservation mode'
};
export const renderDefaultSelectField = Object.entries(SELECT_SOURCE_FOR_TYPE).map(_ref => {
    let [key, value] = _ref;
    return {
        value: key,
        label: value
    };
});
