import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import { createAppAsyncThunk } from 'slices/utils';
export const initialState = {
    loading: false,
    hasErrors: false,
    dataSource: {}
};
const slice = createSlice({
    name: 'appsReportSourceType',
    initialState,
    reducers: {
        fetchData: state => {
            isLoadingRequest(state);
        },
        fetchDataSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.dataSource = payload;
            finishedLoadingSuccess(state);
        },
        fetchDataFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const reportSourceTypeLoading = state => state.appsReportSourceType.loading;
export const reportSourceTypeDataSource = state => state.appsReportSourceType.dataSource;
// Actions
export const { fetchData, fetchDataSuccess, fetchDataFailure } = slice.actions;
export const getDataSource = createAppAsyncThunk('appsReportSourceType/getDataSource', async (_, _ref2) => {
    let { dispatch } = _ref2;
    try {
        dispatch(fetchData());
        const data = await api.get({
            endpoint: '/apps/TE_REPORTING/data-sources'
        });
        dispatch(fetchDataSuccess(data));
    }
    catch (e) {
        dispatch(fetchDataFailure());
        return console.error(e);
    }
});
export const updateDataSource = createAppAsyncThunk('appsReportSourceType/updateDataSource', async (data, _ref3) => {
    let { dispatch } = _ref3;
    try {
        await api.patch({
            endpoint: '/apps/TE_REPORTING/data-sources',
            data
        });
        dispatch(getDataSource());
    }
    catch (e) {
        dispatch(fetchDataFailure());
        return console.error(e);
    }
});
