import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, stateHasFetchedAllObjectsSuccess, upsertEntity } from 'utils/sliceHelpers';
import { createImport } from '@timeedit/types';
import { EImportType } from '@timeedit/types/lib/enums/import.enum';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0
};
// Slice
const slice = createSlice({
    name: 'imports',
    initialState,
    reducers: {
        fetchImportsRequest: state => {
            isLoadingRequest(state);
        },
        fetchImportsSuccess: (state, _ref) => {
            let { payload } = _ref;
            stateHasFetchedAllObjectsSuccess(payload, state, createImport);
            finishedLoadingSuccess(state);
        },
        fetchImportsFailure: state => {
            finishedLoadingFailure(state);
        },
        saveImportRequest: state => {
            isLoadingRequest(state);
        },
        saveImportSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            upsertEntity(state, payload, createImport);
            finishedLoadingSuccess(state);
        },
        saveImportFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const importsLoading = state => state.imports.loading;
export const importsSelector = state => state.imports.results.slice().reverse();
// Actions
export const { fetchImportsRequest, fetchImportsSuccess, fetchImportsFailure, saveImportRequest, saveImportSuccess, saveImportFailure } = slice.actions;
export const fetchImports = importType => async (dispatch) => {
    try {
        dispatch(fetchImportsRequest());
        const result = await api.get({
            endpoint: getEndpointUrlFromType(importType)
        });
        dispatch(fetchImportsSuccess(result));
    }
    catch (e) {
        dispatch(fetchImportsFailure());
        console.error(e);
    }
};
export const saveImport = (type, imp) => async (dispatch) => {
    try {
        dispatch(saveImportRequest());
        const savedImport = await api.post({
            endpoint: getEndpointUrlFromType(type),
            data: imp,
            errorMessage: 'The import failed. Please inspect the log to see the detailed error message'
        });
        dispatch(saveImportSuccess(savedImport));
    }
    catch (error) {
        console.error(error);
        dispatch(saveImportFailure());
        dispatch(fetchImports(type));
    }
};
const getEndpointUrlFromType = importType => {
    switch (importType) {
        case EImportType.ORGANIZATION_HIERARCHY:
            return `/imports/organizations`;
        case EImportType.AUTH_CONFIGS:
            return `/imports/auth-configs`;
        case EImportType.USERS:
            return `/imports/users`;
        default:
            return '';
    }
};
