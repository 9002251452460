import { createSlice } from '@reduxjs/toolkit';
import { EAppId } from '@timeedit/types';
import _, { groupBy } from 'lodash';
import { createAppAsyncThunk } from 'slices/utils';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const initialState = {
    loading: false,
    hasErrors: false,
    appId: EAppId.TE_EXAM,
    relationships: [],
    mappedRelationship: {},
    objectMappings: {
        appId: EAppId.TE_EXAM,
        objectTypes: []
    }
};
// Slice
const slice = createSlice({
    name: 'objectRelationships',
    initialState,
    reducers: {
        fetchObjectRelationshipsRequest: state => {
            isLoadingRequest(state);
        },
        fetchObjectRelationshipsSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.relationships = payload.relationships;
            const groupedRelationships = groupBy(payload.relationships, 'from');
            state.mappedRelationship = Object.keys(groupedRelationships).reduce((results, field) => {
                return {
                    ...results,
                    [field]: groupedRelationships[field].map(_ref2 => {
                        let { to } = _ref2;
                        return to;
                    })
                };
            }, {});
            finishedLoadingSuccess(state);
        },
        fetchObjectMappingSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            state.objectMappings = payload;
            finishedLoadingSuccess(state);
        },
        fetchObjectRelationshipsFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const objectRelationshipsSelector = state => state.objectRelationships.relationships;
export const mappingObjectTypesSelector = state => state.objectRelationships.objectMappings.objectTypes;
export const objectRelationshipsLoading = state => state.objectRelationships.loading;
// Actions
export const { fetchObjectRelationshipsRequest, fetchObjectRelationshipsSuccess, fetchObjectRelationshipsFailure, fetchObjectMappingSuccess } = slice.actions;
export const fetchObjectRelationships = createAppAsyncThunk('objectRelationships/fetchObjectRelationships', async (appId, _ref4) => {
    let { dispatch } = _ref4;
    try {
        dispatch(fetchObjectRelationshipsRequest());
        const result = await api.get({
            endpoint: `/apps/${appId}/object-relationships`
        });
        dispatch(fetchObjectRelationshipsSuccess(result));
    }
    catch (e) {
        dispatch(fetchObjectRelationshipsFailure());
        console.error(e);
    }
});
export const fetchObjectMapping = createAppAsyncThunk('objectRelationships/fetchObjectMapping', async (appId, _ref5) => {
    let { dispatch } = _ref5;
    try {
        dispatch(fetchObjectRelationshipsRequest());
        const result = await api.get({
            endpoint: `/apps/${appId}/mapping`
        });
        dispatch(fetchObjectMappingSuccess(result));
    }
    catch (e) {
        dispatch(fetchObjectRelationshipsFailure());
        console.error(e);
    }
});
export const saveRelationships = createAppAsyncThunk('objectRelationships/saveRelationships', async (_ref6, _ref7) => {
    let { appId, relationships } = _ref6;
    let { dispatch } = _ref7;
    try {
        dispatch(fetchObjectRelationshipsRequest());
        const payload = relationships.map(re => _.omit(re, '_id'));
        const result = await api.patch({
            endpoint: `/apps/${appId}/object-relationships`,
            data: {
                relationships: payload
            }
        });
        dispatch(fetchObjectRelationshipsSuccess(result));
    }
    catch (e) {
        dispatch(fetchObjectRelationshipsFailure());
        console.error(e);
    }
});
