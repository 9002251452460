export const configs = {
    development: {
        REACT_APP_BASE_URL: 'http://localhost:3010/v1',
        REACT_APP_AUTH_DOMAIN: 'http://localhost:3000',
        REACT_APP_PREFERENCE_SERVICE_URL: 'https://new-beta-preferences.timeedit.io/',
        REACT_APP_PATHWAY_SERVICE_URL: 'https://pathways-beta.timeedit.io/',
        REACT_APP_UNIVERSAL_COOKIE_PATHNAME: '/',
        CLIENT_ID: 'teAdmin',
        APP_NAME: 'teAdmin',
        VIEWS_URL: `https://views.timeedit.io/v1`,
        NOTIFICATION_KEY: 'TE_ADMIN_NOTIFICATION',
        LAUNCH_DARKLY_CLIENT_ID: '65e829cc7386bf105e2e28d5'
    },
    beta: {
        REACT_APP_BASE_URL: 'https://api.timeedit.dev/v1',
        REACT_APP_AUTH_DOMAIN: 'https://auth.timeedit.dev',
        REACT_APP_AUTH_FALLBACK_DOMAIN: 'https://auth.timeedit.dev',
        REACT_APP_PREFERENCE_SERVICE_URL: 'https://new-beta-preferences.timeedit.io/',
        REACT_APP_PATHWAY_SERVICE_URL: 'https://pathways-beta.timeedit.io/',
        REACT_APP_UNIVERSAL_COOKIE_PATHNAME: 'timeedit.io',
        CLIENT_ID: 'teAdmin',
        APP_NAME: 'teAdmin',
        VIEWS_URL: `https://views-beta.timeedit.io/v1`,
        NOTIFICATION_KEY: 'TE_ADMIN_NOTIFICATION',
        LAUNCH_DARKLY_CLIENT_ID: '65e829cc7386bf105e2e28d5'
    },
    staging: {
        REACT_APP_BASE_URL: 'https://api.timeedit.io/v1',
        REACT_APP_AUTH_DOMAIN: 'https://auth.timeedit.io',
        REACT_APP_PREFERENCE_SERVICE_URL: 'https://new-preferences.timeedit.io/',
        REACT_APP_PATHWAY_SERVICE_URL: 'https://pathways.timeedit.io/',
        REACT_APP_UNIVERSAL_COOKIE_PATHNAME: 'timeedit.io',
        CLIENT_ID: 'teAdmin',
        APP_NAME: 'teAdmin',
        VIEWS_URL: `https://views.timeedit.com/v1`,
        NOTIFICATION_KEY: 'TE_ADMIN_NOTIFICATION',
        LAUNCH_DARKLY_CLIENT_ID: '65e829836880eb10b9f4edca'
    },
    production: {
        REACT_APP_BASE_URL: 'https://api.timeedit.net/v1',
        REACT_APP_AUTH_DOMAIN: 'https://auth.timeedit.net',
        REACT_APP_PREFERENCE_SERVICE_URL: 'https://preferences.timeedit.net/',
        REACT_APP_PATHWAY_SERVICE_URL: 'https://pathways.timeedit.net/',
        REACT_APP_UNIVERSAL_COOKIE_PATHNAME: 'timeedit.net',
        CLIENT_ID: 'teAdmin',
        APP_NAME: 'teAdmin',
        VIEWS_URL: `https://views.timeedit.com/v1`,
        NOTIFICATION_KEY: 'TE_ADMIN_NOTIFICATION',
        LAUNCH_DARKLY_CLIENT_ID: '65a938f0183d141061d386f9'
    }
};
