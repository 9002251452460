/**
 * @function finishedLoadingFailure
 * @description standardized way of indicating API call has finished loading with failure in redux state
 * @param {IDefaultReducerState} state
 * @returns {void}
 */
export const finishedLoadingFailure = state => {
    state.loading = false;
    state.hasErrors = true;
};
