import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    loading: false,
    hasErrors: false,
    routes: []
};
const slice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        updateNavigation: (state, _ref) => {
            let { payload } = _ref;
            state.routes = payload;
        }
    }
});
export const { updateNavigation } = slice.actions;
export const navigationSelector = state => state.navigation.routes;
export default slice.reducer;
