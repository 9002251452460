import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { organizationSelector } from 'slices/organization.slice';
import { useAppSelector } from 'slices/store';
import { parseCustomerSignature } from 'utils/customerSignature';
export function useCustomerNavigate() {
    const navigate = useNavigate();
    const location = useLocation();
    const organization = useAppSelector(organizationSelector);
    const customerSignature = organization?.customerSignature;
    return useCallback((to, options) => {
        if (!customerSignature) {
            return navigate(to, options);
        }
        const customerSignaturePrefix = `/${parseCustomerSignature(customerSignature)}`;
        const adminStartPage = `${customerSignaturePrefix}/configuration/general-settings`;
        if (typeof to === 'string') {
            const pathname = to.startsWith(customerSignaturePrefix) ? to : `${customerSignaturePrefix}${to}`;
            // Path for which route does not exist, go back to admin default page
            if (pathname === location.pathname) {
                return navigate(adminStartPage);
            }
            return navigate(pathname, options);
        }
        if (!to.pathname) {
            return navigate(to, options);
        }
        const pathname = to.pathname.startsWith(customerSignaturePrefix) ? to.pathname : `${customerSignaturePrefix}${to.pathname}`;
        // Path for which route does not exist, go back to admin default page
        if (pathname === location.pathname) {
            return navigate(adminStartPage);
        }
        return navigate({
            ...to,
            pathname
        }, options);
    }, [customerSignature, location.pathname, navigate]);
}
