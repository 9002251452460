import { createSlice } from '@reduxjs/toolkit';
import { createOrganization } from '@timeedit/types';
import { createAppAsyncThunk } from 'slices/utils';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, prepEntityForAPICall, upsertEntity } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0
};
const slice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        fetchOrganizationRequest: state => {
            isLoadingRequest(state);
        },
        fetchOrganizationSuccess: (state, _ref) => {
            let { payload } = _ref;
            const organization = createOrganization(payload);
            state.results = [organization];
            state.map[organization.id] = organization;
            finishedLoadingSuccess(state);
        },
        fetchOrganizationFailure: state => {
            finishedLoadingFailure(state);
        },
        saveOrganizationRequest: state => {
            isLoadingRequest(state);
        },
        saveOrganizationSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            upsertEntity(state, payload, createOrganization);
            finishedLoadingSuccess(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const organizationSelector = state => {
    return state.organizations.map[state.auth.user?.organizationId];
};
export const organizationLoadingSelector = state => {
    return state.organizations.loading;
};
// Actions
export const { fetchOrganizationRequest, fetchOrganizationSuccess, fetchOrganizationFailure, saveOrganizationRequest, saveOrganizationSuccess } = slice.actions;
export const fetchOrganization = createAppAsyncThunk('organizations/fetchOrganization', async (_, _ref3) => {
    let { dispatch, getState } = _ref3;
    try {
        const organizationId = getState().auth?.user?.organizationId;
        if (organizationId) {
            dispatch(fetchOrganizationRequest());
            const result = await api.get({
                endpoint: `/organizations/${organizationId}`
            });
            dispatch(fetchOrganizationSuccess(result));
        }
        else {
            dispatch(fetchOrganizationFailure());
        }
    }
    catch (e) {
        dispatch(fetchOrganizationFailure());
        console.error(e);
    }
});
export const saveOrganization = createAppAsyncThunk('organizations/saveOrganization', async (organization, _ref4) => {
    let { dispatch } = _ref4;
    try {
        dispatch(saveOrganizationRequest());
        const { inactiveSince, database, cluster, region, customerSignature, ...orgBody } = organization;
        const preppedOrganization = prepEntityForAPICall(orgBody, false, true);
        const updatedOrganization = await api.patch({
            endpoint: `/organizations/${organization.id}`,
            data: preppedOrganization
        });
        dispatch(saveOrganizationSuccess(updatedOrganization));
    }
    catch (error) {
        console.error(error);
    }
});
