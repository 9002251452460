import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { addEntityToState, deleteEntityFromState, finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, prepEntityForAPICall, stateHasFetchedAllObjectsSuccess, upsertEntity } from 'utils/sliceHelpers';
import { createAuthConfig as createTAuthConfig, EAuthStrategy } from '@timeedit/types';
import { notification } from 'antd';
import { configService } from 'services/config.service';
import { createAppAsyncThunk } from 'slices/utils';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0
};
// Slice
const slice = createSlice({
    name: 'authConfigs',
    initialState,
    reducers: {
        fetchAuthConfigsRequest: state => {
            isLoadingRequest(state);
        },
        fetchAuthConfigsSuccess: (state, _ref) => {
            let { payload } = _ref;
            stateHasFetchedAllObjectsSuccess(payload, state, createTAuthConfig);
            finishedLoadingSuccess(state);
        },
        fetchAuthConfigsFailure: state => {
            finishedLoadingFailure(state);
        },
        createAuthConfig: state => {
            addEntityToState(state, {
                id: 'new',
                name: 'New auth config',
                strategy: EAuthStrategy.SAML2
            }, createTAuthConfig);
            finishedLoadingSuccess(state);
        },
        saveAuthConfigRequest: state => {
            isLoadingRequest(state);
        },
        saveAuthConfigSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            upsertEntity(state, payload, createTAuthConfig);
            finishedLoadingSuccess(state);
        },
        saveAuthConfigFailure: state => {
            finishedLoadingFailure(state);
        },
        deleteAuthConfigRequest: state => {
            isLoadingRequest(state);
        },
        deleteAuthConfigSuccess: (state, _ref3) => {
            let { payload: authConfigId } = _ref3;
            deleteEntityFromState(authConfigId, state);
            finishedLoadingSuccess(state);
        },
        deleteAuthConfigFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const authConfigsLoading = state => state.authConfigs.loading;
export const authConfigsSelector = state => state.authConfigs.results;
export const authConfigSelector = id => state => id ? state.authConfigs.map[id] : undefined;
export const selectAuthStrategyForAuthConfig = id => state => {
    if (!id)
        return undefined;
    const authConfig = state.authConfigs.map[id];
    return authConfig && authConfig.strategy ? authConfig.strategy : undefined;
};
export const authConfigsMapSelector = state => state.authConfigs.map;
// Actions
export const { fetchAuthConfigsRequest, fetchAuthConfigsSuccess, fetchAuthConfigsFailure, createAuthConfig, deleteAuthConfigRequest, deleteAuthConfigFailure, deleteAuthConfigSuccess, saveAuthConfigRequest, saveAuthConfigSuccess, saveAuthConfigFailure } = slice.actions;
export const fetchAuthConfigs = createAppAsyncThunk('authConfigs/fetchAuthConfigs', async (_, _ref4) => {
    let { dispatch } = _ref4;
    try {
        dispatch(fetchAuthConfigsRequest());
        const result = await api.get({
            endpoint: `/auth-configs`
        });
        dispatch(fetchAuthConfigsSuccess(result));
    }
    catch (e) {
        dispatch(fetchAuthConfigsFailure());
        console.error(e);
    }
});
export const saveAuthConfig = createAppAsyncThunk('authConfigs/saveAuthConfig', async (authConfig, _ref5) => {
    let { dispatch } = _ref5;
    try {
        dispatch(saveAuthConfigRequest());
        /**
         * Could be both a create new or an update
         * Depending on which, we need to use different methods (POST or PATCH)
         */
        if (authConfig.id === 'new') {
            // Create a new one
            const preppedAuthConfig = prepEntityForAPICall(createTAuthConfig(authConfig), true, true);
            const savedAuthConfig = await api.post({
                endpoint: `/auth-configs`,
                data: preppedAuthConfig
            });
            dispatch(saveAuthConfigSuccess(savedAuthConfig));
            dispatch(deleteAuthConfigSuccess('new'));
        }
        else {
            const preppedAuthConfig = prepEntityForAPICall(createTAuthConfig(authConfig), true, true);
            const updatedAuthConfig = await api.patch({
                endpoint: `/auth-configs/${authConfig.id}`,
                data: preppedAuthConfig
            });
            dispatch(saveAuthConfigSuccess(updatedAuthConfig));
        }
    }
    catch (error) {
        console.error(error);
    }
});
export const copyAuthConfig = createAppAsyncThunk('authConfigs/copyAuthConfig', async (authConfigId, _ref6) => {
    let { dispatch, getState } = _ref6;
    try {
        const authConfigToCopy = getState().authConfigs.map[authConfigId];
        if (!authConfigToCopy) {
            return;
        }
        if (authConfigToCopy.strategy === EAuthStrategy.TE_IDP) {
            notification.error({
                key: configService.NOTIFICATION_KEY,
                message: 'An error happened',
                description: 'You cannot copy a TE_IDP authentication config'
            });
            return;
        }
        dispatch(saveAuthConfigRequest());
        const preppedAuthConfig = prepEntityForAPICall(authConfigToCopy, true, true);
        const copiedAuthConfig = await api.post({
            endpoint: `/auth-configs`,
            data: {
                ...preppedAuthConfig,
                name: `[COPY] ${preppedAuthConfig.name}`,
                default: false
            }
        });
        dispatch(saveAuthConfigSuccess(copiedAuthConfig));
    }
    catch (e) {
        dispatch(saveAuthConfigFailure());
        console.error(e);
    }
});
export const deleteAuthConfig = createAppAsyncThunk('authConfigs/deleteAuthConfig', async (authConfigId, _ref7) => {
    let { dispatch } = _ref7;
    try {
        dispatch(deleteAuthConfigRequest());
        /**
         * No need to send the request to the backend if the entity hasn't been saved
         */
        if (authConfigId !== 'new')
            await api.delete({
                endpoint: `/auth-configs/${authConfigId}`
            });
        dispatch(deleteAuthConfigSuccess(authConfigId));
    }
    catch (e) {
        dispatch(deleteAuthConfigFailure());
        console.error(e);
    }
});
