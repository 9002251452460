import { EFieldType, EOperator } from '@timeedit/types/lib/enums';
export const allowedFieldTypeMatching = [[EFieldType.INTEGER, EFieldType.INTEGER], [EFieldType.CHECKBOX, EFieldType.CHECKBOX], [EFieldType.TEXT, EFieldType.TEXT], [EFieldType.TEXT, EFieldType.SIGNATURE], [EFieldType.TEXT, EFieldType.REFERENCE], [EFieldType.TEXT, EFieldType.CATEGORY], [EFieldType.SIGNATURE, EFieldType.TEXT], [EFieldType.SIGNATURE, EFieldType.SIGNATURE], [EFieldType.SIGNATURE, EFieldType.REFERENCE], [EFieldType.SIGNATURE, EFieldType.CATEGORY], [EFieldType.REFERENCE, EFieldType.TEXT], [EFieldType.REFERENCE, EFieldType.SIGNATURE], [EFieldType.REFERENCE, EFieldType.REFERENCE], [EFieldType.REFERENCE, EFieldType.CATEGORY], [EFieldType.CATEGORY, EFieldType.TEXT], [EFieldType.CATEGORY, EFieldType.SIGNATURE], [EFieldType.CATEGORY, EFieldType.REFERENCE], [EFieldType.CATEGORY, EFieldType.CATEGORY]];
export const availableFieldRelationTypes = [EFieldType.INTEGER, EFieldType.TEXT, EFieldType.CATEGORY, EFieldType.CHECKBOX, EFieldType.SIGNATURE, EFieldType.REFERENCE];
export const fieldTypeInputMapping = {
    [EFieldType.INTEGER]: 'number',
    [EFieldType.TEXT]: 'text',
    [EFieldType.CATEGORY]: 'select',
    [EFieldType.CHECKBOX]: 'switch',
    [EFieldType.COMMENT]: 'textarea',
    [EFieldType.EMAIL]: 'text',
    [EFieldType.LENGTH]: 'number',
    [EFieldType.NON_SEARCHABLE_REFERENCE]: 'text',
    [EFieldType.NON_SEARCHABLE_TEXT]: 'text',
    [EFieldType.REFERENCE]: 'text',
    [EFieldType.TELEPHONE]: 'text',
    [EFieldType.SIGNATURE]: 'text',
    [EFieldType.URL]: 'text'
};
export const integerOperator = {
    [EOperator.E]: 'Equals',
    [EOperator.GT]: 'Greater Than',
    [EOperator.GTE]: 'Greater Than or Equals',
    [EOperator.LT]: 'Less Than',
    [EOperator.LTE]: 'Less Than or Equals'
};
