import { useState, useEffect } from 'react';
export const useDelay = function () {
    let time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    const [delay, setDelay] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setDelay(false);
        }, 1000 * time);
    }, [time]);
    return delay;
};
