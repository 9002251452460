import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'slices/store';
import { allUserMapSelector, fetchUserById } from 'slices/user.slice';
import { getNameOnUser } from 'utils/getNameOn';
export const useUserInfo = userId => {
    const userInfoMap = useAppSelector(allUserMapSelector);
    const dispatch = useAppDispatch();
    const userInfo = useMemo(() => userId ? userInfoMap[userId] : undefined, [userId, userInfoMap]);
    useEffect(() => {
        if (userId && !userInfo) {
            dispatch(fetchUserById(userId));
        }
    }, [dispatch, userId, userInfo]);
    return getNameOnUser(userInfo);
};
