import { createChecksum } from './checksum';
export const shouldInputBeEnabled = (row, inputProp) => {
    const { typeId, fieldCriteria, objectExtIds } = row;
    const isEnabled = {
        typeId: true,
        fieldCriteria: false,
        objectExtIds: false
    };
    if (typeId) {
        isEnabled.fieldCriteria = true;
        isEnabled.objectExtIds = true;
    }
    if (fieldCriteria && (fieldCriteria.fieldId || fieldCriteria.fieldValues.length)) {
        isEnabled.fieldCriteria = true;
        isEnabled.objectExtIds = false;
    }
    else if (objectExtIds?.length) {
        isEnabled.fieldCriteria = false;
        isEnabled.objectExtIds = true;
    }
    return isEnabled[inputProp];
};
export const findImpactedObjRowIdx = (comparisonRow, rule) => {
    // Convert row to checksum
    const rowChecksum = createChecksum(JSON.stringify(comparisonRow));
    // Convert all the impacted object rows to checksums
    const impactedObjectChecksums = rule.impactedObjects.map(row => createChecksum(JSON.stringify(row)));
    return impactedObjectChecksums.findIndex(el => el === rowChecksum);
};
export const isRowPartOfImpactedObjects = (row, rule) => {
    const impactedObjectIdx = findImpactedObjRowIdx(row, rule);
    return impactedObjectIdx > -1;
};
export const updateImpactedObjectsRow = (oldFilterCriteria, updFilterCriteria, rule) => {
    const impactedObjectIdx = findImpactedObjRowIdx(oldFilterCriteria, rule);
    // We can't find the row, nothing to update
    if (impactedObjectIdx === -1)
        return rule.impactedObjects;
    return [...rule.impactedObjects.slice(0, impactedObjectIdx), updFilterCriteria, ...rule.impactedObjects.slice(impactedObjectIdx + 1)];
};
export const getFieldCriteriaPropVal = (prop, row) => {
    const fieldCriteria = row.fieldCriteria ? row.fieldCriteria : undefined;
    if (!fieldCriteria)
        return undefined;
    return fieldCriteria[prop] || undefined;
};
export const getSelectOptionsForCategoryField = (fieldId, allFields) => {
    if (!fieldId)
        return [];
    const field = allFields.find(field => field.id === fieldId);
    if (!field)
        return [];
    return (field.categories || []).map(category => ({
        value: category,
        label: category
    }));
};
export const getFieldsForSelectedType = (typeId, allTypes, allFields) => {
    if (!typeId)
        return [];
    const type = allTypes.find(type => type.id === typeId);
    if (!type)
        return [];
    const allFieldIds = [...(type.fields || []), ...(type.parentFields || [])];
    return allFields.filter(field => allFieldIds.includes(field.id));
};
export const hasEmptyRow = function () {
    let filterCriteria = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return filterCriteria.some(row => !row.typeId && (!row.fieldCriteria || !row.fieldCriteria.fieldId && !row.fieldCriteria.fieldValues) && !row.objectExtIds?.length);
};
export const assertUniqueness = (updFilterCriteria, allFilterCriteria) => {
    const updFCChecksum = createChecksum(JSON.stringify(updFilterCriteria));
    const allFilterCriteriaChecksums = allFilterCriteria.map(el => createChecksum(JSON.stringify(el)));
    const updRowIdx = allFilterCriteriaChecksums.findIndex(el => el === updFCChecksum);
    return updRowIdx === -1;
};
const findTravelTimeDistanceFromFieldValues = (fv1, fv2, distances) => {
    const distance = distances.find(distance => distance.fieldValues.includes(fv1) && distance.fieldValues.includes(fv2));
    if (!distance)
        return undefined;
    return distance.padding;
};
export const calculateDataSourceRows = (categories, distances) => {
    /**
     * It's going to be as many rows as we have categories,
     * we can thus map over each of the entries in the categories arr
     */
    return categories.map((category, idx) => {
        /**
         * Each row will have idx + 1 columns
         */
        const retVal = [];
        for (let i = 0; i <= idx; i++) {
            retVal.push({
                fieldValues: [category, categories[i]],
                padding: findTravelTimeDistanceFromFieldValues(category, categories[i], distances)
            });
        }
        return retVal;
    });
};
export const updateOrAddTravelTimeDistance = (fieldValues, padding, distances) => {
    // Try to find the distance to see if it already exists
    const distanceIdx = distances.findIndex(distance => distance.fieldValues.includes(fieldValues[0]) && distance.fieldValues.includes(fieldValues[1]));
    if (distanceIdx === -1)
        return [...distances, {
                fieldValues,
                padding
            }];
    return [...distances.slice(0, distanceIdx), {
            fieldValues,
            padding
        }, ...distances.slice(distanceIdx + 1)];
};
