import { useCallback } from 'react';
import { theme } from 'antd';
const { useToken } = theme;
export const useRowProps = (onClick, selectedIds) => {
    const { token } = useToken();
    return useCallback(record => ({
        onClick: () => onClick(record),
        style: {
            backgroundColor: selectedIds.includes(record.id) ? token.colorPrimaryBg : token.colorBgBase,
            color: selectedIds.includes(record.id) ? token.colorPrimary : token.colorText
        }
    }), [selectedIds, onClick]);
};
