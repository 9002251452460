import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
// TYPES
// ACTIONS
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from '../utils/sliceHelpers';
export const initialState = {
    loading: false,
    hasErrors: false,
    limitations: {},
    systemPreferences: {},
    updatedSystemPreferences: {},
    variables: []
};
// Slice
const slice = createSlice({
    name: 'systemPreferences',
    initialState,
    reducers: {
        fetchSystemPreferencesRequest: state => {
            isLoadingRequest(state);
        },
        fetchSystemPreferencesSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.systemPreferences = payload;
            state.updatedSystemPreferences = payload;
            finishedLoadingSuccess(state);
        },
        fetchSystemPreferenceVariablesSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            state.variables = payload;
            finishedLoadingSuccess(state);
        },
        fetchSystemPreferencesFailure: state => {
            finishedLoadingFailure(state);
        },
        fetchLimitationsSystemPreferencesSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            state.limitations = payload;
            finishedLoadingSuccess(state);
        },
        setsUpdatedSystemPreferences: (state, _ref4) => {
            let { payload } = _ref4;
            state.updatedSystemPreferences = payload;
        },
        updateSystemPreferencesRequest: state => {
            isLoadingRequest(state);
        },
        updateSystemPreferencesSuccess: (state, _ref5) => {
            let { payload } = _ref5;
            state.systemPreferences = payload;
            state.updatedSystemPreferences = payload;
            finishedLoadingSuccess(state);
        },
        updateSystemPreferencesFailure: state => {
            finishedLoadingFailure(state);
        },
        discardChange: state => {
            state.updatedSystemPreferences = state.systemPreferences;
        }
    }
});
export default slice.reducer;
// Selectors
export const systemPreferencesLoading = state => state.systemPreferences.loading;
export const systemPreferencesSelector = state => state.systemPreferences.systemPreferences;
export const systemPreferenceVariablesSelector = state => state.systemPreferences.variables;
export const updatedSystemPreferencesSelector = state => state.systemPreferences.updatedSystemPreferences;
export const limitationsSystemPreferencesSelector = state => state.systemPreferences.limitations;
// Actions
export const { fetchSystemPreferencesRequest, fetchSystemPreferencesSuccess, fetchSystemPreferencesFailure, fetchLimitationsSystemPreferencesSuccess, setsUpdatedSystemPreferences, updateSystemPreferencesRequest, updateSystemPreferencesSuccess, updateSystemPreferencesFailure, discardChange, fetchSystemPreferenceVariablesSuccess } = slice.actions;
export const fetchSystemPreferences = () => async (dispatch) => {
    try {
        dispatch(fetchSystemPreferencesRequest());
        const systemPreferences = await api.get({
            endpoint: `/system-preferences`
        });
        dispatch(fetchSystemPreferencesSuccess(systemPreferences));
    }
    catch (e) {
        dispatch(fetchSystemPreferencesFailure());
        return console.error(e);
    }
};
export const fetchSystemPreferenceVariables = () => async (dispatch) => {
    try {
        dispatch(fetchSystemPreferencesRequest());
        const variables = await api.get({
            endpoint: `/system-preferences/variables`
        });
        dispatch(fetchSystemPreferenceVariablesSuccess(variables));
    }
    catch (e) {
        dispatch(fetchSystemPreferencesFailure());
        return console.error(e);
    }
};
export const fetchLimitationsForSystemPreferences = () => async (dispatch) => {
    try {
        dispatch(fetchSystemPreferencesRequest());
        const limitationsSystemPreferences = await api.get({
            endpoint: `/system-preferences/limitations`
        });
        dispatch(fetchLimitationsSystemPreferencesSuccess(limitationsSystemPreferences));
    }
    catch (e) {
        dispatch(fetchSystemPreferencesFailure());
        return console.error(e);
    }
};
export const setUpdatedSystemPreferences = systemPreferences => async (dispatch) => {
    dispatch(setsUpdatedSystemPreferences(systemPreferences));
};
export const updateSystemPreferences = systemPreferences => async (dispatch) => {
    try {
        dispatch(updateSystemPreferencesRequest());
        const { ...SystemPreferencesBody } = systemPreferences;
        const response = await api.patch({
            endpoint: `/system-preferences`,
            data: {
                ...SystemPreferencesBody
            },
            suppressNotification: true
        });
        dispatch(updateSystemPreferencesSuccess(response));
    }
    catch (e) {
        dispatch(updateSystemPreferencesFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
};
