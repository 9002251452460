import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { AuthenticationTokenCreateEmpty } from 'types/authenticationToken.type';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import { createAppAsyncThunk } from 'slices/utils';
export const initialState = {
    loading: false,
    hasErrors: false,
    selectedAuthenticationToken: AuthenticationTokenCreateEmpty.createEmpty(),
    allAuthenticationTokens: []
};
// Slice
const slice = createSlice({
    name: 'authenticationTokens',
    initialState,
    reducers: {
        setAuthenticationTokens: (state, _ref) => {
            let { payload: authTokens } = _ref;
            state.allAuthenticationTokens = authTokens;
        },
        deleteAuthenticationTokenRequest: state => {
            isLoadingRequest(state);
        },
        deleteAuthenticationTokenSuccess: (state, _ref2) => {
            let { payload: authToken } = _ref2;
            state.allAuthenticationTokens = state.allAuthenticationTokens.filter(authenticationToken => authenticationToken.id !== authToken.id);
            state.selectedAuthenticationToken = AuthenticationTokenCreateEmpty.createEmpty();
            finishedLoadingSuccess(state);
        },
        deleteAuthenticationTokenFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Actions
export const { setAuthenticationTokens, deleteAuthenticationTokenRequest, deleteAuthenticationTokenSuccess, deleteAuthenticationTokenFailure } = slice.actions;
export const setSelectedAuthenticationTokens = authenticationTokens => dispatch => {
    dispatch(setAuthenticationTokens(authenticationTokens));
};
export const deleteAuthenticationToken = createAppAsyncThunk('authenticationTokens/deleteAuthenticationToken', async (authenticationToken, _ref3) => {
    let { dispatch } = _ref3;
    try {
        dispatch(deleteAuthenticationTokenRequest());
        await api.delete({
            endpoint: `/authentication-tokens/${authenticationToken.id}`
        });
        dispatch(deleteAuthenticationTokenSuccess(authenticationToken));
    }
    catch (e) {
        dispatch(deleteAuthenticationTokenFailure());
        return console.error(e);
    }
});
