import { hasPermissions } from '@timeedit/types/lib/utils/permissionCalculator';
export const getVisibility = _ref => {
    let { environments = undefined, minimumPermissions = undefined, authUser = undefined } = _ref;
    let isVisible = environments?.length && process.env.REACT_APP_TE_APP_ENV ? environments.includes(process.env.REACT_APP_TE_APP_ENV) : true;
    if (isVisible && (minimumPermissions?.scopes?.length || minimumPermissions?.permissions?.length))
        isVisible = calculatePermissions(authUser, minimumPermissions);
    return isVisible;
};
export const calculatePermissions = (authUser, minimumPermissions) => {
    if (!authUser)
        return false;
    return hasPermissions(authUser.appPermissions, authUser.scopes, minimumPermissions);
};
