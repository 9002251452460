export class AuthenticationToken {
    static create = () => ({
        id: 0,
        clientId: '',
        password: '',
        isActive: true,
        useToken: false,
        lastUsed: null
    });
}
export class AuthenticationTokenCreateEmpty {
    static createEmpty = () => ({
        id: 0,
        clientId: '',
        password: '',
        isActive: true,
        useToken: false,
        lastUsed: null
    });
}
