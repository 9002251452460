/**
 * standardized way of deleting an entity from the redux state
 */
export const deleteEntityFromState = function (id, state) {
    let idKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
    const { [id]: _, ...updState } = state.map;
    state.map = updState;
    const idx = state.results.findIndex(el => el && el[idKey].toString() === id.toString());
    if (idx > -1)
        state.results = [...state.results.slice(0, idx), ...state.results.slice(idx + 1)];
};
