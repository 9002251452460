/**
 * @function addEntityToState
 * @description standardized way of adding an entity to the redux state
 * @param {IDefaultAPIState} state
 * @param {Object} payload
 * @param {Function} createFn
 * @param {String | undefined} idKey
 * @returns {void}
 */
export const addEntityToState = function (state, payload, createFn) {
    let idKey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'id';
    const item = createFn(payload);
    const map = {
        ...state.map,
        [item[idKey]]: item
    };
    const idx = state.results.findIndex(el => el[idKey] === item[idKey]);
    const results = idx > -1 ? [...state.results.slice(0, idx), item, ...state.results.slice(idx + 1)] : [item, ...state.results];
    state.map = map;
    state.results = results;
    return item;
};
