import { omit } from 'lodash';
/**
 * standardized way of prepping an entity for being sent to the TE API gateway
 */
export function prepEntityForAPICall(entity) {
    let removeOrgId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    let removeId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    if (!removeOrgId && !removeId) {
        return entity;
    }
    if (removeOrgId && !removeId) {
        return omit(entity, 'organizationId');
    }
    if (!removeOrgId && removeId) {
        return omit(entity, 'id');
    }
    return omit(entity, ['organizationId', 'id', 'createdAt', 'updatedAt', 'legacyId']);
}
