import { createSlice } from '@reduxjs/toolkit';
import { createSOAPConnectionSettings } from '@timeedit/types';
import { createAppAsyncThunk } from 'slices/utils';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, prepEntityForAPICall } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const initialState = {
    loading: false,
    hasErrors: false,
    username: '',
    password: '',
    certificateKey: '',
    baseUrl: ''
};
// Slice
const slice = createSlice({
    name: 'appsIntegrationSettings',
    initialState,
    reducers: {
        fetchAppsIntegrationSettingsRequest: state => {
            isLoadingRequest(state);
        },
        fetchAppsIntegrationSettingsSuccess: handleIntegrationSettingsSuccess,
        fetchAppsIntegrationSettingsFailure: state => {
            finishedLoadingFailure(state);
        },
        saveAppsIntegrationSettingsRequest: state => {
            isLoadingRequest(state);
        },
        saveAppsIntegrationSettingsSuccess: handleIntegrationSettingsSuccess,
        saveAppsIntegrationSettingsFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
function handleIntegrationSettingsSuccess(state, _ref) {
    let { payload } = _ref;
    const appsIntegrationSettings = createSOAPConnectionSettings(payload);
    state.username = appsIntegrationSettings.username;
    state.password = appsIntegrationSettings.password;
    state.certificateKey = appsIntegrationSettings.certificateKey;
    state.baseUrl = appsIntegrationSettings.baseUrl;
    finishedLoadingSuccess(state);
}
export default slice.reducer;
// Selectors
export const appsIntegrationSettingsLoading = state => state.appsIntegrationSettings.loading;
export const appsIntegrationSettingsSelector = state => {
    const { username, password, certificateKey, baseUrl } = state.appsIntegrationSettings;
    return {
        username,
        password,
        certificateKey,
        baseUrl
    };
};
// Actions
export const { fetchAppsIntegrationSettingsRequest, fetchAppsIntegrationSettingsSuccess, fetchAppsIntegrationSettingsFailure, saveAppsIntegrationSettingsRequest, saveAppsIntegrationSettingsSuccess, saveAppsIntegrationSettingsFailure } = slice.actions;
export const fetchAppsIntegrationSettings = createAppAsyncThunk('appsIntegrationSettings/fetchAppsIntegrationSettings', async (_, _ref2) => {
    let { dispatch } = _ref2;
    try {
        dispatch(fetchAppsIntegrationSettingsRequest());
        const result = await api.get({
            endpoint: `/apps/integration-settings/`
        });
        dispatch(fetchAppsIntegrationSettingsSuccess(result));
    }
    catch (e) {
        dispatch(fetchAppsIntegrationSettingsFailure());
        console.error(e);
    }
});
export const saveAppsIntegrationSettings = createAppAsyncThunk('appsIntegrationSettings/saveAppsIntegrationSettings', async (appsIntegrationSettings, _ref3) => {
    let { dispatch } = _ref3;
    try {
        dispatch(saveAppsIntegrationSettingsRequest());
        const preppedResource = prepEntityForAPICall(appsIntegrationSettings, true);
        await api.patch({
            endpoint: `/apps/integration-settings`,
            data: preppedResource
        });
        dispatch(saveAppsIntegrationSettingsSuccess(preppedResource));
    }
    catch (error) {
        dispatch(saveAppsIntegrationSettingsFailure());
        console.error(error);
    }
});
