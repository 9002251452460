import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
// UTILS
import { createEmptyTTimeRule } from '@timeedit/types/lib/utils';
import _, { compact } from 'lodash';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
// TYPES
// ACTIONS
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from '../utils/sliceHelpers';
import { startAppListening } from './listenerMiddleware';
export const initialState = {
    loading: false,
    hasErrors: false,
    allTimeRules: [],
    timeRuleMap: {},
    firstTimeRule: null,
    secondTimeRule: null,
    selectedTimeRuleIds: []
};
// Slice
const slice = createSlice({
    name: 'timeRules',
    initialState,
    reducers: {
        fetchTimeRulesRequest: state => {
            isLoadingRequest(state);
        },
        fetchTimeRulesSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.allTimeRules = payload.results;
            state.timeRuleMap = _.keyBy(payload.results, 'id');
            finishedLoadingSuccess(state);
        },
        fetchTimeRulesFailure: state => {
            finishedLoadingFailure(state);
        },
        createTimeRuleRequest: state => {
            isLoadingRequest(state);
        },
        createTimeRuleSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            state.allTimeRules.push(payload);
            finishedLoadingSuccess(state);
        },
        createTimeRuleFailure: state => {
            finishedLoadingFailure(state);
        },
        deleteTimeRuleRequest: state => {
            isLoadingRequest(state);
        },
        deleteTimeRuleSuccess: (state, _ref3) => {
            let { payload: timeRuleId } = _ref3;
            state.allTimeRules = state.allTimeRules.filter(timeRule => timeRule.id !== timeRuleId);
            state.timeRuleMap[timeRuleId] = undefined;
            finishedLoadingSuccess(state);
        },
        deleteTimeRuleFailure: state => {
            finishedLoadingFailure(state);
        },
        updateTimeRuleRequest: state => {
            isLoadingRequest(state);
        },
        updateTimeRuleSuccess: (state, _ref4) => {
            let { payload } = _ref4;
            const index = state.allTimeRules.findIndex(timeRule => timeRule.id === payload.id);
            state.allTimeRules[index] = payload;
            state.timeRuleMap[payload.id] = payload;
            finishedLoadingSuccess(state);
        },
        updateTimeRuleFailure: state => {
            finishedLoadingFailure(state);
        },
        setFirstTimeRule: (state, _ref5) => {
            let { payload } = _ref5;
            const prevItem = state.firstTimeRule;
            state.firstTimeRule = payload;
            if (prevItem?.id === 0) {
                state.timeRuleMap[0] = undefined;
                state.allTimeRules = state.allTimeRules.filter(ar => ar.id !== 0);
            }
        },
        setSecondTimeRule: (state, _ref6) => {
            let { payload } = _ref6;
            state.secondTimeRule = payload;
        },
        createEmptyTimeRule: state => {
            if (state.firstTimeRule?.id === 0)
                return;
            const newItem = createEmptyTTimeRule();
            state.firstTimeRule = newItem;
            state.timeRuleMap[newItem.id] = newItem;
            state.allTimeRules.unshift(newItem);
        },
        duplicateTimeRule: (state, _ref7) => {
            let { payload } = _ref7;
            if (state.firstTimeRule?.id === 0)
                return;
            const newItem = {
                ...payload,
                id: 0,
                name: `${payload.name} (copy)`
            };
            state.firstTimeRule = newItem;
            state.timeRuleMap[newItem.id] = newItem;
            state.allTimeRules.unshift(newItem);
        },
        changeTimeRule: (state, _ref8) => {
            let { payload } = _ref8;
            const { isFirstDrawer, key, value, object } = payload;
            const changedObject = object || {
                [key]: value
            };
            if (isFirstDrawer) {
                state.firstTimeRule = {
                    ...state.firstTimeRule,
                    ...changedObject
                };
            }
            else {
                state.secondTimeRule = {
                    ...state.secondTimeRule,
                    ...changedObject
                };
            }
        },
        discardChanges: (state, _ref9) => {
            let { payload } = _ref9;
            const { isFirstDrawer, id } = payload;
            if (isFirstDrawer) {
                state.firstTimeRule = state.timeRuleMap[id];
            }
            else {
                state.secondTimeRule = state.timeRuleMap[id];
            }
        },
        closeDrawer: (state, _ref10) => {
            let { payload: id } = _ref10;
            if (state.firstTimeRule?.id === id) {
                state.firstTimeRule = null;
            }
            if (state.secondTimeRule?.id === id) {
                state.secondTimeRule = null;
            }
        },
        setSelectedTimeRuleIds: (state, _ref11) => {
            let { payload } = _ref11;
            state.selectedTimeRuleIds = payload;
        }
    }
});
export default slice.reducer;
// Selectors
export const timeRulesSelector = state => state.timeRules.allTimeRules;
export const firstTimeRuleSelector = state => state.timeRules.firstTimeRule;
export const secondTimeRuleSelector = state => state.timeRules.secondTimeRule;
export const selectedTimeRuleIdsSelector = state => state.timeRules.selectedTimeRuleIds;
export const timeRuleMapSelector = state => state.timeRules.timeRuleMap;
export const timeRuleLoadingSelector = state => state.timeRules.loading;
// Actions
export const { fetchTimeRulesRequest, fetchTimeRulesSuccess, fetchTimeRulesFailure, createTimeRuleRequest, createTimeRuleSuccess, createTimeRuleFailure, deleteTimeRuleRequest, deleteTimeRuleSuccess, deleteTimeRuleFailure, updateTimeRuleRequest, updateTimeRuleSuccess, updateTimeRuleFailure, setFirstTimeRule, setSecondTimeRule, createEmptyTimeRule, duplicateTimeRule, changeTimeRule, discardChanges, closeDrawer, setSelectedTimeRuleIds } = slice.actions;
export const fetchTimeRules = () => async (dispatch) => {
    try {
        dispatch(fetchTimeRulesRequest());
        const timeRules = await api.get({
            endpoint: `/time-rules`
        });
        dispatch(fetchTimeRulesSuccess(timeRules));
    }
    catch (e) {
        dispatch(fetchTimeRulesFailure());
        return console.error(e);
    }
};
export const copyTimeRule = timeRule => async (dispatch) => {
    try {
        dispatch(createTimeRuleRequest());
        const response = {
            ...timeRule,
            id: 0,
            description: ''
        };
        dispatch(createTimeRuleSuccess(response));
    }
    catch (e) {
        dispatch(createTimeRuleFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
};
export const deleteTimeRule = timeRuleId => async (dispatch) => {
    try {
        dispatch(deleteTimeRuleRequest());
        await api.delete({
            endpoint: `/time-rules/${timeRuleId}`
        });
        dispatch(deleteTimeRuleSuccess(timeRuleId));
        dispatch(closeDrawer(timeRuleId));
    }
    catch (e) {
        dispatch(deleteTimeRuleFailure());
        return console.error(e);
    }
};
export const saveTimeRule = timeRule => async (dispatch) => {
    try {
        dispatch(updateTimeRuleRequest());
        const { id, history, absoluteStartTime, absoluteEndTime, absoluteCancellationStartTime, absoluteCancellationEndTime, ...timeRuleBody } = timeRule;
        const data = {
            ...timeRuleBody,
            absoluteStartTime,
            absoluteEndTime: absoluteEndTime && absoluteStartTime === absoluteEndTime ? absoluteEndTime + 86399 : absoluteEndTime,
            absoluteCancellationStartTime,
            absoluteCancellationEndTime: absoluteCancellationEndTime && absoluteCancellationStartTime === absoluteCancellationEndTime ? absoluteCancellationEndTime + 86399 : absoluteCancellationEndTime
        };
        if (id !== 0) {
            const response = await api.patch({
                endpoint: `/time-rules/${id}`,
                data
            });
            dispatch(updateTimeRuleSuccess(response));
        }
        else {
            await api.post({
                endpoint: `/time-rules`,
                data
            });
            dispatch(fetchTimeRules());
        }
        dispatch(closeDrawer(id));
    }
    catch (e) {
        dispatch(updateTimeRuleFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
};
export const checkFirstTimeRuleExists = () => (_, getState) => !!getState().timeRules.firstTimeRule;
export const checkTimeRuleSaveDisabled = isFirstDrawer => (_, getState) => {
    const { timeRules } = getState();
    const { firstTimeRule, secondTimeRule, timeRuleMap } = timeRules;
    if (isFirstDrawer && firstTimeRule && timeRuleMap[firstTimeRule.id]) {
        return checkSavedDisabled(firstTimeRule, timeRuleMap[firstTimeRule.id]);
    }
    if (!isFirstDrawer && secondTimeRule && timeRuleMap[secondTimeRule.id]) {
        return checkSavedDisabled(secondTimeRule, timeRuleMap[secondTimeRule.id]);
    }
    return true;
};
startAppListening({
    predicate: (_, currentState, prevState) => currentState.timeRules.firstTimeRule?.id !== prevState.timeRules.firstTimeRule?.id || currentState.timeRules.secondTimeRule?.id !== prevState.timeRules.secondTimeRule?.id,
    effect: (_, _ref12) => {
        let { dispatch, getState } = _ref12;
        const { timeRules: { firstTimeRule, secondTimeRule } } = getState();
        dispatch(setSelectedTimeRuleIds(compact([firstTimeRule?.id, secondTimeRule?.id])));
    }
});
