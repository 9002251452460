import { createSlice } from '@reduxjs/toolkit';
import { createRolePermission as create, createRolePermissionGroup } from '@timeedit/types';
import { groupBy } from 'lodash';
import { createAppAsyncThunk } from 'slices/utils';
import { addEntityToState, deleteEntityFromState, finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, prepEntityForAPICall, stateHasFetchedAllObjectsSuccess, upsertEntity } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    groups: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0
};
// Slice
const slice = createSlice({
    name: 'rolePermissions',
    initialState,
    reducers: {
        fetchRolePermissionsRequest: state => {
            isLoadingRequest(state);
        },
        fetchRolePermissionsSuccess: (state, _ref) => {
            let { payload } = _ref;
            stateHasFetchedAllObjectsSuccess(payload, state, create);
            finishedLoadingSuccess(state);
        },
        fetchRolePermissionsFailure: state => {
            finishedLoadingFailure(state);
        },
        fetchRolePermissionGroupsSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            state.groups = (payload?.results || []).map(obj => createRolePermissionGroup(obj));
            finishedLoadingSuccess(state);
        },
        createRolePermission: state => {
            addEntityToState(state, {
                id: 0,
                name: 'New role permission'
            }, create);
            finishedLoadingSuccess(state);
        },
        saveRolePermissionRequest: state => {
            isLoadingRequest(state);
        },
        saveRolePermissionSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            upsertEntity(state, payload, create);
            finishedLoadingSuccess(state);
        },
        deleteRolePermissionRequest: state => {
            isLoadingRequest(state);
        },
        deleteRolePermissionSuccess: (state, _ref4) => {
            let { payload: rolePermissionId } = _ref4;
            deleteEntityFromState(rolePermissionId, state);
            finishedLoadingSuccess(state);
        },
        deleteRolePermissionFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const rolePermissionsLoading = state => state.rolePermissions.loading;
export const rolePermissionsSelector = state => state.rolePermissions.results;
export const rolePermissionSelector = id => state => id ? state.rolePermissions.map[id] : undefined;
export const rolePermissionGroupsSelector = state => state.rolePermissions.groups;
export const selectAllowedActionsForGroup = groupId => state => {
    const groups = state.rolePermissions.groups || [];
    const group = groups.find(group => group.id === groupId);
    if (!group)
        return [];
    return group.groupActions;
};
export const selectRolePermissionsAsTreeData = state => {
    // Get the groups
    const groups = state.rolePermissions.groups || [];
    // Get the role permissions
    const rolePermissions = state.rolePermissions.results;
    // Group role permissions by group
    const groupedRolePermissions = groupBy(rolePermissions, 'rolePermissionGroup');
    // Create the tree
    const tree = groups.map(group => {
        // Get all the role permissions matching this group
        const rolePermissionsForGroup = (groupedRolePermissions[group.id] || []).map(rolePermission => ({
            title: rolePermission.name,
            key: rolePermission.id,
            children: [],
            isLeaf: true
        }));
        return {
            title: group.id,
            key: group.id,
            children: rolePermissionsForGroup,
            selectable: false
        };
    });
    if (groupedRolePermissions.undefined && groupedRolePermissions.undefined.length) {
        const noGroup = {
            title: 'UNASSIGNED',
            key: 'UNASSIGNED',
            selectable: false,
            children: groupedRolePermissions.undefined.map(rolePermission => ({
                title: rolePermission.name,
                key: rolePermission.id,
                children: [],
                isLeaf: true
            }))
        };
        tree.unshift(noGroup);
    }
    return tree;
};
// Actions
export const { fetchRolePermissionsRequest, fetchRolePermissionsSuccess, fetchRolePermissionsFailure, fetchRolePermissionGroupsSuccess, createRolePermission, deleteRolePermissionRequest, deleteRolePermissionFailure, deleteRolePermissionSuccess, saveRolePermissionRequest, saveRolePermissionSuccess } = slice.actions;
export const fetchRolePermissions = createAppAsyncThunk('rolePermissions/fetchRolePermissions', async (_, _ref5) => {
    let { dispatch } = _ref5;
    try {
        dispatch(fetchRolePermissionsRequest());
        const result = await api.get({
            endpoint: `/role-permissions`
        });
        dispatch(fetchRolePermissionsSuccess(result));
    }
    catch (e) {
        dispatch(fetchRolePermissionsFailure());
        console.error(e);
    }
});
export const fetchRolePermissionGroups = createAppAsyncThunk('rolePermissions/fetchRolePermissionGroups', async (_, _ref6) => {
    let { dispatch } = _ref6;
    try {
        dispatch(fetchRolePermissionsRequest());
        const result = await api.get({
            endpoint: `/role-permissions/groups`
        });
        dispatch(fetchRolePermissionGroupsSuccess(result));
    }
    catch (e) {
        dispatch(fetchRolePermissionsFailure());
        console.error(e);
    }
});
export const saveRolePermission = createAppAsyncThunk('rolePermissions/saveRolePermission', async (rolePermission, _ref7) => {
    let { dispatch } = _ref7;
    try {
        dispatch(saveRolePermissionRequest());
        /**
         * Could be either create or update
         * Depending on which, we need to use different methods (POST or PATCH)
         */
        if (rolePermission.id === 0) {
            // Create a new one
            const preppedRolePermission = prepEntityForAPICall(rolePermission, true, true);
            const savedRolePermission = await api.post({
                endpoint: `/role-permissions`,
                data: preppedRolePermission
            });
            dispatch(saveRolePermissionSuccess(savedRolePermission));
            dispatch(deleteRolePermissionSuccess(0));
        }
        else {
            const preppedRolePermission = prepEntityForAPICall(rolePermission, true, true);
            const updatedRolePermission = await api.patch({
                endpoint: `/role-permissions/${rolePermission.id}`,
                data: preppedRolePermission
            });
            dispatch(saveRolePermissionSuccess(updatedRolePermission));
        }
    }
    catch (error) {
        console.error(error);
    }
});
export const deleteRolePermission = createAppAsyncThunk('rolePermissions/deleteRolePermission', async (rolePermissionId, _ref8) => {
    let { dispatch } = _ref8;
    try {
        dispatch(deleteRolePermissionRequest());
        /**
         * No need to send the request to the backend if the entity hasn't been saved
         */
        if (rolePermissionId !== 0)
            await api.delete({
                endpoint: `/role-permissions/${rolePermissionId}`
            });
        dispatch(deleteRolePermissionSuccess(rolePermissionId));
    }
    catch (e) {
        dispatch(deleteRolePermissionFailure());
        console.error(e);
    }
});
