import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
const disableSentry = window.location.href.includes('localhost');
if (!disableSentry) {
    Sentry.init({
        dsn: 'https://ca5ec74bcc602bf0465628e946f3ca2a@o4506875039318016.ingest.us.sentry.io/4508375753883648',
        environment: process.env.REACT_APP_TE_APP_ENV,
        integrations: [Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            }), Sentry.replayIntegration()],
        // Capture replay for 100% of sessions with an error
        replaysOnErrorSampleRate: 1.0
    });
}
