import { createSlice } from '@reduxjs/toolkit';
import { createReservationMapping, EAppId } from '@timeedit/types';
import { RESERVATION_SETTINGS_MAPPING } from 'constants/ReservationMapping.constants';
import _ from 'lodash';
import { createAppAsyncThunk } from 'slices/utils';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const initialState = {
    loading: false,
    hasErrors: false,
    appId: EAppId.TE_EXAM,
    paramMappings: [],
    situations: [],
    situationTypes: {},
    situationFields: {},
    objectExtTypes: [],
    objectTypefields: []
};
// Slice
const slice = createSlice({
    name: 'reservationSettings',
    initialState,
    reducers: {
        fetchReservationMappingRequest: state => {
            isLoadingRequest(state);
        },
        fetchReservationMappingSuccess: (state, _ref) => {
            let { payload } = _ref;
            const reservationSettings = createReservationMapping(payload);
            state.appId = reservationSettings.appId;
            state.paramMappings = reservationSettings.paramMappings;
            finishedLoadingSuccess(state);
        },
        fetchReservationMappingFailure: state => {
            finishedLoadingFailure(state);
        },
        saveReservationMappingRequest: state => {
            isLoadingRequest(state);
        },
        saveReservationMappingSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            const reservationSettings = createReservationMapping(payload);
            state.appId = reservationSettings.appId;
            state.paramMappings = reservationSettings.paramMappings;
            finishedLoadingSuccess(state);
        },
        saveReservationMappingFailure: state => {
            finishedLoadingFailure(state);
        },
        fetchReservationSituationsSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            state.situations = _.map(payload, _ref4 => {
                let { extid, name } = _ref4;
                return {
                    label: name,
                    value: extid
                };
            });
            finishedLoadingSuccess(state);
        },
        fetchSituationFieldsSuccess: (state, _ref5) => {
            let { payload } = _ref5;
            const { situation, fields } = payload;
            state.situationFields[situation] = _.map(fields, field => ({
                label: field,
                value: field
            }));
            finishedLoadingSuccess(state);
        },
        fetchSituationTypesSuccess: (state, _ref6) => {
            let { payload } = _ref6;
            const { situation, types } = payload;
            state.situationTypes[situation] = types;
            finishedLoadingSuccess(state);
        },
        fetchObjectExtTypesSuccess: (state, _ref7) => {
            let { payload } = _ref7;
            state.objectExtTypes = _.map(payload, _ref8 => {
                let { extid, name } = _ref8;
                return {
                    label: name,
                    value: extid
                };
            });
            finishedLoadingSuccess(state);
        },
        fetchTypeFieldsSuccess: (state, _ref9) => {
            let { payload } = _ref9;
            state.objectTypefields = _.map(payload, _ref10 => {
                let { fields } = _ref10;
                const field = fields.te_extid;
                return {
                    label: field,
                    value: field
                };
            });
            finishedLoadingSuccess(state);
        },
        updateReservationSettingsLocal: (state, _ref11) => {
            let { payload } = _ref11;
            const { appFieldId, fieldExt } = payload;
            const newSettings = [...state.paramMappings];
            const index = newSettings.findIndex(item => item.appFieldId === appFieldId);
            if (index > -1) {
                newSettings[index] = {
                    appFieldId,
                    fieldExt,
                    fieldLabel: ''
                };
            }
            else {
                newSettings.push({
                    appFieldId,
                    fieldExt,
                    fieldLabel: ''
                });
            }
            state.paramMappings = newSettings;
        }
    }
});
export default slice.reducer;
// Selectors
export const reservationSettingsLoading = state => state.reservationSettings.loading;
export const reservationMappingSelector = state => state.reservationSettings;
export const reservationSettingsSelector = state => state.reservationSettings.paramMappings;
export const situationsSelector = state => state.reservationSettings.situations;
export const situationTypesSelector = state => state.reservationSettings.situationTypes;
// TODO: Make a state of typeIdentified and use it in the selector
export const typeIdentifiedSelector = state => {
    const mapping = _.get(state, 'objectRelationships.objectMappings.objectTypes', []);
    const reservationSettings = _.get(state, 'reservationSettings.paramMappings', []);
    const mappingByTypeName = mapping.reduce((acc, cur) => ({
        ...acc,
        [cur.applicationObjectTypeName]: cur.objectTypeExtId
    }), {});
    const reservationSettingsByAppFieldId = reservationSettings.reduce((acc, cur) => ({
        ...acc,
        [cur.appFieldId]: cur.fieldExt
    }), {});
    const situationTypes = _.get(state, 'reservationSettings.situationTypes', {});
    const identified = {};
    if (RESERVATION_SETTINGS_MAPPING[EAppId.TE_EXAM]) {
        RESERVATION_SETTINGS_MAPPING[EAppId.TE_EXAM].forEach(_ref12 => {
            let { modeFieldId, types } = _ref12;
            if (modeFieldId && types) {
                const reservationTeplate = reservationSettingsByAppFieldId[modeFieldId] || '';
                const reservationTypes = situationTypes[reservationTeplate] || [];
                identified[modeFieldId] = Object.keys(types).reduce((tot, typeKey) => {
                    const reduxSource = _.get(types, [typeKey, 'reduxSource'], 'reservationSettings');
                    const appType = reduxSource === 'reservationSettings' ? reservationSettingsByAppFieldId[typeKey] : mappingByTypeName[typeKey];
                    return {
                        ...tot,
                        [typeKey]: !!appType && reservationTypes.includes(appType)
                    };
                }, {});
            }
        });
    }
    return identified;
};
export const objectExtTypesSelector = state => state.reservationSettings.objectExtTypes;
// Actions
export const { fetchReservationMappingRequest, fetchReservationMappingSuccess, fetchReservationMappingFailure, saveReservationMappingRequest, saveReservationMappingSuccess, saveReservationMappingFailure, fetchReservationSituationsSuccess, fetchSituationFieldsSuccess, fetchSituationTypesSuccess, fetchObjectExtTypesSuccess, fetchTypeFieldsSuccess, updateReservationSettingsLocal } = slice.actions;
export const fetchReservationMapping = createAppAsyncThunk('reservationSettings/fetchReservationMapping', async (_, _ref13) => {
    let { dispatch } = _ref13;
    try {
        dispatch(fetchReservationMappingRequest());
        const result = await api.get({
            endpoint: `/apps/${EAppId.TE_EXAM}/reservation-mapping`
        });
        dispatch(fetchReservationMappingSuccess(result));
    }
    catch (e) {
        dispatch(fetchReservationMappingFailure());
        console.error(e);
    }
});
export const saveReservationMapping = createAppAsyncThunk('reservationSettings/saveReservationMapping', async (paramMappings, _ref14) => {
    let { dispatch } = _ref14;
    try {
        dispatch(saveReservationMappingRequest());
        await api.patch({
            endpoint: `/apps/${EAppId.TE_EXAM}/reservation-mapping`,
            data: {
                paramMappings
            }
        });
        dispatch(saveReservationMappingSuccess({
            appId: EAppId.TE_EXAM,
            paramMappings
        }));
    }
    catch (error) {
        dispatch(saveReservationMappingFailure());
        console.error(error);
    }
});
export const fetchReservationSituations = createAppAsyncThunk('reservationSettings/fetchReservationSituations', async (_, _ref15) => {
    let { dispatch } = _ref15;
    try {
        const result = await api.get({
            endpoint: `/apps/${EAppId.TE_EXAM}/integration/reservations/situations`
        });
        dispatch(fetchReservationSituationsSuccess(result.data || []));
    }
    catch (error) {
        console.error(error);
    }
});
export const fetchSituationTypes = createAppAsyncThunk('reservationSettings/fetchSituationTypes', async (situation, _ref16) => {
    let { dispatch } = _ref16;
    try {
        const result = await api.get({
            endpoint: `/apps/${EAppId.TE_EXAM}/integration/reservations/types/${situation}`
        });
        dispatch(fetchSituationTypesSuccess({
            situation,
            types: result.data || []
        }));
    }
    catch (error) {
        console.error(error);
    }
});
export const fetchSituationFields = createAppAsyncThunk('reservationSettings/fetchSituationFields', async (situation, _ref17) => {
    let { dispatch } = _ref17;
    try {
        const result = await api.get({
            endpoint: `/apps/${EAppId.TE_EXAM}/integration/reservations/fields/${situation}`
        });
        dispatch(fetchSituationFieldsSuccess({
            situation,
            fields: result.data || []
        }));
    }
    catch (error) {
        console.error(error);
    }
});
export const fetchObjectExtTypes = createAppAsyncThunk('reservationSettings/fetchObjectExtTypes', async (_, _ref18) => {
    let { dispatch } = _ref18;
    try {
        const result = await api.get({
            endpoint: `/apps/${EAppId.TE_EXAM}/integration/objects/types`
        });
        dispatch(fetchObjectExtTypesSuccess(result.data || []));
    }
    catch (error) {
        console.error(error);
    }
});
export const fetchTypeFields = createAppAsyncThunk('reservationSettings/fetchTypeFields', async (objectType, _ref19) => {
    let { dispatch } = _ref19;
    try {
        const result = await api.get({
            endpoint: `/apps/${EAppId.TE_EXAM}/integration/objects/types/${objectType}`
        });
        dispatch(fetchTypeFieldsSuccess(result.data || []));
    }
    catch (error) {
        console.error(error);
    }
});
