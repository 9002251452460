import { useState, useEffect } from 'react';
export function useDebounce(value) {
    let delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}
