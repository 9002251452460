import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import { createAppAsyncThunk } from 'slices/utils';
export const initialState = {
    loading: false,
    hasErrors: false,
    holidayRegions: []
};
const slice = createSlice({
    name: 'holidays',
    initialState,
    reducers: {
        fetchHolidayRegionsRequest: state => {
            isLoadingRequest(state);
        },
        fetchHolidayRegionsSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.holidayRegions = payload.results;
            finishedLoadingSuccess(state);
        },
        fetchHolidayRegionsFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const holidayRegionsSelector = state => state.holidayReservations.holidayRegions;
// Actions
export const { fetchHolidayRegionsRequest, fetchHolidayRegionsSuccess, fetchHolidayRegionsFailure } = slice.actions;
export const fetchHolidayRegions = createAppAsyncThunk('holidays/fetchHolidayRegions', async (_, _ref2) => {
    let { dispatch } = _ref2;
    try {
        dispatch(fetchHolidayRegionsRequest());
        const holidayRegions = await api.get({
            endpoint: `/holidays/regions`
        });
        dispatch(fetchHolidayRegionsSuccess(holidayRegions));
    }
    catch (e) {
        dispatch(fetchHolidayRegionsFailure());
        return console.error(e);
    }
});
export const getListOfHolidays = async (holidayRegion, holidayType, startDate, endDate) => {
    const holidays = await api.get({
        endpoint: `/holidays?holidayRegion=${holidayRegion}&holidayType=${holidayType}&startDate=${startDate}&endDate=${endDate}`
    });
    return holidays.results;
};
export const createHolidayReservation = async (holidayReservation) => {
    const { ...holidayReservationBody } = holidayReservation;
    return await api.post({
        endpoint: `/holidays/reservations`,
        data: holidayReservationBody
    });
};
