import { useCustomerNavigate } from 'hooks/useCustomerNavigate';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export function FallbackRedirect() {
    const navigate = useCustomerNavigate();
    const location = useLocation();
    useEffect(() => {
        navigate(location.pathname);
    }, [navigate, location.pathname]);
    return null;
}
