import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import { createAppAsyncThunk } from 'slices/utils';
import _ from 'lodash';
import { EXAM_CONFIGURATION_FIELD_MODE } from 'components/AppService/ExamInCoreConfiguration/helpers';
import { EXAM_CONFIGURATION_CONFIGS } from 'constants/Exam.constants';
export const initialState = {
    loading: false,
    hasErrors: false,
    examInCore: {
        isEnabled: false,
        configureFields: {}
    },
    configureFields: {
        // initialize all fields for exam in core with empty array
        ...EXAM_CONFIGURATION_CONFIGS.reduce((acc, field) => ({
            ...acc,
            [field.config]: []
        }), {})
    }
};
// Slice
const slice = createSlice({
    name: 'examInCoreSettings',
    initialState,
    reducers: {
        fetchExamInCoreSettingsRequest: state => {
            isLoadingRequest(state);
        },
        fetchExamInCoreSettingsSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.examInCore = payload;
            finishedLoadingSuccess(state);
        },
        fetchExamInCoreSettingsFailure: state => {
            finishedLoadingFailure(state);
        },
        saveExamInCoreSettingsRequest: state => {
            isLoadingRequest(state);
        },
        saveExamInCoreSettingsSuccess: state => {
            finishedLoadingSuccess(state);
        },
        saveExamInCoreSettingsFailure: state => {
            finishedLoadingFailure(state);
        },
        fetchConfigFieldsSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            const { result, config } = payload;
            if (!result || !result.length)
                return;
            state.configureFields[config] = result.map(field => ({
                label: field,
                value: field
            }));
            finishedLoadingSuccess(state);
        },
        updateConfigureFieldLocal: (state, _ref3) => {
            let { payload: { config, type, value } } = _ref3;
            const fieldPath = `examInCore.configureFields.${config}`;
            // reset the field if the source or mode is changed
            switch (type) {
                case 'source':
                    _.set(state, `${fieldPath}.mode`, null);
                    _.set(state, `${fieldPath}.field`, null);
                    _.set(state, `configureFields.${config}`, []); // clear the options for the field as well
                    break;
                case 'mode':
                    _.set(state, `${fieldPath}.field`, null);
                    break;
            }
            _.set(state, `${fieldPath}.${type}`, value);
        },
        updateEnabledLocal: (state, _ref4) => {
            let { payload } = _ref4;
            _.set(state, `examInCore.isEnabled`, payload);
        }
    }
});
export default slice.reducer;
// Selectors
export const examInCoreSettingsSelector = state => state.examInCoreSettings;
export const configFieldOptionsSelector = config => state => state.examInCoreSettings.configureFields[config] || [];
export const configFieldSelector = config => state => state.examInCoreSettings.examInCore.configureFields?.[config] || {
    source: null,
    mode: null,
    field: null
};
export const examInCoreLoading = state => state.examInCoreSettings.loading;
// Actions
export const { fetchExamInCoreSettingsRequest, fetchExamInCoreSettingsSuccess, fetchExamInCoreSettingsFailure, saveExamInCoreSettingsRequest, saveExamInCoreSettingsSuccess, saveExamInCoreSettingsFailure, fetchConfigFieldsSuccess, updateConfigureFieldLocal, updateEnabledLocal } = slice.actions;
export const fetchExamInCoreSettings = createAppAsyncThunk('examInCoreSettings/fetchExamInCoreSettings', async (_, _ref5) => {
    let { dispatch } = _ref5;
    try {
        dispatch(fetchExamInCoreSettingsRequest());
        const result = await api.get({
            endpoint: `/apps/TE_EXAM/exam-in-core`
        });
        dispatch(fetchExamInCoreSettingsSuccess(result));
    }
    catch (e) {
        dispatch(fetchExamInCoreSettingsFailure());
        console.error(e);
    }
});
export const fetchObjectFields = createAppAsyncThunk('examInCoreSettings/fetchObjectFields', async (object, _ref6) => {
    let { dispatch } = _ref6;
    try {
        const result = await api.get({
            endpoint: `/apps/TE_EXAM/integration/objects/types/${object}/fields`
        });
        return result.data || [];
    }
    catch (e) {
        dispatch(fetchExamInCoreSettingsFailure());
        console.error(e);
    }
});
export const fetchReservationFields = createAppAsyncThunk('examInCoreSettings/fetchReservationFields', async (object, _ref7) => {
    let { dispatch } = _ref7;
    try {
        const result = await api.get({
            endpoint: `/apps/TE_EXAM/integration/reservations/fields/${object}`
        });
        return result.data || [];
    }
    catch (e) {
        dispatch(fetchExamInCoreSettingsFailure());
        console.error(e);
    }
});
export const fetchConfigFields = createAppAsyncThunk('examInCoreSettings/fetchConfigFields', async (_ref8, _ref9) => {
    let { config, object } = _ref8;
    let { dispatch, getState } = _ref9;
    const source = getState().examInCoreSettings.examInCore?.configureFields?.[config]?.source;
    if (!source || !object)
        return;
    const result = await dispatch(source === EXAM_CONFIGURATION_FIELD_MODE.OBJECT_TYPES ? fetchObjectFields(object) : fetchReservationFields(object)).unwrap();
    dispatch(fetchConfigFieldsSuccess({
        config,
        result
    }));
});
export const saveExamInCoreSettings = createAppAsyncThunk('examInCoreSettings/saveExamInCoreSettings', async (examInCoreSettings, _ref10) => {
    let { dispatch } = _ref10;
    try {
        dispatch(saveExamInCoreSettingsRequest());
        const payload = _.omit(examInCoreSettings, ['id', 'organizationId', 'createdAt', 'updatedAt', 'configureFields.examTitle._id', 'configureFields.student._id', 'configureFields.externalRef._id', 'configureFields.adminInfo._id', 'configureFields.studentInfo._id', 'configureFields.supervisorInfo._id', 'orgIdV2']);
        await api.patch({
            endpoint: '/apps/TE_EXAM/exam-in-core',
            data: payload
        });
        dispatch(saveExamInCoreSettingsSuccess());
    }
    catch (error) {
        dispatch(saveExamInCoreSettingsFailure());
        console.error(error);
    }
});
