import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import { createAppAsyncThunk } from 'slices/utils';
export const initialState = {
    loading: false,
    hasErrors: false,
    weekNames: []
};
// Slice
const slice = createSlice({
    name: 'prefWeekNames',
    initialState,
    reducers: {
        fetchData: state => {
            isLoadingRequest(state);
        },
        fetchDataSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.weekNames = payload;
            finishedLoadingSuccess(state);
        },
        fetchDataFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const weekNamesLoading = state => state.prefWeekNames.loading;
export const weekNamesSelector = state => state.prefWeekNames.weekNames;
// Actions
export const { fetchData, fetchDataSuccess, fetchDataFailure } = slice.actions;
export const getWeekNames = createAppAsyncThunk('prefWeekNames/getWeekNames', async (_, _ref2) => {
    let { dispatch } = _ref2;
    try {
        dispatch(fetchData());
        const { weekNames } = await api.get({
            endpoint: '/apps/TE_PREFERENCES/week-names'
        });
        dispatch(fetchDataSuccess(weekNames));
    }
    catch (e) {
        dispatch(fetchDataFailure());
        return console.error(e);
    }
});
export const updateWeekNames = createAppAsyncThunk('prefWeekNames/updateWeekNames', async (weekNames, _ref3) => {
    let { dispatch } = _ref3;
    try {
        await api.patch({
            endpoint: '/apps/TE_PREFERENCES/week-names',
            data: {
                weekNames
            }
        });
        dispatch(getWeekNames());
    }
    catch (e) {
        dispatch(fetchDataFailure());
        return console.error(e);
    }
});
