import { createSlice } from '@reduxjs/toolkit';
import { createPaddingRule as create, EPaddingRuleType } from '@timeedit/types';
import _ from 'lodash';
import { createAppAsyncThunk } from 'slices/utils';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { deleteEntityFromState, finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, prepEntityForAPICall, stateHasFetchedAllObjectsSuccess } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0,
    firstPaddingRule: undefined,
    secondPaddingRule: undefined
};
// Slice
const slice = createSlice({
    name: 'paddingRules',
    initialState,
    reducers: {
        fetchPaddingRulesRequest: state => {
            isLoadingRequest(state);
        },
        fetchPaddingRulesSuccess: (state, _ref) => {
            let { payload } = _ref;
            stateHasFetchedAllObjectsSuccess(payload, state, create, false);
            finishedLoadingSuccess(state);
        },
        fetchPaddingRulesFailure: state => {
            finishedLoadingFailure(state);
        },
        createPaddingRule: (state, _ref2) => {
            let { payload } = _ref2;
            if (state.firstPaddingRule?.id === 0)
                return;
            const newPaddingRule = {
                id: 0,
                name: '',
                paddingType: payload,
                includeAbstractObjects: false,
                description: '',
                rule: payload === EPaddingRuleType.DIRECT ? {
                    objectsOnReservation: [],
                    impactedObjects: [],
                    paddingBefore: 0,
                    paddingAfter: 0
                } : {
                    controllingTypeId: 0,
                    controllingFieldId: 0,
                    distances: [],
                    impactedObjects: []
                },
                history: []
            };
            state.firstPaddingRule = newPaddingRule;
            state.results.unshift(newPaddingRule);
            state.map[0] = newPaddingRule;
            finishedLoadingSuccess(state);
        },
        savePaddingRuleRequest: state => {
            isLoadingRequest(state);
        },
        savePaddingRuleSuccess: (state, _ref3) => {
            let { payload: { isFirstPaddingRule } } = _ref3;
            if (isFirstPaddingRule) {
                state.firstPaddingRule = undefined;
            }
            else {
                state.secondPaddingRule = undefined;
            }
            finishedLoadingSuccess(state);
        },
        savePaddingRuleFailure: state => {
            finishedLoadingFailure(state);
        },
        deletePaddingRuleRequest: state => {
            isLoadingRequest(state);
        },
        deletePaddingRuleSuccess: (state, _ref4) => {
            let { payload: { paddingRuleId } } = _ref4;
            deleteEntityFromState(paddingRuleId, state);
            if (state.firstPaddingRule?.id === paddingRuleId) {
                state.firstPaddingRule = undefined;
            }
            else {
                state.secondPaddingRule = undefined;
            }
            finishedLoadingSuccess(state);
        },
        deletePaddingRuleFailure: state => {
            finishedLoadingFailure(state);
        },
        setFirstPaddingRule: (state, _ref5) => {
            let { payload } = _ref5;
            const previousFirstPaddingRule = state.firstPaddingRule;
            state.firstPaddingRule = payload;
            if (!payload && previousFirstPaddingRule?.id === 0) {
                state.results = state.results.filter(paddingRule => paddingRule.id !== 0);
            }
        },
        setSecondPaddingRule: (state, _ref6) => {
            let { payload } = _ref6;
            state.secondPaddingRule = payload;
        },
        duplicatePaddingRule: (state, _ref7) => {
            let { payload } = _ref7;
            if (state.firstPaddingRule?.id === 0)
                return;
            const newPaddingRule = {
                ...payload,
                id: 0,
                name: payload.name + '-copy',
                history: []
            };
            state.firstPaddingRule = newPaddingRule;
            state.results.unshift(newPaddingRule);
            state.map[0] = newPaddingRule;
        },
        discardChanges: (state, _ref8) => {
            let { payload } = _ref8;
            const { isFirstDrawer, id } = payload;
            if (isFirstDrawer) {
                state.firstPaddingRule = state.map[id];
            }
            else {
                state.secondPaddingRule = state.map[id];
            }
        },
        changePaddingRule: (state, _ref9) => {
            let { payload } = _ref9;
            const { isFirstDrawer, key, value } = payload;
            if (isFirstDrawer) {
                state.firstPaddingRule = {
                    ...state.firstPaddingRule,
                    [key]: value
                };
            }
            else {
                state.secondPaddingRule = {
                    ...state.secondPaddingRule,
                    [key]: value
                };
            }
        }
    }
});
export default slice.reducer;
// Selectors
export const paddingRulesLoading = state => state.paddingRules.loading;
export const paddingRulesSelector = state => state.paddingRules.results;
export const paddingRuleMapSelector = state => state.paddingRules.map;
export const firstPaddingRuleSelector = state => state.paddingRules.firstPaddingRule;
export const secondPaddingRuleSelector = state => state.paddingRules.secondPaddingRule;
export const selectedIdsSelector = state => _.compact([state.paddingRules.firstPaddingRule, state.paddingRules.secondPaddingRule]).map(pr => pr.id);
// Actions
export const { fetchPaddingRulesRequest, fetchPaddingRulesSuccess, fetchPaddingRulesFailure, createPaddingRule, deletePaddingRuleRequest, deletePaddingRuleFailure, deletePaddingRuleSuccess, savePaddingRuleRequest, savePaddingRuleSuccess, savePaddingRuleFailure, setFirstPaddingRule, setSecondPaddingRule, duplicatePaddingRule, discardChanges, changePaddingRule } = slice.actions;
export const fetchPaddingRules = createAppAsyncThunk('paddingRules/fetchPaddingRules', async (_, _ref10) => {
    let { dispatch } = _ref10;
    try {
        dispatch(fetchPaddingRulesRequest());
        const result = await api.get({
            endpoint: `/padding-rules`
        });
        dispatch(fetchPaddingRulesSuccess(result));
    }
    catch (e) {
        dispatch(fetchPaddingRulesFailure());
        console.error(e);
    }
});
export const savePaddingRule = createAppAsyncThunk('paddingRules/savePaddingRule', async (_ref11, _ref12) => {
    let { isFirstPaddingRule, paddingRule } = _ref11;
    let { dispatch } = _ref12;
    try {
        dispatch(savePaddingRuleRequest());
        /**
         * Could be both either create or update
         * Depending on which, we need to use different methods (POST or PATCH)
         */
        if (paddingRule.id === 0) {
            // Create a new one
            const preppedPaddingRule = prepEntityForAPICall(paddingRule, true, true);
            delete preppedPaddingRule.history;
            await api.post({
                endpoint: `/padding-rules`,
                data: preppedPaddingRule
            });
            dispatch(savePaddingRuleSuccess({
                isFirstPaddingRule
            }));
            dispatch(fetchPaddingRules());
        }
        else {
            const preppedPaddingRule = prepEntityForAPICall(paddingRule, true, true);
            delete preppedPaddingRule.history;
            await api.patch({
                endpoint: `/padding-rules/${paddingRule.id}`,
                data: preppedPaddingRule
            });
            dispatch(savePaddingRuleSuccess({
                isFirstPaddingRule
            }));
            dispatch(fetchPaddingRules());
        }
    }
    catch (error) {
        dispatch(savePaddingRuleFailure());
        console.error(error);
    }
});
export const deletePaddingRule = createAppAsyncThunk('paddingRules/deletePaddingRule', async (paddingRuleId, _ref13) => {
    let { dispatch } = _ref13;
    try {
        dispatch(deletePaddingRuleRequest());
        /**
         * No need to send the request to the backend if the entity hasn't been saved
         */
        if (paddingRuleId !== 0)
            await api.delete({
                endpoint: `/padding-rules/${paddingRuleId}`
            });
        dispatch(deletePaddingRuleSuccess({
            paddingRuleId
        }));
    }
    catch (e) {
        dispatch(deletePaddingRuleFailure());
        console.error(e);
    }
});
/**
 * Travel time padding rule has distances array, each of which has fieldValues array.
 * The order of array are changed after matrix component, so we need to sort them before comparing.
 **/
const checkPaddingRuleChanged = (paddingRule1, paddingRule2) => {
    if (paddingRule1.paddingType === EPaddingRuleType.DIRECT)
        return checkSavedDisabled(paddingRule1, paddingRule2);
    return checkSavedDisabled(normalizePaddingRule(paddingRule1), normalizePaddingRule(paddingRule2));
};
function normalizePaddingRule(paddingRule) {
    return {
        ...paddingRule,
        rule: {
            ...paddingRule.rule,
            distances: sortDistances(paddingRule.rule.distances || [])
        }
    };
}
function sortDistances(distances) {
    const newDistances = distances.filter(distance => distance.padding !== 0).map(distance => {
        return {
            ...distance,
            fieldValues: _.sortBy(distance.fieldValues, fieldValue => fieldValue)
        };
    });
    newDistances.sort((a, b) => a.fieldValues[0].localeCompare(b.fieldValues[0]));
    return newDistances;
}
export const checkPaddingSaveDisabled = isFirstDrawer => (_, getState) => {
    const { paddingRules } = getState();
    const { firstPaddingRule, secondPaddingRule, map: paddingRuleMap } = paddingRules;
    if (isFirstDrawer && firstPaddingRule && paddingRuleMap[firstPaddingRule.id]) {
        return checkPaddingRuleChanged(firstPaddingRule, paddingRuleMap[firstPaddingRule.id]);
    }
    if (!isFirstDrawer && secondPaddingRule && paddingRuleMap[secondPaddingRule.id]) {
        return checkPaddingRuleChanged(secondPaddingRule, paddingRuleMap[secondPaddingRule.id]);
    }
    return true;
};
export const checkFirstPaddingRuleExist = () => (_, getState) => !!getState().paddingRules.firstPaddingRule;
