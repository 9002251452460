import { assertListWithIdElements, assertWithId } from './utils';
/**
 * standardized way of parsing a list API response into the redux state
 */
export function stateHasFetchedAllObjectsSuccess(payload, state, createFn) {
    let retainNewEl = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    const { results, page, limit, totalPages } = payload;
    const iteratedResults = results.map(el => createFn(el));
    state.page = page;
    state.limit = limit;
    state.totalPages = totalPages;
    const newEl = state.map[0] || state.map['0'] || state.map['new'];
    if (retainNewEl && newEl) {
        state.results = [...iteratedResults, newEl];
    }
    else {
        state.results = iteratedResults;
    }
    if (!assertListWithIdElements(iteratedResults)) {
        return;
    }
    const map = iteratedResults.reduce((tot, acc) => ({
        ...tot,
        [acc.id]: acc
    }), {});
    if (retainNewEl && assertWithId(newEl)) {
        state.map = {
            ...map,
            [newEl.id]: newEl
        };
    }
    else {
        state.map = map;
    }
}
