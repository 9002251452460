import { useLayoutEffect, useState, useMemo } from 'react';
import { debounce } from 'lodash';
/**
 * Keep the Table the height of the parent, minus the header and footer.
 * This is to make sure that the table is scrollable and the header and
 * footer are always visible.
 *
 * @param ref A ref to the container div
 * @returns table height in px or undefined if the ref is not set
 */
export const useTableDimensions = ref => {
    // Keep the Table the height of the parent.
    const [tableDimensions, setTableDimensions] = useState({
        height: 0,
        width: 0
    });
    const resizeTable = useMemo(() => debounce(() => {
        const node = ref.current;
        if (!node) {
            return;
        }
        const { height, width } = node.getBoundingClientRect();
        // Fiddle with the values here subtract the height of the footer and header
        setTableDimensions({
            height: height - 78 - 34,
            width
        });
    }, 100, {
        trailing: true,
        maxWait: 100
    }), [ref]);
    useLayoutEffect(() => {
        resizeTable();
        window.addEventListener('resize', resizeTable);
        return () => {
            window.removeEventListener('resize', resizeTable);
        };
    }, [resizeTable]);
    return tableDimensions;
};
