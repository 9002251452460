import { createSlice } from '@reduxjs/toolkit';
import { createEmptyMemberType } from '@timeedit/types';
import _ from 'lodash';
import { createAppAsyncThunk } from 'slices/utils';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const initialState = {
    loading: false,
    hasErrors: false,
    allMemberships: [],
    membershipMap: {},
    firstMembership: null,
    secondMembership: null
};
// Slice
const slice = createSlice({
    name: 'memberships',
    initialState,
    reducers: {
        fetchMembershipsRequest: state => {
            isLoadingRequest(state);
        },
        fetchMembershipsSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.allMemberships = payload.results;
            state.membershipMap = _.keyBy(payload.results, 'id');
            finishedLoadingSuccess(state);
        },
        fetchMembershipsFailure: state => {
            finishedLoadingFailure(state);
        },
        deleteMembershipRequest: state => {
            isLoadingRequest(state);
        },
        deleteMembershipSuccess: (state, _ref2) => {
            let { payload: membershipId } = _ref2;
            state.allMemberships = state.allMemberships.filter(memberType => memberType.id !== membershipId);
            state.membershipMap[membershipId] = undefined;
            finishedLoadingSuccess(state);
        },
        deleteMembershipFailure: state => {
            finishedLoadingFailure(state);
        },
        updateMembershipRequest: state => {
            isLoadingRequest(state);
        },
        updateMembershipSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            const index = state.allMemberships.findIndex(memberType => memberType.id === payload.id);
            state.allMemberships[index] = payload;
            state.membershipMap[payload.id] = payload;
            finishedLoadingSuccess(state);
        },
        updateMembershipFailure: state => {
            finishedLoadingFailure(state);
        },
        setFirstMembership: (state, _ref4) => {
            let { payload } = _ref4;
            const previousFirstMembership = state.firstMembership;
            state.firstMembership = payload;
            if (previousFirstMembership?.id === 0) {
                state.allMemberships = state.allMemberships.filter(membership => membership.id !== 0);
            }
        },
        setSecondMembership: (state, _ref5) => {
            let { payload } = _ref5;
            state.secondMembership = payload;
        },
        createUnsavedMembership: (state, _ref6) => {
            let { payload: newMembership } = _ref6;
            if (state.firstMembership?.id === 0)
                return;
            state.firstMembership = newMembership;
            state.allMemberships.unshift(newMembership);
            state.membershipMap[0] = newMembership;
        },
        discardChanges: (state, _ref7) => {
            let { payload } = _ref7;
            const { isFirstDrawer, id } = payload;
            if (isFirstDrawer) {
                state.firstMembership = state.membershipMap[id];
            }
            else {
                state.secondMembership = state.membershipMap[id];
            }
        },
        changeMembership: (state, _ref8) => {
            let { payload } = _ref8;
            const { isFirstDrawer, key, value } = payload;
            if (isFirstDrawer) {
                state.firstMembership = {
                    ...state.firstMembership,
                    [key]: value
                };
            }
            else {
                state.secondMembership = {
                    ...state.secondMembership,
                    [key]: value
                };
            }
        },
        closeDrawer: (state, _ref9) => {
            let { payload: membershipId } = _ref9;
            if (state.firstMembership?.id === membershipId) {
                state.firstMembership = null;
            }
            else if (state.secondMembership?.id === membershipId) {
                state.secondMembership = null;
            }
        }
    }
});
export default slice.reducer;
// Selectors
export const membershipsLoading = state => state.memberships.loading;
export const membershipsSelector = state => state.memberships.allMemberships;
export const firstMembershipSelector = state => state.memberships.firstMembership;
export const secondMembershipSelector = state => state.memberships.secondMembership;
export const membershipMapSelector = state => state.memberships.membershipMap;
export const selectedIdsSelector = state => _.compact([state.memberships.firstMembership, state.memberships.secondMembership]).map(mem => mem.id);
// Actions
export const { fetchMembershipsRequest, fetchMembershipsSuccess, fetchMembershipsFailure, deleteMembershipRequest, deleteMembershipSuccess, deleteMembershipFailure, updateMembershipRequest, updateMembershipSuccess, updateMembershipFailure, setFirstMembership, setSecondMembership, createUnsavedMembership, discardChanges, changeMembership, closeDrawer } = slice.actions;
export const fetchMemberships = () => async (dispatch) => {
    try {
        dispatch(fetchMembershipsRequest());
        const memberships = await api.get({
            endpoint: `/member-types`
        });
        dispatch(fetchMembershipsSuccess(memberships));
    }
    catch (e) {
        dispatch(fetchMembershipsFailure());
        return console.error(e);
    }
};
export const createNewMembership = () => dispatch => {
    const newMembership = {
        ...createEmptyMemberType(),
        id: 0,
        name: ''
    };
    dispatch(createUnsavedMembership(newMembership));
};
export const duplicateMembership = membership => dispatch => {
    const newMembership = {
        ...membership,
        id: 0,
        name: membership.name + '-copy'
    };
    dispatch(createUnsavedMembership(newMembership));
};
export const deleteMembership = createAppAsyncThunk('memberships/deleteMembership', async (memberTypeId, _ref10) => {
    let { dispatch } = _ref10;
    try {
        dispatch(deleteMembershipRequest());
        await api.delete({
            endpoint: `/member-types/${memberTypeId}`
        });
        dispatch(deleteMembershipSuccess(memberTypeId));
        dispatch(closeDrawer(memberTypeId));
    }
    catch (e) {
        dispatch(deleteMembershipFailure());
        return console.error(e);
    }
});
export const saveMembership = createAppAsyncThunk('memberships/saveMembership', async (memberType, _ref11) => {
    let { dispatch } = _ref11;
    try {
        dispatch(updateMembershipRequest());
        const { id, ...membershipBody } = memberType;
        if (id === 0) {
            await api.post({
                endpoint: `/member-types`,
                data: membershipBody
            });
            dispatch(fetchMemberships());
        }
        else {
            const response = await api.patch({
                endpoint: `/member-types/${id}`,
                data: membershipBody
            });
            dispatch(updateMembershipSuccess(response));
        }
        dispatch(closeDrawer(id));
    }
    catch (e) {
        dispatch(updateMembershipFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const checkMembershipSaveDisabled = isFirstDrawer => (_, getState) => {
    const { memberships } = getState();
    const { firstMembership, secondMembership, membershipMap } = memberships;
    if (isFirstDrawer && firstMembership && membershipMap[firstMembership.id]) {
        return checkSavedDisabled(firstMembership, membershipMap[firstMembership.id]);
    }
    if (!isFirstDrawer && secondMembership && membershipMap[secondMembership.id]) {
        return checkSavedDisabled(secondMembership, membershipMap[secondMembership.id]);
    }
    return true;
};
export const checkFirstMembershipExist = () => (_, getState) => !!getState().memberships.firstMembership;
