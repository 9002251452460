import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    breadcrumbs: []
};
const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setBreadcrumbs: (state, _ref) => {
            let { payload } = _ref;
            state.breadcrumbs = payload;
        }
    }
});
export default uiSlice.reducer;
export const { setBreadcrumbs } = uiSlice.actions;
