import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, stateHasFetchedAllObjectsSuccess } from 'utils/sliceHelpers';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0,
    totalResults: 0,
    tags: []
};
const createLoggedEvent = payload => {
    return {
        id: payload.id,
        type: payload.type,
        level: payload.level,
        label: payload.label,
        items: payload.items,
        tags: payload.tags,
        createdAt: payload.createdAt,
        updatedAt: payload.updatedAt
    };
};
// Slice
const slice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
        fetchLogsRequest: state => {
            isLoadingRequest(state);
        },
        fetchLogsSuccess: (state, _ref) => {
            let { payload } = _ref;
            stateHasFetchedAllObjectsSuccess(payload, state, createLoggedEvent);
            state.totalResults = payload.totalResults;
            finishedLoadingSuccess(state);
        },
        fetchLogsFailure: state => {
            finishedLoadingFailure(state);
        },
        fetchTagsRequest: state => {
            isLoadingRequest(state);
        },
        fetchTagsSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            state.tags = payload;
        },
        fetchTagsFailure: () => { }
    }
});
export default slice.reducer;
// Selectors
export const logsLoading = state => state.logs?.loading || false;
export const logsSelector = state => {
    return state.logs?.results || [];
};
export const tagsSelector = state => {
    return state.logs?.tags || [];
};
export const totalPagesSelector = state => {
    return state.logs?.totalPages || 0;
};
export const totalResultsSelector = state => {
    return state.logs?.totalResults || 0;
};
// Actions
export const { fetchLogsRequest, fetchLogsSuccess, fetchLogsFailure, fetchTagsRequest, fetchTagsSuccess, fetchTagsFailure } = slice.actions;
export const fetchLogs = findLogRequestData => async (dispatch) => {
    const { path, ...data } = findLogRequestData;
    try {
        dispatch(fetchLogsRequest());
        const result = await api.post({
            endpoint: path,
            data,
            suppressNotification: true
        });
        dispatch(fetchLogsSuccess(result));
    }
    catch (e) {
        dispatch(fetchLogsFailure());
        console.error(e);
    }
};
export const fetchTags = (path, type, level, start, end) => async (dispatch) => {
    const queryParams = {
        type,
        ...(level && {
            level
        }),
        ...(start && {
            start: start.toString()
        }),
        ...(end && {
            end: end.toString()
        })
    };
    try {
        dispatch(fetchLogsRequest());
        const result = await api.get({
            endpoint: `${path}${queryParams ? `?${new URLSearchParams(queryParams)}` : ''}`
        });
        dispatch(fetchTagsSuccess(result));
    }
    catch (e) {
        dispatch(fetchTagsFailure());
        console.error(e);
    }
};
