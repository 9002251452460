// TYPES
// Getting right name on types
export const getNameOnType = (types, item) => {
    const result = types.find(i => i.id === item);
    if (result) {
        return result.name;
    }
    return item;
};
// Getting ext.id on type
export const getExtIdOnType = (types, item) => {
    const result = types.find(i => i.id === item);
    if (result) {
        return result.extId;
    }
    return item;
};
// Getting description on type
export const getDescriptionOnType = (types, item) => {
    const result = types.find(i => i.id === item);
    if (result) {
        return result.description;
    }
    return item;
};
// Getting ext.id on fields
export const getExtIdOnField = (fields, field) => {
    const result = fields.find(f => f.id === field);
    if (result) {
        return result.extId;
    }
    return field;
};
// Getting right name on fields
export const getNameOnField = (fields, field) => {
    const result = fields.find(f => f.id === field);
    if (result) {
        return result.name;
    }
    return field;
};
// Getting right name on organizations
export const getNameOnOrganization = (organizations, item) => {
    const result = organizations.find(org => org.id === item);
    if (result) {
        return result.name;
    }
    return item;
};
export const getNameOnOrganizationWithOrgId = (organizations, item) => {
    const result = organizations.find(org => org.organizationId === item);
    if (result) {
        return result.name;
    }
    return item;
};
// Getting right name on availabilityTypes
export const getNameOnAvailabilityType = (availabilityTypes, availabilityType) => {
    const result = availabilityTypes.find(o => o.id === availabilityType);
    if (result) {
        return result.name;
    }
    return availabilityType;
};
// Getting right name on objects
export const getNameOnObject = (objects, object) => {
    const result = objects.find(mt => mt.id === object);
    if (result) {
        return result.extId;
    }
    return object;
};
// Getting right extId on objects
export const getExtIdName = (array, item) => {
    const result = array.find(i => i.id === item);
    if (result) {
        return result.extId;
    }
    return item;
};
// Getting right name on reservation templates
export const getNameOnReservationTemplates = (reservationTemplates, templates) => reservationTemplates?.filter(rt => templates?.includes(rt.id))?.map(rt => rt.active === true ? `${rt.name}` : `(${rt.name})`) ?? [];
// Getting right name on timeZones
export const getNameOnTimeZone = (timeZones, timeZoneId) => {
    const result = timeZones.find(timeZone => timeZone.id === timeZoneId);
    if (result) {
        return result.name;
    }
    return timeZoneId;
};
// Getting name on role
export const getNameOnRole = (roles, role) => {
    const result = roles.find(r => r.id === parseInt(role));
    if (result) {
        return result.name;
    }
    return role;
};
// Getting name on user
export const getNameOnUser = user => {
    if (!user) {
        return 'N/A';
    }
    if (user.firstName || user.lastName) {
        return `${user.firstName || ''} ${user.lastName || ''}`;
    }
    if (user.email) {
        return user.email;
    }
    return user.id || 'N/A';
};
