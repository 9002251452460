import { useEffect, useRef } from 'react';
/**
 * @function traversedClassList
 * @description traverses the class list of a node to get all parent classes
 * @param {DOMNode} element
 * @returns Array
 */
const traversedClassList = element => {
    if (!element)
        return [];
    let currentNode = element;
    const classes = [];
    do {
        classes.push(...currentNode.classList);
        currentNode = currentNode.parentNode;
    } while (currentNode.parentNode);
    return classes;
};
export const EXCLUDED_MODAL_CLASSNAME = 'allowed-modal--wrapper';
export const SAVE_CHANGES_CLASSNAME = 'save-changes--btn';
const useOutsideClick = (outsideClickHandler, onUpdateRefValue) => {
    const rootNode = useRef();
    useEffect(() => {
        function handleClick(e) {
            if (traversedClassList(e.target).includes(SAVE_CHANGES_CLASSNAME)) {
                return onUpdateRefValue();
            }
            /**
             * Definition of outside click:
             * a) ref is valid
             * b) ref does not contain event target
             * c) event target does not have our excluded class as part of its parent class list tree
             */
            if ((!rootNode.current || !rootNode.current.contains(e.target)) && !traversedClassList(e.target).includes(EXCLUDED_MODAL_CLASSNAME) && e.isTrusted) {
                outsideClickHandler(e);
            }
        }
        // Add event listener when mounted
        document.addEventListener('mousedown', handleClick, {
            capture: true
        });
        // Remove event listener when unmounted
        return () => {
            document.removeEventListener('mousedown', handleClick, {
                capture: true
            });
        };
    }, [onUpdateRefValue, outsideClickHandler]);
    return rootNode;
};
export default useOutsideClick;
