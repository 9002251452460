import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { deleteEntityFromState, finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, prepEntityForAPICall } from 'utils/sliceHelpers';
import { EDynamicAvailabilityType, ETimeScope } from '@timeedit/types';
import { compact, keyBy } from 'lodash';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { createAppAsyncThunk } from 'slices/utils';
import { startAppListening } from './listenerMiddleware';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0,
    appliedRules: {},
    firstDynamicAvailability: undefined,
    secondDynamicAvailability: undefined,
    selectedDynamicAvailabilityIds: []
};
const slice = createSlice({
    name: 'dynamicAvailability',
    initialState,
    reducers: {
        fetchDynamicAvailabilitiesRequest: state => {
            isLoadingRequest(state);
        },
        fetchDynamicAvailabilitiesSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.results = payload;
            state.map = keyBy(payload, 'id');
            finishedLoadingSuccess(state);
        },
        fetchDynamicAvailabilitiesFailure: state => {
            finishedLoadingFailure(state);
        },
        createDynamicAvailability: (state, _ref2) => {
            let { payload: type } = _ref2;
            const newDynamicAvailability = {
                id: 0,
                name: ``,
                description: '',
                dynamicAvailabilityType: type,
                scopeType: ETimeScope.DAILY,
                includeAbstractObjects: false
            };
            state.firstDynamicAvailability = newDynamicAvailability;
            state.results = state.results.filter(da => da.id !== 0);
            state.results.unshift(newDynamicAvailability);
            state.map[0] = newDynamicAvailability;
            finishedLoadingSuccess(state);
        },
        saveDynamicAvailabilityRequest: state => {
            isLoadingRequest(state);
        },
        saveDynamicAvailabilitySuccess: (state, _ref3) => {
            let { payload: { isFirstDynamicAvailability } } = _ref3;
            if (isFirstDynamicAvailability) {
                state.firstDynamicAvailability = undefined;
            }
            else {
                state.secondDynamicAvailability = undefined;
            }
            finishedLoadingSuccess(state);
        },
        saveDynamicAvailabilityFailure: state => {
            finishedLoadingFailure(state);
        },
        deleteDynamicAvailabilityRequest: state => {
            isLoadingRequest(state);
        },
        deleteDynamicAvailabilitySuccess: (state, _ref4) => {
            let { payload: { dynamicAvailabilityId } } = _ref4;
            deleteEntityFromState(dynamicAvailabilityId, state);
            if (dynamicAvailabilityId === state?.firstDynamicAvailability?.id) {
                state.firstDynamicAvailability = undefined;
            }
            if (dynamicAvailabilityId === state?.secondDynamicAvailability?.id) {
                state.secondDynamicAvailability = undefined;
            }
            finishedLoadingSuccess(state);
        },
        deleteDynamicAvailabilityFailure: state => {
            finishedLoadingFailure(state);
        },
        setFirstDynamicAvailability: (state, _ref5) => {
            let { payload } = _ref5;
            state.firstDynamicAvailability = payload;
            if (!payload || payload && payload.id !== 0) {
                state.results = state.results.filter(da => da.id !== 0);
                state.map[0] = undefined;
            }
        },
        setSecondDynamicAvailability: (state, _ref6) => {
            let { payload } = _ref6;
            state.secondDynamicAvailability = payload;
        },
        duplicateDynamicAvailability: (state, _ref7) => {
            let { payload } = _ref7;
            const newDynamicAvailability = {
                ...payload,
                id: 0,
                name: `${payload.name}-copy`,
                history: undefined
            };
            state.firstDynamicAvailability = newDynamicAvailability;
            state.results = state.results.filter(da => da.id !== 0);
            state.results.unshift(newDynamicAvailability);
            state.map[0] = newDynamicAvailability;
        },
        setSelectedDynamicAvailabilityIds: (state, _ref8) => {
            let { payload } = _ref8;
            state.selectedDynamicAvailabilityIds = payload;
        }
    }
});
export default slice.reducer;
export const dynamicAvailabilityLoading = state => state.dynamicAvailability.loading;
export const dynamicAvailabilitySelector = state => state.dynamicAvailability.results;
export const dynamicAvailabilityMapSelector = state => state.dynamicAvailability.map;
export const firstDynamicAvailabilitySelector = state => state.dynamicAvailability.firstDynamicAvailability;
export const secondDynamicAvailabilitySelector = state => state.dynamicAvailability.secondDynamicAvailability;
export const selectedDynamicAvailabilityIdsSelector = state => state.dynamicAvailability.selectedDynamicAvailabilityIds;
export const { fetchDynamicAvailabilitiesRequest, fetchDynamicAvailabilitiesSuccess, fetchDynamicAvailabilitiesFailure, createDynamicAvailability, deleteDynamicAvailabilityRequest, deleteDynamicAvailabilityFailure, deleteDynamicAvailabilitySuccess, saveDynamicAvailabilityRequest, saveDynamicAvailabilitySuccess, saveDynamicAvailabilityFailure, setFirstDynamicAvailability, setSecondDynamicAvailability, setSelectedDynamicAvailabilityIds, duplicateDynamicAvailability } = slice.actions;
export const fetchDynamicAvailabilities = createAppAsyncThunk('dynamicAvailability/fetchDynamicAvailabilities', async (_, _ref9) => {
    let { dispatch } = _ref9;
    try {
        dispatch(fetchDynamicAvailabilitiesRequest());
        const { results } = await api.get({
            endpoint: `/dynamic-availability`
        });
        dispatch(fetchDynamicAvailabilitiesSuccess(results));
    }
    catch (e) {
        dispatch(fetchDynamicAvailabilitiesFailure());
        console.error(e);
    }
});
export const saveDynamicAvailability = createAppAsyncThunk('dynamicAvailability/saveDynamicAvailability', async (_ref10, _ref11) => {
    let { isFirstDynamicAvailability, dynamicAvailability } = _ref10;
    let { dispatch } = _ref11;
    try {
        dispatch(saveDynamicAvailabilityRequest());
        /**
         * Could be both a create new or an update
         * Depending on which, we need to use different methods (POST or PATCH)
         */
        const preppedDynamicAvailability = prepEntityForAPICall(dynamicAvailability, true, true);
        delete preppedDynamicAvailability.history;
        const type = dynamicAvailability.dynamicAvailabilityType;
        if (type === EDynamicAvailabilityType.FREE_TIME) {
            delete preppedDynamicAvailability.maxReservationLength;
        }
        if (type === EDynamicAvailabilityType.TOTAL_HOURS) {
            delete preppedDynamicAvailability.minBreakLength;
            delete preppedDynamicAvailability.minBreakCount;
        }
        if (type === EDynamicAvailabilityType.CONSECUTIVE_HOURS) {
            delete preppedDynamicAvailability.minBreakCount;
        }
        if (dynamicAvailability.id === 0) {
            // Create a new one
            await api.post({
                endpoint: `/dynamic-availability`,
                data: preppedDynamicAvailability
            });
            dispatch(saveDynamicAvailabilitySuccess({
                isFirstDynamicAvailability
            }));
            dispatch(fetchDynamicAvailabilities());
        }
        else {
            await api.patch({
                endpoint: `/dynamic-availability/${dynamicAvailability.id}`,
                data: preppedDynamicAvailability
            });
            dispatch(saveDynamicAvailabilitySuccess({
                isFirstDynamicAvailability
            }));
            dispatch(fetchDynamicAvailabilities());
        }
    }
    catch (error) {
        dispatch(saveDynamicAvailabilityFailure());
        console.error(error);
    }
});
export const deleteDynamicAvailability = createAppAsyncThunk('dynamicAvailability/deleteDynamicAvailability', async (dynamicAvailabilityId, _ref12) => {
    let { dispatch } = _ref12;
    try {
        dispatch(deleteDynamicAvailabilityRequest());
        /**
         * No need to send the request to the backend if the entity hasn't been saved
         */
        if (dynamicAvailabilityId !== 0)
            await api.delete({
                endpoint: `/dynamic-availability/${dynamicAvailabilityId}`
            });
        dispatch(deleteDynamicAvailabilitySuccess({
            dynamicAvailabilityId
        }));
    }
    catch (e) {
        dispatch(deleteDynamicAvailabilityFailure());
        console.error(e);
    }
});
export const checkDisable = idFirstDynamicAvailability => (dispatch, getStore) => {
    const { firstDynamicAvailability, secondDynamicAvailability, map } = getStore().dynamicAvailability;
    const drawer = idFirstDynamicAvailability ? firstDynamicAvailability : secondDynamicAvailability;
    return !drawer || !map[drawer.id] || checkSavedDisabled(drawer, map[drawer.id]);
};
export const getDrawer = idFirstDynamicAvailability => (dispatch, getStore) => {
    const { firstDynamicAvailability, secondDynamicAvailability } = getStore().dynamicAvailability;
    return idFirstDynamicAvailability ? firstDynamicAvailability : secondDynamicAvailability;
};
startAppListening({
    predicate: (_, currentState, prevState) => currentState.dynamicAvailability.firstDynamicAvailability?.id !== prevState.dynamicAvailability.firstDynamicAvailability?.id || currentState.dynamicAvailability.secondDynamicAvailability?.id !== prevState.dynamicAvailability.secondDynamicAvailability?.id,
    effect: (_, _ref13) => {
        let { dispatch, getState } = _ref13;
        const { dynamicAvailability: { firstDynamicAvailability, secondDynamicAvailability } } = getState();
        dispatch(setSelectedDynamicAvailabilityIds(compact([firstDynamicAvailability?.id, secondDynamicAvailability?.id])));
    }
});
