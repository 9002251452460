import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, stateHasFetchedAllObjectsSuccess } from 'utils/sliceHelpers';
import { createAppAsyncThunk } from 'slices/utils';
import { createPermission, EPermissionTypes } from '@timeedit/types';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0
};
const slice = createSlice({
    name: 'assignablePermissions',
    initialState,
    reducers: {
        fetchAssignablePermissionsRequest: state => {
            isLoadingRequest(state);
        },
        fetchAssignablePermissionsSuccess: (state, _ref) => {
            let { payload } = _ref;
            stateHasFetchedAllObjectsSuccess(payload, state, createPermission);
            finishedLoadingSuccess(state);
        },
        fetchAssignablePermissionsFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
export const assignableScopesSelector = state => state.assignablePermissions.results.filter(permission => permission.type === EPermissionTypes.SCOPE);
export const assignableAppPermissionsSelector = state => state.assignablePermissions.results.filter(permission => permission.type === EPermissionTypes.APP_PERMISSION);
export const { fetchAssignablePermissionsRequest, fetchAssignablePermissionsSuccess, fetchAssignablePermissionsFailure } = slice.actions;
export const fetchAssignablePermissions = createAppAsyncThunk('assignablePermissions/fetchAssignablePermissions', async (_, _ref2) => {
    let { dispatch } = _ref2;
    try {
        dispatch(fetchAssignablePermissionsRequest());
        const result = await api.get({
            endpoint: `/permissions?sortBy=name&assignableOnly=true`
        });
        dispatch(fetchAssignablePermissionsSuccess(result));
    }
    catch (e) {
        dispatch(fetchAssignablePermissionsFailure());
        console.error(e);
    }
});
