import { createSlice } from '@reduxjs/toolkit';
import { compact, keyBy } from 'lodash';
// TYPES
// ACTIONS
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from '../utils/sliceHelpers';
// Utils
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { createEmptyTimeZone } from '@timeedit/types/lib/utils/timeZone.util';
import api from '../services/api.service';
import { startAppListening } from './listenerMiddleware';
export const initialState = {
    loading: false,
    hasErrors: false,
    allTimeZones: [],
    selectedTimeZones: [],
    zones: [],
    dsts: [],
    timeZoneMap: {},
    zoneMap: {},
    dstMap: {},
    firstTimeZone: null,
    secondTimeZone: null,
    selectedTimeZoneIds: []
};
// Slice
const slice = createSlice({
    name: 'timeZones',
    initialState,
    reducers: {
        fetchTimeZonesRequest: state => {
            isLoadingRequest(state);
        },
        fetchTimeZonesSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.allTimeZones = payload.results;
            state.timeZoneMap = keyBy(payload.results, 'id');
            finishedLoadingSuccess(state);
        },
        fetchZonesSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            state.zones = payload.results;
            state.zoneMap = keyBy(payload.results, 'id');
            finishedLoadingSuccess(state);
        },
        fetchDstsSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            state.dsts = payload.results;
            state.dstMap = keyBy(payload.results, 'id');
            finishedLoadingSuccess(state);
        },
        fetchTimeZonesFailure: state => {
            finishedLoadingFailure(state);
        },
        createTimeZoneRequest: state => {
            isLoadingRequest(state);
        },
        createTimeZoneSuccess: (state, _ref4) => {
            let { payload } = _ref4;
            state.allTimeZones.push(payload);
            state.timeZoneMap[payload.id] = payload;
            finishedLoadingSuccess(state);
        },
        createTimeZoneFailure: state => {
            finishedLoadingFailure(state);
        },
        setSelectedTimeZones: (state, _ref5) => {
            let { payload } = _ref5;
            state.selectedTimeZones = payload;
        },
        deleteTimeZoneRequest: state => {
            isLoadingRequest(state);
        },
        deleteTimeZoneSuccess: (state, _ref6) => {
            let { payload: timeZoneId } = _ref6;
            state.allTimeZones = state.allTimeZones.filter(timeZone => timeZone.id !== timeZoneId);
            state.timeZoneMap[timeZoneId] = undefined;
            finishedLoadingSuccess(state);
        },
        deleteTimeZoneFailure: state => {
            finishedLoadingFailure(state);
        },
        updateTimeZoneRequest: state => {
            isLoadingRequest(state);
        },
        updateTimeZoneSuccess: (state, _ref7) => {
            let { payload } = _ref7;
            const index = state.allTimeZones.findIndex(timeZone => timeZone.id === payload.id);
            state.allTimeZones[index] = payload;
            state.timeZoneMap[payload.id] = payload;
            finishedLoadingSuccess(state);
        },
        updateTimeZoneFailure: state => {
            finishedLoadingFailure(state);
        },
        moveTimeZonesRequest: state => {
            isLoadingRequest(state);
        },
        moveTimeZonesFailure: state => {
            finishedLoadingFailure(state);
        },
        setFirstTimeZone: (state, _ref8) => {
            let { payload } = _ref8;
            const prevItem = state.firstTimeZone;
            state.firstTimeZone = payload;
            if (prevItem?.id === 0) {
                state.timeZoneMap[0] = undefined;
                state.allTimeZones = state.allTimeZones.filter(ar => ar.id !== 0);
            }
        },
        setSecondTimeZone: (state, _ref9) => {
            let { payload } = _ref9;
            state.secondTimeZone = payload;
        },
        createUnsavedTimeZone: state => {
            if (state.firstTimeZone?.id === 0)
                return;
            const newItem = createEmptyTimeZone();
            state.firstTimeZone = newItem;
            state.timeZoneMap[newItem.id] = newItem;
            state.allTimeZones.unshift(newItem);
        },
        duplicateTimeZone: (state, _ref10) => {
            let { payload } = _ref10;
            if (state.firstTimeZone?.id === 0)
                return;
            const newItem = {
                ...payload,
                id: 0,
                name: `${payload.name} (copy)`
            };
            state.firstTimeZone = newItem;
            state.timeZoneMap[newItem.id] = newItem;
            state.allTimeZones.unshift(newItem);
        },
        changeTimeZone: (state, _ref11) => {
            let { payload } = _ref11;
            const { isFirstDrawer, key, value, object } = payload;
            const changedObject = object || {
                [key]: value
            };
            if (isFirstDrawer) {
                state.firstTimeZone = {
                    ...state.firstTimeZone,
                    ...changedObject
                };
            }
            else {
                state.secondTimeZone = {
                    ...state.secondTimeZone,
                    ...changedObject
                };
            }
        },
        discardChanges: (state, _ref12) => {
            let { payload } = _ref12;
            const { isFirstDrawer, id } = payload;
            if (isFirstDrawer) {
                state.firstTimeZone = state.timeZoneMap[id];
            }
            else {
                state.secondTimeZone = state.timeZoneMap[id];
            }
        },
        closeDrawer: (state, _ref13) => {
            let { payload: id } = _ref13;
            if (state.firstTimeZone?.id === id) {
                state.firstTimeZone = null;
            }
            if (state.secondTimeZone?.id === id) {
                state.secondTimeZone = null;
            }
        },
        setSelectedTimezoneIds: (state, _ref14) => {
            let { payload } = _ref14;
            state.selectedTimeZoneIds = payload;
        }
    }
});
export default slice.reducer;
// Selectors
export const timeZonesLoading = state => state.timeZones.loading;
export const timeZonesSelector = state => state.timeZones.allTimeZones;
export const selectedTimeZonesSelector = state => {
    return state.timeZones.selectedTimeZones;
};
export const zonesSelector = state => state.timeZones.zones;
export const dstsSelector = state => state.timeZones.dsts;
export const firstTimeZoneSelector = state => state.timeZones.firstTimeZone;
export const secondTimeZoneSelector = state => state.timeZones.secondTimeZone;
export const selectedTimeZoneIdsSelector = state => state.timeZones.selectedTimeZoneIds;
export const timeZoneMapSelector = state => state.timeZones.timeZoneMap;
export const zoneMapSelector = state => state.timeZones.zoneMap;
export const dstMapSelector = state => state.timeZones.dstMap;
export const timeZoneLoadingSelector = state => state.timeZones.loading;
// Actions
export const { fetchTimeZonesRequest, fetchTimeZonesSuccess, fetchTimeZonesFailure, fetchZonesSuccess, fetchDstsSuccess, createTimeZoneRequest, createTimeZoneSuccess, createTimeZoneFailure, setSelectedTimeZones, deleteTimeZoneRequest, deleteTimeZoneSuccess, deleteTimeZoneFailure, updateTimeZoneRequest, updateTimeZoneSuccess, updateTimeZoneFailure, moveTimeZonesRequest, moveTimeZonesFailure, setFirstTimeZone, setSecondTimeZone, createUnsavedTimeZone, duplicateTimeZone, changeTimeZone, discardChanges, closeDrawer, setSelectedTimezoneIds } = slice.actions;
export const fetchTimeZones = () => async (dispatch) => {
    try {
        dispatch(fetchTimeZonesRequest());
        const timeZones = await api.get({
            endpoint: `/time-zones`
        });
        dispatch(fetchTimeZonesSuccess(timeZones));
    }
    catch (e) {
        dispatch(fetchTimeZonesFailure());
        return console.error(e);
    }
};
export const fetchZones = () => async (dispatch) => {
    try {
        dispatch(fetchTimeZonesRequest());
        const zones = await api.get({
            endpoint: `/time-zones/zones`
        });
        dispatch(fetchZonesSuccess(zones));
    }
    catch (e) {
        dispatch(fetchTimeZonesFailure());
        return console.error(e);
    }
};
export const fetchDsts = () => async (dispatch) => {
    try {
        dispatch(fetchTimeZonesRequest());
        const dsts = await api.get({
            endpoint: `/time-zones/dsts`
        });
        dispatch(fetchDstsSuccess(dsts));
    }
    catch (e) {
        dispatch(fetchTimeZonesFailure());
        return console.error(e);
    }
};
export const saveTimeZone = timeZone => async (dispatch) => {
    try {
        dispatch(createTimeZoneRequest());
        const { id, isDefault, offset, ...timeZoneBody } = timeZone;
        if (id === 0) {
            await api.post({
                endpoint: `/time-zones`,
                data: timeZoneBody
            });
            dispatch(fetchTimeZones());
        }
        else {
            const { timeZone, daylightSavingsTime, ...updateBody } = timeZoneBody;
            const response = await api.patch({
                endpoint: `/time-zones/${id}`,
                data: updateBody
            });
            dispatch(updateTimeZoneSuccess(response));
        }
        dispatch(closeDrawer(id));
    }
    catch (e) {
        dispatch(createTimeZoneFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
};
export const setUpdatedTimeZones = timeZones => async (dispatch) => {
    dispatch(setSelectedTimeZones(timeZones));
};
export const deleteTimeZone = timeZoneId => async (dispatch) => {
    try {
        dispatch(deleteTimeZoneRequest());
        await api.delete({
            endpoint: `/time-zones/${timeZoneId}`
        });
        dispatch(deleteTimeZoneSuccess(timeZoneId));
        dispatch(closeDrawer(timeZoneId));
    }
    catch (e) {
        dispatch(deleteTimeZoneFailure());
        return console.error(e);
    }
};
export const fetchNumberOfReservationsToBeMoved = async (fromTimeZone, toTimeZone) => {
    const amountOfReservationsToBeMoved = await api.get({
        endpoint: `/time-zones/move-reservations?fromTimeZone=${fromTimeZone}&toTimeZone=${toTimeZone}`
    });
    return amountOfReservationsToBeMoved.numberOfReservationsToBeMoved;
};
export const moveReservationsBetweenTimeZones = async (fromTimeZone, toTimeZone) => {
    const amountOfReservationsMoved = await api.patch({
        endpoint: `/time-zones/move-reservations`,
        data: {
            fromTimeZone,
            toTimeZone
        }
    });
    return amountOfReservationsMoved.numberOfReservationsMoved;
};
export const checkFirstTimeZoneExists = () => (_, getState) => !!getState().timeZones.firstTimeZone;
export const checkTimeZoneSaveDisabled = isFirstDrawer => (_, getState) => {
    const { timeZones } = getState();
    const { firstTimeZone, secondTimeZone, timeZoneMap } = timeZones;
    if (isFirstDrawer && firstTimeZone && timeZoneMap[firstTimeZone.id]) {
        return checkSavedDisabled(firstTimeZone, timeZoneMap[firstTimeZone.id]);
    }
    if (!isFirstDrawer && secondTimeZone && timeZoneMap[secondTimeZone.id]) {
        return checkSavedDisabled(secondTimeZone, timeZoneMap[secondTimeZone.id]);
    }
    return true;
};
startAppListening({
    predicate: (_, currentState, prevState) => currentState.timeZones.firstTimeZone?.id !== prevState.timeZones.firstTimeZone?.id || currentState.timeZones.secondTimeZone?.id !== prevState.timeZones.secondTimeZone?.id,
    effect: (_, _ref15) => {
        let { dispatch, getState } = _ref15;
        const { timeZones: { firstTimeZone, secondTimeZone } } = getState();
        dispatch(setSelectedTimezoneIds(compact([firstTimeZone?.id, secondTimeZone?.id])));
    }
});
