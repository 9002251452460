import { EUserVisibility } from '@timeedit/types';
import { EFilterType } from '@timeedit/ui-components';
export const filterFields = [{
        key: 'active',
        label: 'Active',
        type: EFilterType.boolean
    }, {
        key: 'visibility',
        label: 'Visibility',
        type: EFilterType.text
    }, {
        key: 'authConfigId',
        label: 'Auth Config',
        type: EFilterType.text
    }, {
        key: 'scopes',
        label: 'Scopes',
        type: EFilterType.text
    }, {
        key: 'appPermissions',
        label: 'App Permissions',
        type: EFilterType.text
    }, {
        key: 'lockedForExternalAuthentication',
        label: 'Locked',
        type: EFilterType.boolean
    }];
export const visibilityOptions = Object.keys(EUserVisibility).map(key => ({
    value: key,
    label: key
}));
export const lockedOptions = [{
        value: 'true',
        label: 'Locked'
    }, {
        value: 'false',
        label: 'Not locked'
    }];
