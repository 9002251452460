import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import { createAppAsyncThunk } from 'slices/utils';
export const initialState = {
    loading: false,
    hasErrors: false,
    viewLink: {}
};
// Slice prefReviewSettings
const slice = createSlice({
    name: 'appsPrefReviewSettings',
    initialState,
    reducers: {
        fetchData: state => {
            isLoadingRequest(state);
        },
        fetchDataSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.viewLink = payload;
            finishedLoadingSuccess(state);
        },
        fetchDataFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const PrefReviewLoading = state => state.appsPrefReviewSettings.loading;
export const PrefReviewSelector = state => state.appsPrefReviewSettings.viewLink;
// Actions
export const { fetchData, fetchDataSuccess, fetchDataFailure } = slice.actions;
export const getViewLink = createAppAsyncThunk('appsPrefReviewSettings/getViewLink', async (_, _ref2) => {
    let { dispatch } = _ref2;
    try {
        dispatch(fetchData());
        const data = await api.get({
            endpoint: '/apps/TE_PREFERENCES/view-link'
        });
        dispatch(fetchDataSuccess(data));
    }
    catch (e) {
        dispatch(fetchDataFailure());
        return console.error(e);
    }
});
export const updatePrefReview = createAppAsyncThunk('appsPrefReviewSettings/updatePrefReview', async (dataBody, _ref3) => {
    let { dispatch } = _ref3;
    try {
        await api.patch({
            endpoint: '/apps/TE_PREFERENCES/view-link',
            data: dataBody
        });
        dispatch(getViewLink());
    }
    catch (e) {
        dispatch(fetchDataFailure());
        return console.error(e);
    }
});
