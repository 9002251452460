import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { EReportingCapacitySource, EReportingTimeType } from '@timeedit/types';
import { createAppAsyncThunk } from 'slices/utils';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { deleteEntityFromState, finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
const TEMP_ID = 'NEW-REPORT-TEMPLATE';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0,
    firstDrawer: undefined,
    secondDrawer: undefined
};
// Slice
const slice = createSlice({
    name: 'reportTemplate',
    initialState,
    reducers: {
        fetchDataRequest: state => {
            isLoadingRequest(state);
        },
        fetchDataSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.results = payload.results;
            state.map = _.keyBy(payload.results, 'id');
            finishedLoadingSuccess(state);
        },
        fetchDataFailure: state => {
            finishedLoadingFailure(state);
        },
        setFieldsSetting: (state, _ref2) => {
            let { payload } = _ref2;
            const { id, fieldsSetting } = payload;
            state.map[id] = {
                ...state.map[id],
                fieldsSetting
            };
        },
        createReportTemplate: (state, _ref3) => {
            let { payload: { templateType, timezone } } = _ref3;
            if (state.firstDrawer?.id === TEMP_ID)
                return;
            const newReportTemplate = {
                id: TEMP_ID,
                name: '',
                description: '',
                templateType,
                availableType: EReportingTimeType.FIXED_HOUR,
                capacitySource: EReportingCapacitySource.FROM_FIELD,
                timezone
            };
            state.firstDrawer = newReportTemplate;
            state.results.unshift(newReportTemplate);
            state.map[TEMP_ID] = newReportTemplate;
            finishedLoadingSuccess(state);
        },
        saveReportTemplateRequest: state => {
            isLoadingRequest(state);
        },
        saveReportTemplateSuccess: (state, _ref4) => {
            let { payload: { isFirstReportTemplate } } = _ref4;
            if (isFirstReportTemplate) {
                state.firstDrawer = undefined;
            }
            else {
                state.secondDrawer = undefined;
            }
            finishedLoadingSuccess(state);
        },
        saveReportTemplateFailure: state => {
            finishedLoadingFailure(state);
        },
        deleteReportTemplateRequest: state => {
            isLoadingRequest(state);
        },
        deleteReportTemplateSuccess: (state, _ref5) => {
            let { payload: { reportTemplateId } } = _ref5;
            deleteEntityFromState(reportTemplateId, state);
            if (state.firstDrawer?.id === reportTemplateId) {
                state.firstDrawer = undefined;
            }
            else {
                state.secondDrawer = undefined;
            }
            finishedLoadingSuccess(state);
        },
        deleteReportTemplateFailure: state => {
            finishedLoadingFailure(state);
        },
        setFirstReportTemplate: (state, _ref6) => {
            let { payload } = _ref6;
            const previousFirstReportTemplate = state.firstDrawer;
            state.firstDrawer = payload;
            if (!payload && previousFirstReportTemplate?.id === TEMP_ID) {
                state.results = state.results.filter(reportTemplate => reportTemplate.id !== TEMP_ID);
            }
        },
        setSecondReportTemplate: (state, _ref7) => {
            let { payload } = _ref7;
            state.secondDrawer = payload;
        },
        duplicateReportTemplate: (state, _ref8) => {
            let { payload } = _ref8;
            if (state.firstDrawer?.id === TEMP_ID)
                return;
            const newReportTemplate = {
                ...payload,
                id: TEMP_ID,
                name: payload.name + '-copy'
            };
            state.firstDrawer = newReportTemplate;
            state.results.unshift(newReportTemplate);
            state.map[TEMP_ID] = newReportTemplate;
        },
        discardChanges: (state, _ref9) => {
            let { payload } = _ref9;
            const { isFirstDrawer, id } = payload;
            if (isFirstDrawer) {
                state.firstDrawer = state.map[id];
            }
            else {
                state.secondDrawer = state.map[id];
            }
        },
        changeReportTemplate: (state, _ref10) => {
            let { payload } = _ref10;
            const { isFirstDrawer, key, value } = payload;
            if (isFirstDrawer) {
                state.firstDrawer = {
                    ...state.firstDrawer,
                    [key]: value
                };
            }
            else {
                state.secondDrawer = {
                    ...state.secondDrawer,
                    [key]: value
                };
            }
        }
    }
});
export default slice.reducer;
// Selectors
export const reportTemplatesLoading = state => state.reportTemplate.loading;
export const reportTemplatesSelector = state => state.reportTemplate.results;
export const reportTemplateMapSelector = state => state.reportTemplate.map;
export const firstReportTemplateSelector = state => state.reportTemplate.firstDrawer;
export const secondReportTemplateSelector = state => state.reportTemplate.secondDrawer;
// TODO: Replace this with a `selectedIds` property updated when firstDrawer or secondDrawer is updated
export const selectedIdsSelector = state => _.compact([state.reportTemplate.firstDrawer, state.reportTemplate.secondDrawer]).map(pr => pr.id);
// Actions
export const { fetchDataRequest, fetchDataSuccess, fetchDataFailure, createReportTemplate, deleteReportTemplateRequest, deleteReportTemplateFailure, deleteReportTemplateSuccess, saveReportTemplateRequest, saveReportTemplateSuccess, saveReportTemplateFailure, setFirstReportTemplate, setSecondReportTemplate, duplicateReportTemplate, discardChanges, changeReportTemplate, setFieldsSetting } = slice.actions;
export const fetchTemplate = createAppAsyncThunk('reportTemplate/fetchTemplate', async (_, _ref11) => {
    let { dispatch } = _ref11;
    try {
        dispatch(fetchDataRequest());
        const result = await api.get({
            endpoint: `/reporting/template-settings`
        });
        dispatch(fetchDataSuccess(result));
    }
    catch (e) {
        dispatch(fetchDataFailure());
        console.error(e);
    }
});
export const saveReportTemplate = createAppAsyncThunk('reportTemplate/saveReportTemplate', async (_ref12, _ref13) => {
    let { isFirstReportTemplate, reportTemplate } = _ref12;
    let { dispatch } = _ref13;
    try {
        dispatch(saveReportTemplateRequest());
        /**
         * Could be either create or update
         * Depending on which, we need to use different methods (POST or PATCH)
         */
        const { id, ...body } = reportTemplate;
        if (id === TEMP_ID) {
            // Create a new one
            await api.post({
                endpoint: `/reporting/template-settings`,
                data: body
            });
            dispatch(saveReportTemplateSuccess({
                isFirstReportTemplate
            }));
            dispatch(fetchTemplate());
        }
        else {
            await api.patch({
                endpoint: `/reporting/template-settings/${reportTemplate.id}`,
                data: body
            });
            dispatch(saveReportTemplateSuccess({
                isFirstReportTemplate
            }));
            dispatch(fetchTemplate());
        }
    }
    catch (error) {
        dispatch(saveReportTemplateFailure());
        console.error(error);
    }
});
export const deleteReportTemplate = createAppAsyncThunk('reportTemplate/deleteReportTemplate', async (reportTemplateId, _ref14) => {
    let { dispatch } = _ref14;
    try {
        dispatch(deleteReportTemplateRequest());
        /**
         * No need to send the request to the backend if the entity hasn't been saved
         */
        if (reportTemplateId !== TEMP_ID)
            await api.delete({
                endpoint: `/reporting/template-settings/${reportTemplateId}`
            });
        dispatch(deleteReportTemplateSuccess({
            reportTemplateId
        }));
    }
    catch (e) {
        dispatch(deleteReportTemplateFailure());
        console.error(e);
    }
});
/**
 * Travel time padding rule has distances array, each of which has fieldValues array.
 * The order of array are changed after matrix component, so we need to sort them before comparing.
 **/
const checkReportTemplateChanged = (reportTemplate1, reportTemplate2) => {
    return checkSavedDisabled(reportTemplate1, reportTemplate2);
};
export const checkSaveDisabled = isFirstDrawer => (_, getState) => {
    const { reportTemplate } = getState();
    const { firstDrawer, secondDrawer, map: reportTemplateMap } = reportTemplate;
    if (isFirstDrawer && firstDrawer && reportTemplateMap[firstDrawer.id]) {
        return checkReportTemplateChanged(firstDrawer, reportTemplateMap[firstDrawer.id]);
    }
    if (!isFirstDrawer && secondDrawer && reportTemplateMap[secondDrawer.id]) {
        return checkReportTemplateChanged(secondDrawer, reportTemplateMap[secondDrawer.id]);
    }
    return true;
};
export const checkFirstReportTemplateExist = () => (_, getState) => !!getState().reportTemplate.firstDrawer;
