import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { createAppAsyncThunk } from 'slices/utils';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    totalPages: 0,
    totalResults: 0
};
// Slice
const slice = createSlice({
    name: 'reportingLogs',
    initialState,
    reducers: {
        fetchLogsRequest: state => {
            isLoadingRequest(state);
        },
        fetchLogsSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.totalResults = payload.totalResults;
            state.totalPages = payload.totalPages;
            state.results = payload.results;
            state.page = payload.page;
            finishedLoadingSuccess(state);
        },
        fetchLogsFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const reportingLogsSelector = state => {
    return state.reportingLogs.results;
};
export const totalResultsSelector = state => {
    return state.reportingLogs.totalResults;
};
// Actions
export const { fetchLogsRequest, fetchLogsSuccess, fetchLogsFailure } = slice.actions;
export const fetchReportingLogs = createAppAsyncThunk('reportingLogs/fetchLogs', async (_ref2, _ref3) => {
    let { templateId, page = 1, limit = 10, status } = _ref2;
    let { dispatch } = _ref3;
    try {
        const query = new URLSearchParams(_.omitBy({
            page,
            limit,
            status,
            templateId
        }, _.isNil));
        dispatch(fetchLogsRequest());
        const result = await api.get({
            endpoint: `/reporting/template-settings/logs?${query.toString()}`
        });
        dispatch(fetchLogsSuccess(result));
    }
    catch (e) {
        dispatch(fetchLogsFailure());
        console.error(e);
    }
});
