import { useEffect } from 'react';
const useReloadWarning = show => {
    useEffect(() => {
        if (!show)
            return;
        const handleTabClose = registerReloadWarning();
        return () => {
            unregisterReloadWarning(handleTabClose);
        };
    }, [show]);
};
export default useReloadWarning;
export const registerReloadWarning = () => {
    const handleTabClose = event => {
        event.preventDefault();
        event.returnValue = true;
        return true;
    };
    window.addEventListener('beforeunload', handleTabClose);
    return handleTabClose;
};
export const unregisterReloadWarning = handleTabClose => {
    window.removeEventListener('beforeunload', handleTabClose);
};
