/**
 * @function finishedLoadingSuccess
 * @description standardized way of indicating API call has finished loading successfully in redux state
 * @param {IDefaultReducerState} state
 * @returns {void}
 */
export const finishedLoadingSuccess = state => {
    state.loading = false;
    state.hasErrors = false;
};
