import { createSlice } from '@reduxjs/toolkit';
import { createFieldMapping, createObjectTypeMapping, EFieldMappingModes, EObjectTypeMappingStatuses } from '@timeedit/types';
import { mappingApp } from 'constants/Mapping.constants';
import { current } from 'immer';
import _ from 'lodash';
import { clearAllocateCache } from 'slices/integration.slice';
import { createAppAsyncThunk } from 'slices/utils';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
const createEmptyAppConfig = () => ({
    loading: false,
    hasErrors: false,
    objectTypesMapping: [],
    objectTypeFields: {},
    rawObjectTypes: [],
    previewObjectType: {
        related: [],
        optionalRelated: [],
        fields: {}
    }
});
export const initialState = {
    loading: false,
    teExam: createEmptyAppConfig(),
    teExamV3: createEmptyAppConfig(),
    teReporting: createEmptyAppConfig(),
    tePreferences: createEmptyAppConfig(),
    hasErrors: false,
    organizationId: '',
    username: '',
    password: '',
    certificateKey: ''
};
// Slice
const slice = createSlice({
    name: 'appService',
    initialState,
    reducers: {
        // ************* Exam setting **************
        fetchExamSettingsRequest: state => {
            isLoadingRequest(state.teExam);
        },
        fetchExamSettingsSuccess: (state, _ref) => {
            let { payload } = _ref;
            if (payload.mapping.objectTypes) {
                state.teExam.objectTypesMapping = payload.mapping.objectTypes.map(obj => {
                    const fields = obj.fields.map(field => {
                        const dataConvert = createFieldMapping({
                            ...field,
                            isActive: true
                        });
                        return {
                            ...dataConvert,
                            isActive: true
                        };
                    });
                    const objectTypeMappingTypeConvert = createObjectTypeMapping({
                        ...obj,
                        fields
                    });
                    return {
                        ...objectTypeMappingTypeConvert,
                        fieldMappingMode: EFieldMappingModes.MAPPED
                    };
                });
            }
            if (payload?.rawObjectTypes) {
                state.teExam.rawObjectTypes = payload.rawObjectTypes;
            }
            finishedLoadingSuccess(state.teExam);
        },
        fetchExamSettingsFailure: state => {
            finishedLoadingFailure(state.teExam);
        },
        fetchExamV3MappingRequest: state => {
            isLoadingRequest(state.teExamV3);
        },
        fetchExamV3MappingSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            const currentDataObjectType = current(state.teExamV3.objectTypeFields) || {};
            state.teExamV3.objectTypesMapping = payload.mapping.objectTypes.map(obj => {
                const fields = obj.fields.map(field => {
                    const dataConvert = createFieldMapping({
                        ...field,
                        isActive: true
                    });
                    return {
                        ...dataConvert,
                        isActive: true
                    };
                });
                if (_.get(currentDataObjectType, obj.objectTypeExtId)) {
                    const listAllObjField = currentDataObjectType[obj.objectTypeExtId];
                    const listCurr = fields.map(i => i.fieldExtId);
                    listAllObjField.forEach(field => {
                        if (!listCurr.includes(field.extid)) {
                            fields.push({
                                appFieldName: field.extid,
                                appProperty: null,
                                allocationFieldFromObject: null,
                                fieldExtId: field.extid,
                                fieldLabel: field.extid,
                                // TODO use field name instead
                                isSearchable: false,
                                defaultSorting: false,
                                isActive: false
                            });
                        }
                    });
                }
                const objectTypeMappingTypeConvert = createObjectTypeMapping({
                    ...obj,
                    fields
                });
                return {
                    ...objectTypeMappingTypeConvert,
                    // fixed for pref
                    fieldMappingMode: EFieldMappingModes.FREE_MAPPING
                };
            });
            finishedLoadingSuccess(state.teExamV3);
        },
        fetchExamV3MappingFailure: state => {
            finishedLoadingFailure(state.teExamV3);
        },
        //  ************* Reporting setting **************
        fetchReportSettingsRequest: state => {
            isLoadingRequest(state.teReporting);
        },
        fetchReportSettingsSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            const currentDataObjectType = current(state.teReporting.objectTypeFields) || {};
            const dataConvert = payload.mapping.objectTypes.map(obj => {
                const fields = obj.fields.map(field => {
                    const dataConvert = createFieldMapping({
                        ...field,
                        isActive: true
                    });
                    return {
                        ...dataConvert,
                        isActive: true
                    };
                });
                if (_.get(currentDataObjectType, obj.objectTypeExtId)) {
                    const listAllObjField = currentDataObjectType[obj.objectTypeExtId];
                    const listCurr = fields.map(i => i.fieldExtId);
                    listAllObjField.forEach(field => {
                        if (!listCurr.includes(field.extid)) {
                            fields.push({
                                appFieldName: field.extid,
                                appProperty: null,
                                allocationFieldFromObject: null,
                                fieldExtId: field.extid,
                                fieldId: field.id,
                                fieldLabel: field.extid,
                                isSearchable: false,
                                defaultSorting: false,
                                isActive: false
                            });
                        }
                    });
                }
                const objectTypeMappingTypeConvert = createObjectTypeMapping({
                    ...obj,
                    fields
                });
                return {
                    ...objectTypeMappingTypeConvert,
                    // fixed for pref
                    fieldMappingMode: EFieldMappingModes.FREE_MAPPING
                };
            });
            if (payload?.rawObjectTypes) {
                state.teReporting.rawObjectTypes = payload.rawObjectTypes;
            }
            state.teReporting.objectTypesMapping = dataConvert;
            finishedLoadingSuccess(state.teReporting);
        },
        fetchReportSettingsFailure: state => {
            finishedLoadingFailure(state.teReporting);
        },
        //  ************* tePreferences setting **************
        fetchPreferencesSettingsRequest: state => {
            isLoadingRequest(state.tePreferences);
        },
        fetchPreferencesSettingsSuccess: (state, _ref4) => {
            let { payload } = _ref4;
            const currentDataObjectType = current(state.tePreferences.objectTypeFields) || {};
            state.tePreferences.objectTypesMapping = payload.mapping.objectTypes.map(obj => {
                const fields = obj.fields.map(field => {
                    const dataConvert = createFieldMapping({
                        ...field,
                        isActive: true
                    });
                    return {
                        ...dataConvert,
                        isActive: true
                    };
                });
                if (_.get(currentDataObjectType, obj.objectTypeExtId)) {
                    const listAllObjField = currentDataObjectType[obj.objectTypeExtId];
                    const listCurr = fields.map(i => i.fieldExtId);
                    listAllObjField.forEach(field => {
                        if (!listCurr.includes(field.extid)) {
                            fields.push({
                                appFieldName: field.extid,
                                appProperty: null,
                                allocationFieldFromObject: null,
                                fieldExtId: field.extid,
                                fieldId: field.id,
                                fieldLabel: field.extid,
                                // TODO use field name instead
                                isSearchable: false,
                                defaultSorting: false,
                                isActive: false
                            });
                        }
                    });
                }
                const objectTypeMappingTypeConvert = createObjectTypeMapping({
                    ...obj,
                    fields
                });
                return {
                    ...objectTypeMappingTypeConvert,
                    // fixed for pref
                    fieldMappingMode: EFieldMappingModes.FREE_MAPPING
                };
            });
            finishedLoadingSuccess(state.tePreferences);
        },
        fetchPreferencesSettingsFailure: state => {
            finishedLoadingFailure(state.tePreferences);
        },
        updateObjectTypesMapping: (state, _ref5) => {
            let { payload } = _ref5;
            const { appName, objectTypeIdx, fieldIdx, fieldLabel } = payload;
            state[appName].objectTypesMapping[objectTypeIdx].fields[fieldIdx].fieldLabel = fieldLabel;
            state[appName].objectTypesMapping[objectTypeIdx].hasChanges = true;
            if (state[appName].objectTypesMapping[objectTypeIdx].status === EObjectTypeMappingStatuses.NOT_MAPPED) {
                state[appName].objectTypesMapping[objectTypeIdx].status = EObjectTypeMappingStatuses.MAPPED;
            }
        },
        updateExtIdForFieldInObjectType: (state, _ref6) => {
            let { payload } = _ref6;
            const { appName, objectTypeIdx, fieldIdx, fieldExtId } = payload;
            state[appName].objectTypesMapping[objectTypeIdx].fields[fieldIdx].fieldExtId = fieldExtId;
            state[appName].objectTypesMapping[objectTypeIdx].hasChanges = true;
        },
        updateForObjectType: (state, _ref7) => {
            let { payload } = _ref7;
            const { appName, objectTypeIdx, ...update } = payload;
            Object.keys(update).forEach(key => {
                state[appName].objectTypesMapping[objectTypeIdx][key] = update[key];
            });
            state[appName].objectTypesMapping[objectTypeIdx].hasChanges = true;
            if (state[appName].objectTypesMapping[objectTypeIdx].status === EObjectTypeMappingStatuses.NOT_MAPPED) {
                state[appName].objectTypesMapping[objectTypeIdx].status = EObjectTypeMappingStatuses.MAPPED;
            }
        },
        addNewObjectType: (state, _ref8) => {
            let { payload } = _ref8;
            const { appName, newObj } = payload;
            state[appName].objectTypesMapping.unshift(newObj);
        },
        fetchObjectTypesFieldsSuccess: (state, _ref9) => {
            let { payload } = _ref9;
            const { appName, type, objectTypeFields } = payload;
            state[appName].objectTypeFields[type] = objectTypeFields;
            finishedLoadingSuccess(state[appName]);
        },
        fetchTestObjectTypesFieldsSuccess: (state, _ref10) => {
            let { payload } = _ref10;
            const { appName, objectTypeExtId, previewObjectType } = payload;
            state[appName].previewObjectType[objectTypeExtId] = previewObjectType;
            finishedLoadingSuccess(state[appName]);
        },
        fetchRawObjectFieldsSuccess: (state, _ref11) => {
            let { payload } = _ref11;
            const { data, appName } = payload;
            state[appName].rawObjectTypes = data;
        },
        updateForFieldInObjectType(state, _ref12) {
            let { payload } = _ref12;
            const { appName, objectTypeIdx, fieldIdx, ...update } = payload;
            Object.keys(update).forEach(key => {
                state[appName].objectTypesMapping[objectTypeIdx].fields[fieldIdx][key] = update[key];
            });
            state[appName].objectTypesMapping[objectTypeIdx].hasChanges = true;
            if (state[appName].objectTypesMapping[objectTypeIdx].status === EObjectTypeMappingStatuses.NOT_MAPPED) {
                state[appName].objectTypesMapping[objectTypeIdx].status = EObjectTypeMappingStatuses.MAPPED;
            }
        },
        updateObjectTypeFields(state, _ref13) {
            let { payload } = _ref13;
            const { appName, dataField, objectTypeExtId } = payload;
            const index = current(state[appName].objectTypesMapping).findIndex(f => f.objectTypeExtId === objectTypeExtId);
            const newData = [];
            current(state[appName].objectTypesMapping[index].fields).forEach(f => {
                if (f.isActive) {
                    newData.push(f);
                }
            });
            dataField?.forEach(field => {
                if (newData.find(f => f.fieldExtId === field.extid)) {
                    return;
                }
                newData.push({
                    appFieldName: field.extid,
                    appProperty: null,
                    allocationFieldFromObject: null,
                    fieldExtId: field.extid,
                    fieldId: field.id,
                    fieldLabel: field.extid,
                    // TODO use field name instead
                    defaultSorting: false,
                    isSearchable: false,
                    isActive: false
                });
            });
            state[appName].objectTypesMapping[index].fields = newData;
        },
        updateObjectExtIdForObjectType(state, _ref14) {
            let { payload } = _ref14;
            const { appName, objectTypeIdx, objectTypeExtId, status = '' } = payload;
            const objectTypeId = state[appName].rawObjectTypes.find(obj => obj.extid === objectTypeExtId)?.id;
            state[appName].objectTypesMapping[objectTypeIdx].hasChanges = true;
            if (status) {
                state[appName].objectTypesMapping[objectTypeIdx].status = status;
            }
            if (state[appName].objectTypesMapping[objectTypeIdx].status === EObjectTypeMappingStatuses.NOT_MAPPED) {
                state[appName].objectTypesMapping[objectTypeIdx].status = EObjectTypeMappingStatuses.MAPPED;
            }
            state[appName].objectTypesMapping[objectTypeIdx].objectTypeExtId = objectTypeExtId;
            state[appName].objectTypesMapping[objectTypeIdx].objectTypeId = objectTypeId;
        }
    }
});
export default slice.reducer;
// exam
export const appServiceExamLoading = state => state.appService.teExam.loading;
export const appServiceExamObjectTypes = state => state.appService.teExam.objectTypesMapping;
export const appServiceExamV3ObjectTypes = state => state.appService.teExamV3.objectTypesMapping;
// report
export const appServiceReportLoading = state => state.appService.teReporting.loading;
export const appServiceReportObjectTypes = state => state.appService.teReporting.objectTypesMapping;
// preferences
export const appServicePreferencesObjectTypes = state => state.appService.tePreferences.objectTypesMapping;
export const isLoadingObjectType = appName => state => state.appService[appName].loading;
export const appServiceRawObjectFields = (appName, type) => state => state.appService[appName].objectTypeFields[type] ?? [];
export const appServiceObjectTypes = appName => state => state.appService[appName].objectTypesMapping ?? [];
export const appServiceRawObjectTypes = appName => state => state.appService[appName].rawObjectTypes ?? [];
// TODO: Need to figure out the proper type, since the usage seems incorrect
export const appPreviewObjectTypes = (appName, type) => state => {
    const dataPreview = _.get(state, `appService.${appName}.previewObjectType`, {}) || {};
    return dataPreview[type] || [];
};
// Actions
export const { 
// exam
fetchExamSettingsRequest, fetchExamSettingsSuccess, fetchExamSettingsFailure, 
// exam v3
fetchExamV3MappingRequest, fetchExamV3MappingSuccess, fetchExamV3MappingFailure, 
// report
fetchReportSettingsRequest, fetchReportSettingsSuccess, fetchReportSettingsFailure, 
// preferences
fetchPreferencesSettingsRequest, fetchPreferencesSettingsSuccess, fetchPreferencesSettingsFailure, 
//
updateObjectTypesMapping, updateExtIdForFieldInObjectType, updateForObjectType, fetchRawObjectFieldsSuccess, 
//
fetchObjectTypesFieldsSuccess, fetchTestObjectTypesFieldsSuccess, updateForFieldInObjectType, 
//
updateObjectTypeFields, updateObjectExtIdForObjectType } = slice.actions;
// *************** Exam *******************
export const fetchExamMappingSettings = createAppAsyncThunk('appService/fetchExamMappingSettings', async (_, _ref15) => {
    let { dispatch } = _ref15;
    try {
        dispatch(fetchExamSettingsRequest());
        const [result, objectsType] = await Promise.all([api.get({
                endpoint: `/apps/TE_EXAM/mapping`
            }), api.get({
                endpoint: `/apps/TE_EXAM/integration/objects/types`
            })]);
        dispatch(fetchExamSettingsSuccess({
            mapping: result,
            rawObjectTypes: objectsType.data
        }));
    }
    catch (e) {
        dispatch(fetchExamSettingsFailure());
    }
});
// *************** Exam V3 *******************
export const fetchExamV3Mapping = createAppAsyncThunk('appService/fetchExamV3Mapping', async (_, _ref16) => {
    let { dispatch } = _ref16;
    try {
        dispatch(fetchExamV3MappingRequest());
        const result = await api.get({
            endpoint: `/apps/TE_EXAM_V3/mapping`
        });
        dispatch(fetchExamV3MappingSuccess({
            mapping: result
        }));
    }
    catch (e) {
        dispatch(fetchExamV3MappingFailure());
        console.error(e);
    }
});
// *************** Report *******************
export const fetchReportMappingSettings = createAppAsyncThunk('appService/fetchReportMappingSettings', async (_, _ref17) => {
    let { dispatch } = _ref17;
    try {
        dispatch(fetchReportSettingsRequest());
        const [result, objectsType] = await Promise.all([api.get({
                endpoint: `/apps/TE_REPORTING/mapping`
            }), api.get({
                endpoint: `/apps/TE_REPORTING/integration/objects/types`
            })]);
        dispatch(fetchReportSettingsSuccess({
            mapping: result,
            rawObjectTypes: objectsType.data
        }));
    }
    catch (e) {
        dispatch(fetchReportSettingsFailure());
        console.error(e);
    }
});
// *************** Preferences *******************
export const fetchPreferencesMappingSettings = createAppAsyncThunk('appService/fetchPreferencesMappingSettings', async (_, _ref18) => {
    let { dispatch } = _ref18;
    try {
        dispatch(fetchPreferencesSettingsRequest());
        const result = await api.get({
            endpoint: `/apps/TE_PREFERENCES/mapping`
        });
        dispatch(fetchPreferencesSettingsSuccess({
            mapping: result
        }));
    }
    catch (e) {
        dispatch(fetchPreferencesSettingsFailure());
        console.error(e);
    }
});
export const fetchRawObjectFields = createAppAsyncThunk('appService/fetchRawObjectFields', async (appName, _ref19) => {
    let { dispatch } = _ref19;
    try {
        dispatch(fetchPreferencesSettingsRequest());
        const result = await api.get({
            endpoint: `/types`
        });
        dispatch(fetchRawObjectFieldsSuccess({
            appName,
            data: result.results.map(type => ({
                extid: type.extId,
                name: type.name,
                id: type.id
            }))
        }));
    }
    catch (e) {
        dispatch(fetchPreferencesSettingsFailure());
        console.error('errors fetchRawObjectFields', e);
    }
});
const mappedDispatchStatements = {
    tePreferences: {
        request: fetchPreferencesSettingsRequest,
        success: fetchPreferencesSettingsSuccess,
        failure: fetchPreferencesSettingsFailure
    },
    teExamV3: {
        request: fetchExamV3MappingRequest,
        success: fetchExamV3MappingSuccess,
        failure: fetchExamV3MappingFailure
    }
};
export const fetchObjectTypesFields = createAppAsyncThunk('appService/fetchObjectTypesFields', async (_ref20, _ref21) => {
    let { appName, type } = _ref20;
    let { dispatch, getState } = _ref21;
    if (!['tePreferences', 'teExamV3'].includes(appName)) {
        try {
            dispatch(fetchPreferencesSettingsRequest());
            const result = await api.get({
                endpoint: `/apps/${mappingApp[appName]}/integration/objects/types/${type}/fields`
            });
            dispatch(fetchObjectTypesFieldsSuccess({
                appName,
                type,
                objectTypeFields: result.data.map(extid => ({
                    extid
                }))
            }));
        }
        catch (err) {
            dispatch(fetchPreferencesSettingsFailure());
            console.error('errors fetchObjectTypesFields', err);
        }
    }
    else {
        try {
            const store = getState();
            const rawObjectTypes = _.get(store, `appService.${appName}.rawObjectTypes`);
            const typeId = rawObjectTypes.find(i => i.extid === type)?.id;
            if (mappedDispatchStatements?.[appName]) {
                dispatch(mappedDispatchStatements[appName].request());
            }
            const result = await api.post({
                endpoint: '/fields/find',
                suppressNotification: true,
                data: {
                    typeId
                }
            });
            dispatch(fetchObjectTypesFieldsSuccess({
                appName,
                type: type,
                objectTypeFields: result.results.map(field => ({
                    extid: field.extId,
                    id: field.id
                }))
            }));
        }
        catch (err) {
            if (mappedDispatchStatements?.[appName]) {
                dispatch(mappedDispatchStatements[appName].failure());
            }
            console.error('errors fetchObjectTypesFields', err);
        }
    }
});
export const fetchTestObjectTypesFields = createAppAsyncThunk('appService/fetchTestObjectTypesFields', async (_ref22, _ref23) => {
    let { appName, type, objectTypeExtId } = _ref22;
    let { dispatch, getState } = _ref23;
    if (!['tePreferences', 'teExamV3'].includes(appName)) {
        try {
            dispatch(fetchPreferencesSettingsRequest());
            const result = await api.get({
                endpoint: `/apps/${mappingApp[appName]}/integration/objects/types/${type}?limit=10`
            });
            dispatch(fetchTestObjectTypesFieldsSuccess({
                appName,
                type,
                objectTypeExtId,
                previewObjectType: result.data
            }));
        }
        catch (err) {
            dispatch(fetchPreferencesSettingsFailure());
            console.error('errors fetchTestObjectTypesFields', err);
        }
    }
    else {
        try {
            const store = getState();
            const rawObjectTypes = store.appService[appName].rawObjectTypes;
            const objectType = store.appService[appName].objectTypesMapping.find(item => item.objectTypeExtId === objectTypeExtId);
            const activeFields = objectType?.fields.filter(field => field.isActive);
            const typeId = rawObjectTypes.find(i => i.extid === objectTypeExtId)?.id;
            if (mappedDispatchStatements?.[appName]) {
                dispatch(mappedDispatchStatements[appName].request());
            }
            const fieldsResult = await api.post({
                endpoint: '/fields/find',
                suppressNotification: true,
                data: {
                    typeId
                }
            });
            const objectsResult = await api.post({
                endpoint: '/objects/find',
                suppressNotification: true,
                data: {
                    typeId,
                    limit: 10
                }
            });
            const previewObjectType = objectsResult.results.map(item => {
                return {
                    related: item.related,
                    optionalRelated: item.optionalRelated,
                    fields: item.fields?.reduce((acc, cur) => {
                        const fieldExtId = fieldsResult.results.find(field => cur.fieldId === field.id)?.extId;
                        if (fieldExtId !== undefined && fieldExtId !== null && activeFields?.find(field => field.fieldExtId === fieldExtId)) {
                            acc[fieldExtId] = cur.values[0];
                        }
                        return acc;
                    }, {})
                };
            });
            dispatch(fetchTestObjectTypesFieldsSuccess({
                appName,
                type,
                objectTypeExtId,
                previewObjectType
            }));
        }
        catch (err) {
            if (mappedDispatchStatements?.[appName]) {
                dispatch(mappedDispatchStatements[appName].failure());
            }
            console.error('errors fetchTestObjectTypesFields', err);
        }
    }
});
export const saveMappingFopApp = createAppAsyncThunk('appService/saveMappingFopApp', async (_ref24, _ref25) => {
    let { appName, objectTypes } = _ref24;
    let { dispatch, getState } = _ref25;
    try {
        const store = getState();
        let dataBody;
        if (objectTypes) {
            dataBody = objectTypes;
        }
        else {
            dataBody = store.appService[appName].objectTypesMapping;
        }
        const dataSave = _.cloneDeep(dataBody).filter(i => {
            // remove no need
            if (!i.applicationObjectTypeGroup) {
                delete i.applicationObjectTypeGroup;
            }
            delete i.fieldMappingMode;
            delete i.hasChanges;
            if (!i.objectTypeId) {
                i.objectTypeId = store.appService[appName].rawObjectTypes.find(obj => obj.extid === i.objectTypeExtId)?.id;
            }
            // in fields
            i.fields = i.fields.filter(f => f.isActive);
            i.fields.forEach(f => {
                if (!f.appProperty)
                    f.appProperty = null;
                if (!f.allocationFieldFromObject)
                    f.allocationFieldFromObject = null;
                if (!f.isSearchable)
                    f.isSearchable = false;
                if (!f.defaultSorting)
                    f.defaultSorting = false;
                if (!f.allocationFieldFromObject)
                    f.allocationFieldFromObject = null;
                if (!f.fieldId) {
                    const objectTypeFields = store?.appService?.[appName]?.objectTypeFields?.[i.objectTypeExtId];
                    f.fieldId = objectTypeFields?.find(field => {
                        return field.extid === f.fieldExtId;
                    })?.id;
                }
                // TODO: We should either remove this or add `fieldMappingMode` to the `IFieldMapping` type
                if ('fieldMappingMode' in f) {
                    delete f.fieldMappingMode;
                }
                delete f.isActive;
            });
            return i;
        });
        const result = await api.patch({
            endpoint: `/apps/${mappingApp[appName]}/mapping`,
            data: {
                objectTypes: dataSave
            }
        });
        if (mappedDispatchStatements?.[appName]) {
            dispatch(mappedDispatchStatements[appName].success({
                mapping: result
            }));
        }
        else if (appName === 'teExam') {
            dispatch(fetchExamSettingsSuccess({
                mapping: result
            }));
        }
        else if (appName === 'teReporting') {
            dispatch(fetchReportSettingsSuccess({
                mapping: result
            }));
        }
    }
    catch (err) { }
});
export const testMappingForObjectType = createAppAsyncThunk('appService/testMappingForObjectType', async (_ref26, _ref27) => {
    let { appName, objectTypeIdx, objectType } = _ref26;
    let { dispatch } = _ref27;
    try {
        await dispatch(saveMappingFopApp({
            appName
        }));
        const result = await api.get({
            endpoint: `/apps/${mappingApp[appName]}/integration/objects/types/${objectType.applicationObjectTypeName}?limit=10`
        });
        dispatch(updateForObjectType({
            appName,
            objectTypeIdx,
            status: result?.data ? EObjectTypeMappingStatuses.TESTED : EObjectTypeMappingStatuses.FAILED
        }));
        await dispatch(saveMappingFopApp({
            appName
        }));
        dispatch(fetchTestObjectTypesFieldsSuccess({
            appName,
            type: objectType.applicationObjectTypeName,
            objectTypeExtId: objectType.objectTypeExtId,
            previewObjectType: result.data
        }));
    }
    catch (err) {
        dispatch(updateForObjectType({
            appName,
            objectTypeIdx,
            status: EObjectTypeMappingStatuses.FAILED
        }));
        dispatch(saveMappingFopApp({
            appName
        }));
    }
});
export const deleteMapping = createAppAsyncThunk('appService/deleteMapping', async (_ref28, _ref29) => {
    let { appName, objectTypes, objectTypeIdx } = _ref28;
    let { dispatch } = _ref29;
    try {
        const dataSave = _.cloneDeep(objectTypes);
        dataSave.splice(objectTypeIdx, 1);
        const dataConvert = dataSave.filter(i => {
            // remove no need
            if (!i.applicationObjectTypeGroup)
                delete i.applicationObjectTypeGroup;
            delete i.fieldMappingMode;
            delete i.hasChanges;
            // in fields
            i.fields = i.fields.filter(f => f.isActive);
            i.fields.forEach(f => {
                if (!f.appProperty)
                    f.appProperty = null;
                if (!f.allocationFieldFromObject)
                    f.allocationFieldFromObject = null;
                if (!f.isSearchable)
                    f.isSearchable = false;
                if (!f.defaultSorting)
                    f.defaultSorting = false;
                if (!f.allocationFieldFromObject)
                    f.allocationFieldFromObject = null;
                // TODO: We should either remove this or add `fieldMappingMode` to the `IFieldMapping` type
                if ('fieldMappingMode' in f) {
                    delete f.fieldMappingMode;
                }
                delete f.isActive;
            });
            if (!i.objectTypeExtId) {
                // handle empty case
                i.objectTypeExtId = '_';
            }
            return i;
        });
        const result = await api.patch({
            endpoint: `/apps/${mappingApp[appName]}/mapping`,
            data: {
                objectTypes: dataConvert
            }
        });
        if (mappedDispatchStatements?.[appName]) {
            dispatch(mappedDispatchStatements[appName].success({
                mapping: result
            }));
        }
        else if (appName === 'teExam') {
            dispatch(fetchExamSettingsSuccess({
                mapping: result
            }));
        }
        else if (appName === 'teReporting') {
            dispatch(fetchReportSettingsSuccess({
                mapping: result
            }));
        }
    }
    catch (err) { }
});
export const appServiceAddNewObjectType = createAppAsyncThunk('appService/appServiceAddNewObjectType', async (_ref30, _ref31) => {
    let { appName, objectType, objectTypes } = _ref30;
    let { dispatch } = _ref31;
    try {
        const newObjectTypes = _.cloneDeep([{
                fields: [],
                status: 'NOT_MAPPED',
                ...objectType
            }, ...objectTypes]);
        dispatch(saveMappingFopApp({
            appName,
            objectTypes: newObjectTypes
        }));
        if (appName === 'tePreferences') {
            dispatch(clearAllocateCache());
        }
    }
    catch (err) { }
});
