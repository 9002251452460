import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'slices/utils';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
const createEmptySupportInfo = () => ({
    generalDescription: '',
    generalName: '',
    generalEmail: '',
    generalPhone: '',
    generalUrl: '',
    generalUrlTitle: '',
    generalManualUrl: '',
    generalManualUrlTitle: '',
    technicalDescription: '',
    technicalName: '',
    technicalEmail: '',
    technicalPhone: '',
    technicalUrl: '',
    technicalUrlTitle: '',
    technicalManualUrl: '',
    technicalManualUrlTitle: ''
});
export const initialState = {
    loading: false,
    hasErrors: false,
    supportInfo: createEmptySupportInfo(),
    updatedSupportInfo: createEmptySupportInfo()
};
// Slice
const slice = createSlice({
    name: 'supportInfo',
    initialState,
    reducers: {
        fetchSupportInfoRequest: state => {
            isLoadingRequest(state);
        },
        fetchSupportInfoSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.supportInfo = payload;
            state.updatedSupportInfo = payload;
            finishedLoadingSuccess(state);
        },
        fetchSupportInfoFailure: state => {
            finishedLoadingFailure(state);
        },
        setsUpdatedSupportInfo: (state, _ref2) => {
            let { payload } = _ref2;
            state.updatedSupportInfo = payload;
        },
        updateSupportInfoRequest: state => {
            isLoadingRequest(state);
        },
        updateSupportInfoSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            state.supportInfo = payload;
            state.updatedSupportInfo = payload;
            finishedLoadingSuccess(state);
        },
        updateSupportInfoFailure: state => {
            finishedLoadingFailure(state);
        },
        discardChange: state => {
            state.updatedSupportInfo = state.supportInfo;
        }
    }
});
export default slice.reducer;
// Selectors
export const supportInfoLoading = state => state.supportInfo.loading;
export const updatedSupportInfoSelector = state => state.supportInfo.updatedSupportInfo;
// Actions
export const { fetchSupportInfoRequest, fetchSupportInfoSuccess, fetchSupportInfoFailure, setsUpdatedSupportInfo, updateSupportInfoRequest, updateSupportInfoSuccess, updateSupportInfoFailure, discardChange } = slice.actions;
export const fetchSupportInfo = createAppAsyncThunk('supportInfo/fetchSupportInfo', async (_, _ref4) => {
    let { dispatch } = _ref4;
    try {
        dispatch(fetchSupportInfoRequest());
        const supportInfo = await api.get({
            endpoint: `/support-info`
        });
        dispatch(fetchSupportInfoSuccess(supportInfo));
    }
    catch (e) {
        dispatch(fetchSupportInfoFailure());
        return console.error(e);
    }
});
export const updateSupportInfo = createAppAsyncThunk('supportInfo/updateSupportInfo', async (supportInfo, _ref5) => {
    let { dispatch } = _ref5;
    try {
        dispatch(updateSupportInfoRequest());
        const { ...supportInfoBody } = supportInfo;
        const response = await api.patch({
            endpoint: `/support-info`,
            data: {
                ...supportInfoBody
            }
        });
        dispatch(updateSupportInfoSuccess(response));
    }
    catch (e) {
        dispatch(updateSupportInfoFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
