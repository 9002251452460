import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import _ from 'lodash';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { createAppAsyncThunk } from 'slices/utils';
const initialState = {
    loading: false,
    hasErrors: false,
    firstFieldRelation: null,
    secondFieldRelation: null,
    allFieldRelations: [],
    fieldRelationMapping: {}
};
// Slice
const slice = createSlice({
    name: 'fieldRelations',
    initialState,
    reducers: {
        fetchFieldRelationsRequest: state => {
            isLoadingRequest(state);
        },
        fetchFieldRelationsSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.allFieldRelations = payload.results;
            state.fieldRelationMapping = _.keyBy(payload.results, 'id');
            finishedLoadingSuccess(state);
        },
        fetchFieldRelationsFailure: state => {
            finishedLoadingFailure(state);
        },
        createFieldRelationRequest: state => {
            isLoadingRequest(state);
        },
        createFieldRelationSuccess: state => {
            state.firstFieldRelation = undefined;
            finishedLoadingSuccess(state);
        },
        createUnsavedFieldRelationSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            if (state.firstFieldRelation?.id === 0)
                return;
            state.fieldRelationMapping[payload.id] = payload;
            state.allFieldRelations.unshift(payload);
            state.firstFieldRelation = payload;
        },
        createFieldRelationFailure: state => {
            finishedLoadingFailure(state);
        },
        setFirstFieldRelation: (state, _ref3) => {
            let { payload } = _ref3;
            state.firstFieldRelation = payload;
            // If closed field relation, remove new reservation from list
            if (!payload) {
                state.allFieldRelations = state.allFieldRelations.filter(fr => fr.id !== 0);
            }
        },
        setSecondFieldRelation: (state, _ref4) => {
            let { payload } = _ref4;
            state.secondFieldRelation = payload;
        },
        deleteFieldRelationRequest: state => {
            isLoadingRequest(state);
        },
        deleteFieldRelationSuccess: (state, _ref5) => {
            let { payload } = _ref5;
            state.allFieldRelations = state.allFieldRelations.filter(fieldRelation => fieldRelation.id !== payload.fieldRelationId);
            if (state.firstFieldRelation?.id === payload.fieldRelationId) {
                state.firstFieldRelation = undefined;
            }
            else {
                state.secondFieldRelation = undefined;
            }
            finishedLoadingSuccess(state);
        },
        deleteFieldRelationFailure: state => {
            finishedLoadingFailure(state);
        },
        updateFieldRelationRequest: state => {
            isLoadingRequest(state);
        },
        updateFieldRelationSuccess: (state, _ref6) => {
            let { payload: { fieldRelation } } = _ref6;
            const index = state.allFieldRelations.findIndex(fr => fr.id === fieldRelation.id);
            state.allFieldRelations[index] = fieldRelation;
            state.fieldRelationMapping[fieldRelation.id] = fieldRelation;
            if (state.firstFieldRelation?.id === fieldRelation.id) {
                state.firstFieldRelation = undefined;
            }
            else {
                state.secondFieldRelation = undefined;
            }
            finishedLoadingSuccess(state);
        },
        updateFieldRelationFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const fieldRelationsLoading = state => state.fieldRelations.loading;
export const fieldRelationsSelector = state => state.fieldRelations.allFieldRelations;
export const firstFieldRelationSelector = state => {
    return state.fieldRelations.firstFieldRelation;
};
export const secondFieldRelationSelector = state => {
    return state.fieldRelations.secondFieldRelation;
};
export const fieldRelationMappingSelector = state => state.fieldRelations.fieldRelationMapping;
export const selectedFieldRelationIdsSelector = state => _.compact([state.fieldRelations.firstFieldRelation, state.fieldRelations.secondFieldRelation]).map(fr => fr.id);
// Actions
export const { fetchFieldRelationsRequest, fetchFieldRelationsSuccess, fetchFieldRelationsFailure, createFieldRelationRequest, createFieldRelationSuccess, createUnsavedFieldRelationSuccess, createFieldRelationFailure, setFirstFieldRelation, setSecondFieldRelation, deleteFieldRelationRequest, deleteFieldRelationSuccess, deleteFieldRelationFailure, updateFieldRelationRequest, updateFieldRelationSuccess, updateFieldRelationFailure } = slice.actions;
export const fetchFieldRelations = createAppAsyncThunk('fieldRelations/fetchFieldRelations', async (_, _ref7) => {
    let { dispatch } = _ref7;
    try {
        dispatch(fetchFieldRelationsRequest());
        const fieldRelations = await api.get({
            endpoint: `/field-relations`
        });
        dispatch(fetchFieldRelationsSuccess(fieldRelations));
    }
    catch (e) {
        dispatch(fetchFieldRelationsFailure());
        return console.error(e);
    }
});
export const createFieldRelation = createAppAsyncThunk('fieldRelations/createFieldRelation', async (fieldRelation, _ref8) => {
    let { dispatch } = _ref8;
    try {
        dispatch(createFieldRelationRequest());
        const { id, history, ...fieldRelationBody } = fieldRelation;
        await api.post({
            endpoint: `/field-relations`,
            data: fieldRelationBody
        });
        dispatch(createFieldRelationSuccess());
        dispatch(fetchFieldRelations());
    }
    catch (e) {
        dispatch(createFieldRelationFailure(_.get(e, 'e.response.data.message')));
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const createUnsavedFieldRelation = () => async (dispatch) => {
    const response = {
        id: 0,
        name: '',
        description: 'New field relation',
        documentation: '',
        history: [],
        reservationTemplates: [],
        sourceField: null
    };
    dispatch(createUnsavedFieldRelationSuccess(response));
};
export const copyFieldRelation = createAppAsyncThunk('fieldRelations/copyFieldRelation', async (fieldRelation, _ref9) => {
    let { dispatch } = _ref9;
    const response = {
        ...fieldRelation,
        id: 0,
        description: '',
        documentation: '',
        reservationTemplates: [],
        name: fieldRelation.name + '-copy'
    };
    dispatch(createUnsavedFieldRelationSuccess(response));
});
export const deleteFieldRelation = createAppAsyncThunk('fieldRelations/deleteFieldRelation', async (fieldRelationId, _ref10) => {
    let { dispatch } = _ref10;
    try {
        dispatch(deleteFieldRelationRequest());
        await api.delete({
            endpoint: `/field-relations/${fieldRelationId}`
        });
        dispatch(deleteFieldRelationSuccess({
            fieldRelationId
        }));
    }
    catch (e) {
        dispatch(deleteFieldRelationFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const updateFieldRelation = createAppAsyncThunk('fieldRelations/updateFieldRelation', async (fieldRelation, _ref11) => {
    let { dispatch } = _ref11;
    try {
        dispatch(updateFieldRelationRequest());
        const { id, history, ...fieldRelationBody } = fieldRelation;
        const response = await api.patch({
            endpoint: `/field-relations/${fieldRelation.id}`,
            data: {
                ...fieldRelationBody
            }
        });
        dispatch(updateFieldRelationSuccess({
            fieldRelation: response
        }));
    }
    catch (e) {
        dispatch(updateFieldRelationFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const checkFieldRelationSaveDisabled = isFirstDrawer => (_, getState) => {
    const { fieldRelations } = getState();
    const { firstFieldRelation, secondFieldRelation, fieldRelationMapping } = fieldRelations;
    if (isFirstDrawer && firstFieldRelation) {
        return checkSavedDisabled(firstFieldRelation, fieldRelationMapping[firstFieldRelation.id]);
    }
    if (!isFirstDrawer && secondFieldRelation) {
        return checkSavedDisabled(secondFieldRelation, fieldRelationMapping[secondFieldRelation.id]);
    }
    return true;
};
export const checkIfFirstFieldRelationExists = () => (_, getState) => {
    const { fieldRelations } = getState();
    return !!fieldRelations.firstFieldRelation;
};
