import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
// TYPES
// ACTIONS
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from '../utils/sliceHelpers';
export const initialState = {
    loading: false,
    hasErrors: false,
    allSystemActions: [],
    systemInfo: ''
};
// Slice
const slice = createSlice({
    name: 'systemActions',
    initialState,
    reducers: {
        fetchSystemActionsRequest: state => {
            isLoadingRequest(state);
        },
        fetchSystemActionsSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.allSystemActions = payload.results;
            finishedLoadingSuccess(state);
        },
        fetchSystemActionSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            state.systemInfo = payload;
            finishedLoadingSuccess(state);
        },
        fetchSystemActionsFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const systemActionsLoading = state => state.systemActions.loading;
export const systemActionsSelector = state => state.systemActions.allSystemActions;
export const systemInfoSelector = state => state.systemActions.systemInfo;
// Actions
export const { fetchSystemActionsRequest, fetchSystemActionsSuccess, fetchSystemActionsFailure, fetchSystemActionSuccess } = slice.actions;
export const fetchSystemActions = () => async (dispatch) => {
    try {
        dispatch(fetchSystemActionsRequest());
        const systemActions = await api.get({
            endpoint: `/system-actions`
        });
        dispatch(fetchSystemActionsSuccess(systemActions));
    }
    catch (e) {
        dispatch(fetchSystemActionsFailure());
        return console.error(e);
    }
};
export const fetchSystemActionInfo = (actionId, valueQuery) => async (dispatch) => {
    try {
        dispatch(fetchSystemActionsRequest());
        let url = `/system-actions/${actionId}`;
        if (valueQuery !== undefined) {
            url = `/system-actions/${actionId}?value=${valueQuery}`;
        }
        const { result } = await api.get({
            endpoint: url
        });
        dispatch(fetchSystemActionSuccess(result));
    }
    catch (e) {
        dispatch(fetchSystemActionsFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
};
