export const EXAM_CONFIGURATION_CONFIGS = [{
        label: 'Exam title',
        config: 'examTitle',
        className: 'configure-field-content'
    }, {
        label: 'Student',
        config: 'student',
        className: 'student-content'
    }, {
        label: 'External ref',
        config: 'externalRef',
        className: 'custom-external-ref-row'
    }, {
        label: 'Student info',
        config: 'studentInfo',
        className: 'custom-student-info-row'
    }, {
        label: 'Supervisor info',
        config: 'supervisorInfo',
        className: 'custom-supervisor-info-row'
    }, {
        label: 'Admin info',
        config: 'adminInfo',
        className: 'custom-admin-info-row'
    }];
