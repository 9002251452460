import { EAppId } from '@timeedit/types';
export const TEMPLATE_TYPE = {
    GENERAL: 'GENERAL',
    SELECT_MODE: 'SELECT_MODE'
};
export const EXAM_RESERVATION_MODE_FIELD = 'exam_publish_reservation_mode';
// In fields, appFieldId is key
export const RESERVATION_SETTINGS_MAPPING = {
    [EAppId.TE_EXAM]: [{
            label: 'General settings',
            templateType: TEMPLATE_TYPE.GENERAL,
            fields: {
                object_type_ext_id: {
                    order: 0,
                    label: 'TE Exam system type',
                    type: 'dropdown',
                    optionsSource: 'objectExtTypes',
                    loading: 'rawObjectTypesLoading',
                    onChange: 'fetchObjectTypeFields',
                    required: true
                },
                object_ext_id: {
                    order: 1,
                    label: 'TE Exam system object',
                    type: 'dropdown',
                    dependOn: 'object_type_ext_id',
                    optionsSource: 'objectTypefields',
                    loading: 'objectTypeObjectsLoading',
                    required: true
                }
            }
        }, {
            label: 'Room booking reservations',
            templateType: TEMPLATE_TYPE.SELECT_MODE,
            showDependOn: 'object_ext_id',
            modeFieldId: 'reservation_mode_ext_id',
            types: {
                object_type_ext_id: {
                    label: 'TE Exam system',
                    reduxSource: 'reservationSettings',
                    required: true,
                    requiredMessage: 'TE Exam system type not detected on room booking reservation mode'
                },
                room: {
                    label: 'Room',
                    reduxSource: 'mapping',
                    required: true,
                    requiredMessage: 'Room type not detected on room booking reservation mode'
                }
            },
            fields: {
                reservation_mode_ext_id: {
                    order: 2,
                    label: 'Select reservation mode',
                    type: 'dropdown',
                    optionsSource: 'situations',
                    loading: 'situationsLoading',
                    onChange: ['fetchSituationFields', 'fetchSituationTypes'],
                    required: true,
                    requiredMessage: 'No Room booking reservation mode selected',
                    uniqWithFields: [EXAM_RESERVATION_MODE_FIELD],
                    uniqWithFieldsMessage: 'Room reservation mode and exam reservation mode can not be the same'
                },
                reference_field_ext_id: {
                    order: 3,
                    label: 'Room booking ID',
                    type: 'dropdown',
                    dependOn: 'reservation_mode_ext_id',
                    optionsSource: 'situationFields',
                    loading: 'situationFieldsLoading',
                    required: true,
                    requiredMessage: 'Room booking ID not mapped on room booking reservation mode'
                },
                boolean_field_ext_id: {
                    order: 4,
                    label: 'Is TE Exam room booking',
                    type: 'dropdown',
                    dependOn: 'reservation_mode_ext_id',
                    optionsSource: 'situationFields',
                    loading: 'situationFieldsLoading',
                    required: true,
                    requiredMessage: 'Is TE Exam room booking not mapped on room booking reservation mode'
                },
                room_booking_public_schedule_url: {
                    order: 5,
                    label: 'Public schedule url',
                    type: 'dropdown',
                    dependOn: 'reservation_mode_ext_id',
                    optionsSource: 'situationFields',
                    loading: 'objectTypeObjectsLoading',
                    requiredMessage: 'Public Schedule URL not mapped on room booking reservation mode'
                }
            }
        }, {
            label: 'Exam reservations',
            templateType: TEMPLATE_TYPE.SELECT_MODE,
            showDependOn: 'object_ext_id',
            modeFieldId: EXAM_RESERVATION_MODE_FIELD,
            types: {
                object_type_ext_id: {
                    label: 'TE Exam system',
                    reduxSource: 'reservationSettings',
                    required: true,
                    requiredMessage: 'TE Exam system type not detected on exam reservation mode'
                },
                room: {
                    label: 'Room',
                    reduxSource: 'mapping'
                },
                professor: {
                    label: 'Professor',
                    reduxSource: 'mapping'
                },
                coordinator: {
                    label: 'Coordinator',
                    reduxSource: 'mapping'
                },
                course: {
                    label: 'Course',
                    reduxSource: 'mapping'
                },
                courseEvent: {
                    label: 'Course event',
                    reduxSource: 'mapping'
                },
                module: {
                    label: 'Module',
                    reduxSource: 'mapping'
                },
                coursePart: {
                    label: 'Course part',
                    reduxSource: 'mapping'
                },
                program: {
                    label: 'Program',
                    reduxSource: 'mapping'
                },
                studentGroup: {
                    label: 'Student group',
                    reduxSource: 'mapping'
                },
                costCenter: {
                    label: 'Cost center',
                    reduxSource: 'mapping'
                },
                department: {
                    label: 'Department',
                    reduxSource: 'mapping'
                },
                externalCustomer: {
                    label: 'External customer',
                    reduxSource: 'mapping'
                }
            },
            fields: {
                [EXAM_RESERVATION_MODE_FIELD]: {
                    order: 6,
                    label: 'Select reservation mode',
                    type: 'dropdown',
                    optionsSource: 'situations',
                    loading: 'situationsLoading',
                    onChange: ['fetchSituationFields', 'fetchSituationTypes'],
                    required: true,
                    requiredMessage: 'No Exam reservation mode selected'
                },
                public_schedule_url: {
                    order: 7,
                    label: 'Public schedule url',
                    type: 'dropdown',
                    dependOn: EXAM_RESERVATION_MODE_FIELD,
                    optionsSource: 'situationFields',
                    loading: 'objectTypeObjectsLoading',
                    required: true,
                    requiredMessage: 'Public Schedule URL not mapped on exam reservation mode'
                },
                exam_name: {
                    order: 8,
                    label: 'Exam name',
                    type: 'dropdown',
                    dependOn: EXAM_RESERVATION_MODE_FIELD,
                    optionsSource: 'situationFields',
                    loading: 'objectTypeObjectsLoading',
                    required: true,
                    requiredMessage: 'Exam name not mapped on exam reservation mode'
                },
                exam_id: {
                    order: 9,
                    label: 'Exam id',
                    type: 'dropdown',
                    dependOn: EXAM_RESERVATION_MODE_FIELD,
                    optionsSource: 'situationFields',
                    loading: 'objectTypeObjectsLoading',
                    required: true,
                    requiredMessage: 'Exam id not mapped on exam reservation mode'
                },
                student_information: {
                    order: 10,
                    label: 'Student information',
                    type: 'dropdown',
                    dependOn: EXAM_RESERVATION_MODE_FIELD,
                    optionsSource: 'situationFields',
                    loading: 'objectTypeObjectsLoading'
                },
                comment_to_scheduler: {
                    order: 11,
                    label: 'External comment',
                    type: 'dropdown',
                    dependOn: EXAM_RESERVATION_MODE_FIELD,
                    optionsSource: 'situationFields',
                    loading: 'objectTypeObjectsLoading'
                },
                planned_students: {
                    order: 12,
                    label: 'Planned students',
                    type: 'dropdown',
                    dependOn: EXAM_RESERVATION_MODE_FIELD,
                    optionsSource: 'situationFields',
                    loading: 'objectTypeObjectsLoading'
                },
                allocated_students: {
                    order: 13,
                    label: 'Allocated students',
                    type: 'dropdown',
                    dependOn: EXAM_RESERVATION_MODE_FIELD,
                    optionsSource: 'situationFields',
                    loading: 'objectTypeObjectsLoading'
                },
                exam_type: {
                    order: 14,
                    label: 'Exam type',
                    type: 'dropdown',
                    dependOn: EXAM_RESERVATION_MODE_FIELD,
                    optionsSource: 'situationFields',
                    loading: 'objectTypeObjectsLoading'
                },
                exam_status: {
                    order: 15,
                    label: 'Exam status',
                    type: 'dropdown',
                    dependOn: EXAM_RESERVATION_MODE_FIELD,
                    optionsSource: 'situationFields',
                    loading: 'objectTypeObjectsLoading'
                },
                external_reference: {
                    order: 16,
                    label: 'External reference',
                    type: 'dropdown',
                    dependOn: EXAM_RESERVATION_MODE_FIELD,
                    optionsSource: 'situationFields',
                    loading: 'objectTypeObjectsLoading'
                }
            }
        }]
};
export const RESERVATION_SETTINGS_ALL_FIELDS = {
    [EAppId.TE_EXAM]: RESERVATION_SETTINGS_MAPPING[EAppId.TE_EXAM].reduce((tot, _ref) => {
        let { fields } = _ref;
        return {
            ...tot,
            ...fields
        };
    }, {})
};
