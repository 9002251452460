import { EFieldType, EPreferencesFilter } from '@timeedit/types';
export const calculateProperties = _ref => {
    let { fieldType, mandatory, multiple } = _ref;
    if (fieldType === EFieldType.TEXT || fieldType === EFieldType.SIGNATURE) {
        return {
            searchable: true,
            listable: !multiple,
            primary: multiple ? false : mandatory,
            sortable: multiple ? false : mandatory
        };
    }
    else if (fieldType === EFieldType.EMAIL || fieldType === EFieldType.URL || fieldType === EFieldType.TELEPHONE || fieldType === EFieldType.INTEGER || fieldType === EFieldType.NON_SEARCHABLE_TEXT) {
        return {
            searchable: false,
            listable: !multiple,
            primary: false,
            sortable: multiple ? false : mandatory
        };
    }
    else if (fieldType === EFieldType.CATEGORY) {
        return {
            searchable: true,
            listable: !multiple,
            primary: false,
            sortable: multiple ? false : mandatory
        };
    }
    else if (fieldType === EFieldType.COMMENT || fieldType === EFieldType.LENGTH) {
        return {
            searchable: true,
            listable: false,
            primary: false,
            sortable: false
        };
    }
    else if (fieldType === EFieldType.CHECKBOX) {
        return {
            searchable: true,
            listable: true,
            primary: false,
            sortable: false
        };
    }
    else if (fieldType === EFieldType.REFERENCE) {
        return {
            searchable: true,
            listable: true,
            primary: mandatory,
            sortable: mandatory
        };
    }
    else if (fieldType === EFieldType.NON_SEARCHABLE_REFERENCE) {
        return {
            searchable: false,
            listable: true,
            primary: false,
            sortable: mandatory
        };
    }
};
export const defaultMap = {
    filter: {
        isEnabled: false
    },
    length: {
        isEnabled: false
    },
    minMax: {
        isEnabled: false
    },
    min: {
        isEnabled: false
    },
    max: {
        isEnabled: false
    },
    sumType: {
        isEnabled: false
    },
    titleType: {
        isEnabled: false
    },
    topLevelType: {
        isEnabled: false
    },
    refSeparator: {
        isEnabled: false
    },
    searchableInReservationList: {
        isEnabled: false
    },
    reservationTextField: {
        isEnabled: false
    },
    mandatory: {
        isEnabled: false
    },
    unique: {
        isEnabled: false
    },
    multiple: {
        isEnabled: false
    },
    editable: {
        isEnabled: false
    },
    searchable: {
        isEnabled: false
    },
    listable: {
        isEnabled: false
    },
    primary: {
        isEnabled: false
    },
    sortable: {
        isEnabled: false
    },
    virtualCategory: {
        isEnabled: false
    },
    abstractCategory: {
        isEnabled: false
    }
};
export const mapCol2 = {
    [EFieldType.TEXT]: {
        ...defaultMap,
        mandatory: {
            isEnabled: true,
            defaultValue: false
        },
        multiple: {
            isEnabled: true,
            defaultValue: false
        },
        unique: {
            isEnabled: true,
            defaultValue: false
        },
        editable: {
            isEnabled: true,
            defaultValue: true
        },
        filter: {
            isEnabled: true
        },
        length: {
            isEnabled: true,
            defaultValue: 192
        }
        //
    },
    [EFieldType.SIGNATURE]: {
        ...defaultMap,
        mandatory: {
            isEnabled: true,
            defaultValue: false
        },
        multiple: {
            isEnabled: true,
            defaultValue: false
        },
        unique: {
            isEnabled: true,
            defaultValue: false
        },
        editable: {
            isEnabled: false,
            defaultValue: true
        },
        filter: {
            isEnabled: true
        },
        length: {
            isEnabled: true
        }
    },
    [EFieldType.EMAIL]: {
        ...defaultMap,
        mandatory: {
            isEnabled: true,
            defaultValue: false
        },
        multiple: {
            isEnabled: true,
            defaultValue: false
        },
        unique: {
            isEnabled: true,
            defaultValue: false
        },
        editable: {
            isEnabled: false,
            defaultValue: true
        },
        filter: {
            isEnabled: true
        },
        length: {
            isEnabled: true
        }
    },
    [EFieldType.URL]: {
        ...defaultMap,
        filter: {
            isEnabled: true
        },
        length: {
            isEnabled: true
        },
        mandatory: {
            isEnabled: true,
            defaultValue: false
        },
        multiple: {
            isEnabled: true,
            defaultValue: false
        },
        unique: {
            isEnabled: false,
            defaultValue: false
        },
        editable: {
            isEnabled: false,
            defaultValue: true
        }
    },
    [EFieldType.TELEPHONE]: {
        ...defaultMap,
        filter: {
            isEnabled: true
        },
        length: {
            isEnabled: true
        },
        mandatory: {
            isEnabled: true,
            defaultValue: false
        },
        multiple: {
            isEnabled: true,
            defaultValue: false
        },
        unique: {
            isEnabled: true,
            defaultValue: true
        },
        editable: {
            isEnabled: false,
            defaultValue: true
        }
    },
    [EFieldType.CATEGORY]: {
        ...defaultMap,
        filter: {
            isEnabled: true
        },
        length: {
            isEnabled: true,
            defaultValue: 48
        },
        mandatory: {
            isEnabled: true,
            defaultValue: false
        },
        multiple: {
            isEnabled: true,
            defaultValue: false
        },
        unique: {
            isEnabled: false,
            defaultValue: false
        },
        editable: {
            isEnabled: true,
            defaultValue: true
        },
        searchableInReservationList: {
            isEnabled: true
        },
        topLevelType: {
            isEnabled: true
        },
        reservationTextField: {
            isEnabled: true
        },
        titleType: {
            isEnabled: true
        },
        virtualCategory: {
            isEnabled: true
        },
        abstractCategory: {
            isEnabled: true
        }
    },
    [EFieldType.COMMENT]: {
        ...defaultMap,
        filter: {
            isEnabled: true
        },
        length: {
            isEnabled: true
        },
        mandatory: {
            isEnabled: false,
            defaultValue: false
        },
        multiple: {
            isEnabled: false,
            defaultValue: false
        },
        unique: {
            isEnabled: false,
            defaultValue: false
        },
        editable: {
            isEnabled: false,
            defaultValue: true
        }
    },
    [EFieldType.INTEGER]: {
        ...defaultMap,
        mandatory: {
            isEnabled: true,
            defaultValue: false
        },
        multiple: {
            isEnabled: true,
            defaultValue: false
        },
        unique: {
            isEnabled: false,
            defaultValue: false
        },
        editable: {
            isEnabled: true,
            defaultValue: true
        },
        sumType: {
            isEnabled: true
        },
        minMax: {
            isEnabled: true
        }
    },
    [EFieldType.LENGTH]: {
        ...defaultMap,
        mandatory: {
            isEnabled: false,
            defaultValue: false
        },
        multiple: {
            isEnabled: false,
            defaultValue: false
        },
        unique: {
            isEnabled: false,
            defaultValue: false
        },
        editable: {
            isEnabled: false,
            defaultValue: true
        },
        minMax: {
            isEnabled: true
        }
    },
    [EFieldType.CHECKBOX]: {
        ...defaultMap,
        mandatory: {
            isEnabled: false,
            defaultValue: false
        },
        multiple: {
            isEnabled: false,
            defaultValue: false
        },
        unique: {
            isEnabled: false,
            defaultValue: false
        },
        editable: {
            isEnabled: false,
            defaultValue: true
        },
        topLevelType: {
            isEnabled: true
        },
        titleType: {
            isEnabled: true
        },
        searchableInReservationList: {
            isEnabled: true
        }
    },
    [EFieldType.REFERENCE]: {
        ...defaultMap,
        mandatory: {
            isEnabled: true,
            defaultValue: false
        },
        multiple: {
            isEnabled: false,
            defaultValue: false
        },
        unique: {
            isEnabled: false,
            defaultValue: false
        },
        editable: {
            isEnabled: false,
            defaultValue: false
        }
    },
    [EFieldType.NON_SEARCHABLE_TEXT]: {
        ...defaultMap,
        filter: {
            isEnabled: true
        },
        length: {
            isEnabled: true
        },
        mandatory: {
            isEnabled: true,
            defaultValue: false
        },
        multiple: {
            isEnabled: true,
            defaultValue: false
        },
        unique: {
            isEnabled: true,
            defaultValue: false
        },
        editable: {
            isEnabled: false,
            defaultValue: true
        }
    },
    [EFieldType.NON_SEARCHABLE_REFERENCE]: {
        ...defaultMap,
        mandatory: {
            isEnabled: true,
            defaultValue: false
        },
        multiple: {
            isEnabled: false,
            defaultValue: false
        },
        unique: {
            isEnabled: false,
            defaultValue: false
        },
        editable: {
            isEnabled: false,
            defaultValue: false
        }
    }
};
export const preferenceFilterLabels = {
    [EPreferencesFilter.CHOICE1]: 'Numbers',
    [EPreferencesFilter.CHOICE2]: 'Phone',
    [EPreferencesFilter.CHOICE3]: 'Alphanumerical',
    [EPreferencesFilter.CHOICE4]: 'Alphanumerical with Swedish characters',
    [EPreferencesFilter.CHOICE5]: 'E-mail',
    [EPreferencesFilter.CHOICE6]: 'E-mail with Swedish characters'
};
export const preferencesFilterOptions = [{
        value: '',
        label: 'No filter'
    }, ...Object.entries(preferenceFilterLabels).map(_ref2 => {
        let [value, label] = _ref2;
        return {
            label,
            value
        };
    })];
