import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { assertWithId } from 'utils/sliceHelpers/utils';
const useSelectResource = (resourceMap, resource1, resource2, setResource1, setResource2) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const isEntry = useRef(true);
    const [resource1Id, setResource1Id] = useState();
    const [resource2Id, setResource2Id] = useState();
    useEffect(() => {
        if (searchParams && resourceMap && isEntry.current) {
            const id1 = searchParams.get('item1');
            const id2 = searchParams.get('item2');
            setResource1Id(id1);
            setResource2Id(id2);
            if (id1 && resourceMap[id1]) {
                setResource1(resourceMap[id1]);
            }
            if (id2 && resourceMap[id2]) {
                setResource2(resourceMap[id2]);
            }
            if (!_.isEmpty(resourceMap)) {
                isEntry.current = false;
            }
        }
    }, [searchParams, resourceMap, isEntry, setResource1, setResource2]);
    useEffect(() => {
        // If it's the first time we enter the page, we don't modify the search params
        if (isEntry.current)
            return;
        const itemDic = {};
        if (assertWithId(resource1)) {
            itemDic.item1 = resource1.id;
        }
        if (assertWithId(resource2)) {
            itemDic.item2 = resource2.id;
        }
        setSearchParams(itemDic);
    }, [isEntry, resource1, resource2, setSearchParams]);
    return _.compact([resource1Id, resource2Id]);
};
export default useSelectResource;
