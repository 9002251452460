import { useEffect, useMemo } from 'react';
const useFetchMissingItems = _ref => {
    let { itemIds, itemMap, fetchItemsFn } = _ref;
    const [items, notFoundIds] = useMemo(() => {
        const items = [];
        const notFoundIds = [];
        itemIds.forEach(id => {
            const item = itemMap[id];
            if (item) {
                items.push(item);
            }
            else {
                notFoundIds.push(id);
            }
        });
        return [items, notFoundIds];
    }, [itemIds, itemMap]);
    useEffect(() => {
        if (notFoundIds.length === 0)
            return;
        fetchItemsFn(notFoundIds);
    }, [notFoundIds, fetchItemsFn]);
    return items;
};
export default useFetchMissingItems;
