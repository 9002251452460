import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { createPermission, EPermissionTypes } from '@timeedit/types';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, stateHasFetchedAllObjectsSuccess } from 'utils/sliceHelpers';
import { createAppAsyncThunk } from 'slices/utils';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0
};
// Slice
const slice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        fetchPermissionsRequest: state => {
            isLoadingRequest(state);
        },
        fetchPermissionsSuccess: (state, _ref) => {
            let { payload } = _ref;
            stateHasFetchedAllObjectsSuccess(payload, state, createPermission);
            finishedLoadingSuccess(state);
        },
        fetchPermissionsFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const scopesSelector = state => state.permissions.results.filter(permission => permission.type === EPermissionTypes.SCOPE);
export const appPermissionsSelector = state => state.permissions.results.filter(permission => permission.type === EPermissionTypes.APP_PERMISSION);
// Actions
export const { fetchPermissionsRequest, fetchPermissionsSuccess, fetchPermissionsFailure } = slice.actions;
export const fetchPermissions = createAppAsyncThunk('permissions/fetchPermissions', async (_, _ref2) => {
    let { dispatch } = _ref2;
    try {
        dispatch(fetchPermissionsRequest());
        const result = await api.get({
            endpoint: `/permissions?sortBy=name`
        });
        dispatch(fetchPermissionsSuccess(result));
    }
    catch (e) {
        dispatch(fetchPermissionsFailure());
        console.error(e);
    }
});
