import { createSlice } from '@reduxjs/toolkit';
import { fetchOrganization } from './organization.slice';
import { fetchRootUsers } from './user.slice';
import { fetchAssignablePermissions } from './assignablePermission.slice';
import { parseCustomerSignature } from 'utils/customerSignature';
const initialState = {
    loading: true,
    hasErrors: false,
    user: null
};
const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        onLoginSuccess: (state, _ref) => {
            let { payload: user } = _ref;
            if (!user) {
                /**
                 * If we don't have a user, this should be considered an error
                 * and we'll consequently set the redux state to mimic this
                 */
                state.hasErrors = true;
                state.user = null;
            }
            else {
                state.hasErrors = false;
                state.user = user;
            }
            state.loading = false;
        },
        onLogout: state => {
            localStorage.clear();
            state.hasErrors = false;
            state.loading = false;
            state.user = null;
        }
    }
});
export default slice.reducer;
// Selectors
export const userSelector = state => state.auth.user;
/** Use only for URL & navigation related purposes */
export const parsedCustomerSignatureSelector = state => parseCustomerSignature(state.auth.user.customerSignature);
// Actions
export const { onLoginSuccess, onLogout } = slice.actions;
export const fetchInitialData = () => dispatch => {
    dispatch(fetchOrganization());
    dispatch(fetchRootUsers());
    dispatch(fetchAssignablePermissions());
};
