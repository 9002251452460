import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'slices/utils';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const initialState = {
    loading: false,
    hasErrors: false,
    emailPreferences: {},
    updatedEmailPreferences: {}
};
// Slice
const slice = createSlice({
    name: 'emailPreferences',
    initialState,
    reducers: {
        fetchEmailPreferencesRequest: state => {
            isLoadingRequest(state);
        },
        fetchEmailPreferencesSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.emailPreferences = payload;
            state.updatedEmailPreferences = payload;
            finishedLoadingSuccess(state);
        },
        fetchEmailPreferencesFailure: state => {
            finishedLoadingFailure(state);
        },
        setsUpdatedEmailPreferences: (state, _ref2) => {
            let { payload } = _ref2;
            state.updatedEmailPreferences = payload;
        },
        updateEmailPreferencesRequest: state => {
            isLoadingRequest(state);
        },
        updateEmailPreferencesSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            state.emailPreferences = payload;
            state.updatedEmailPreferences = payload;
            finishedLoadingSuccess(state);
        },
        updateEmailPreferencesFailure: state => {
            finishedLoadingFailure(state);
        },
        discardEmailPreferences: state => {
            state.updatedEmailPreferences = state.emailPreferences;
        }
    }
});
export default slice.reducer;
// Selectors
export const updatedEmailPreferencesSelector = state => state.emailPreferences.updatedEmailPreferences;
// Actions
export const { fetchEmailPreferencesRequest, fetchEmailPreferencesSuccess, fetchEmailPreferencesFailure, setsUpdatedEmailPreferences, updateEmailPreferencesRequest, updateEmailPreferencesSuccess, updateEmailPreferencesFailure, discardEmailPreferences } = slice.actions;
export const fetchEmailPreferences = () => async (dispatch) => {
    try {
        dispatch(fetchEmailPreferencesRequest());
        const emailPreferences = await api.get({
            endpoint: `/email-preferences`
        });
        dispatch(fetchEmailPreferencesSuccess(emailPreferences));
    }
    catch (e) {
        dispatch(fetchEmailPreferencesFailure());
        return console.error(e);
    }
};
export const setUpdatedEmailPreferences = createAppAsyncThunk('emailPreferences/setUpdatedEmailPreferences', async (emailPreferences, _ref4) => {
    let { dispatch } = _ref4;
    dispatch(setsUpdatedEmailPreferences(emailPreferences));
});
export const updateEmailPreferences = createAppAsyncThunk('emailPreferences/updateEmailPreferences', async (emailPreferences, _ref5) => {
    let { dispatch } = _ref5;
    try {
        dispatch(updateEmailPreferencesRequest());
        const { ...emailPreferencesBody } = emailPreferences;
        const response = await api.patch({
            endpoint: `/email-preferences`,
            data: {
                ...emailPreferencesBody
            }
        });
        dispatch(updateEmailPreferencesSuccess(response));
    }
    catch (e) {
        dispatch(updateEmailPreferencesFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
