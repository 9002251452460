import _ from 'lodash';
export function checkSavedDisabled(updateObject, originalObject) {
    const ignoreProps = ['history'];
    if (!originalObject) {
        return false;
    }
    return _.every(Object.keys(updateObject), key => {
        if (ignoreProps.indexOf(key) !== -1) {
            return true;
        }
        return _.isEqual(updateObject[key], originalObject[key]);
    });
}
