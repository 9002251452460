import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { addEntityToState, deleteEntityFromState, finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, prepEntityForAPICall, stateHasFetchedAllObjectsSuccess, upsertEntity } from 'utils/sliceHelpers';
import { createAppAsyncThunk } from 'slices/utils';
import { createM2MUser as createTM2MUser } from '@timeedit/types';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0
};
// Slice
const slice = createSlice({
    name: 'apiKeys',
    initialState,
    reducers: {
        fetchM2MUsersRequest: state => {
            isLoadingRequest(state);
        },
        fetchM2MUsersSuccess: (state, _ref) => {
            let { payload } = _ref;
            stateHasFetchedAllObjectsSuccess(payload, state, createTM2MUser);
            finishedLoadingSuccess(state);
        },
        fetchM2MUsersFailure: state => {
            finishedLoadingFailure(state);
        },
        createM2MUser: state => {
            addEntityToState(state, {
                id: 'new',
                name: 'Name'
            }, createTM2MUser);
            finishedLoadingSuccess(state);
        },
        saveM2MUserRequest: state => {
            isLoadingRequest(state);
        },
        saveM2MUserSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            upsertEntity(state, payload, createTM2MUser);
            finishedLoadingSuccess(state);
        },
        saveM2MUserFailure: state => {
            finishedLoadingFailure(state);
        },
        deleteM2MUserRequest: state => {
            isLoadingRequest(state);
        },
        deleteM2MUserSuccess: (state, _ref3) => {
            let { payload: userId } = _ref3;
            deleteEntityFromState(userId, state);
            finishedLoadingSuccess(state);
        },
        deleteM2MUserFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const usersLoading = state => state.apiKeys.loading;
export const usersSelector = state => state.apiKeys.results;
export const userSelector = id => state => id ? state.apiKeys.map[id] : undefined;
// Actions
export const { fetchM2MUsersRequest, fetchM2MUsersSuccess, fetchM2MUsersFailure, createM2MUser, deleteM2MUserRequest, deleteM2MUserFailure, deleteM2MUserSuccess, saveM2MUserRequest, saveM2MUserSuccess, saveM2MUserFailure } = slice.actions;
export const fetchM2MUsers = createAppAsyncThunk('apiKeys/fetchM2MUsers', async (_, _ref4) => {
    let { dispatch } = _ref4;
    try {
        dispatch(fetchM2MUsersRequest());
        const result = await api.get({
            endpoint: `/m2m-users`
        });
        dispatch(fetchM2MUsersSuccess(result));
    }
    catch (e) {
        dispatch(fetchM2MUsersFailure());
    }
});
export const saveM2MUser = createAppAsyncThunk('apiKeys/saveM2MUser', async (user, _ref5) => {
    let { dispatch } = _ref5;
    try {
        dispatch(saveM2MUserRequest());
        /**
         * Could be both a create new or an update
         * Depending on which, we need to use different methods (POST or PATCH)
         */
        if (user.id === 'new') {
            // Create a new one
            const preppedM2MUser = prepEntityForAPICall(user, true, true);
            const savedM2MUser = await api.post({
                endpoint: `/m2m-users`,
                data: preppedM2MUser,
                successMessage: 'Successfully created user'
            });
            dispatch(saveM2MUserSuccess(savedM2MUser));
            dispatch(deleteM2MUserSuccess('new'));
        }
        else {
            const preppedM2MUser = prepEntityForAPICall(user, true, true);
            const updatedM2MUser = await api.patch({
                endpoint: `/m2m-users/${user.id}`,
                data: preppedM2MUser
            });
            dispatch(saveM2MUserSuccess(updatedM2MUser));
        }
    }
    catch (error) {
        dispatch(saveM2MUserFailure());
    }
});
export const deleteM2MUser = createAppAsyncThunk('apiKeys/deleteM2MUser', async (userId, _ref6) => {
    let { dispatch } = _ref6;
    try {
        dispatch(deleteM2MUserRequest());
        /**
         * No need to send the request to the backend if the entity hasn't been saved
         */
        if (userId !== 'new')
            await api.delete({
                endpoint: `/m2m-users/${userId}`
            });
        dispatch(deleteM2MUserSuccess(userId));
    }
    catch (e) {
        dispatch(deleteM2MUserFailure());
    }
});
