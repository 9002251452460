import { useMemo, useState } from 'react';
const useSelectSearch = function (items) {
    let valueKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';
    let labelKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'name';
    const [filterQuery, setFilterQuery] = useState('');
    const optionizedItems = useMemo(() => (items || []).map(el => ({
        value: el[valueKey],
        key: el[valueKey],
        label: el[labelKey]
    })), [items, labelKey, valueKey]);
    const filteredItems = useMemo(() => {
        if (!filterQuery || !filterQuery.length)
            return optionizedItems;
        const normalizedQuery = filterQuery.toLowerCase();
        const filteredItems = optionizedItems.filter(el => !!(el.label && el.label.toString().toLowerCase().includes(normalizedQuery)));
        return filteredItems;
    }, [optionizedItems, filterQuery]);
    return [filteredItems, setFilterQuery];
};
export default useSelectSearch;
