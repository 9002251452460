import axios from 'axios';
import { configService } from './config.service';
import { getAccessToken } from '@timeedit/ui-components';
import { message } from 'antd';
const prepareOption = function (requiresAuth) {
    let { data, headers, method, params, ...options } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const option = {
        ...options,
        method,
        headers: {
            ...headers
        }
    };
    if (requiresAuth) {
        const token = getAccessToken();
        option.headers = {
            Authorization: `Bearer ${token}`,
            ...headers
        };
    }
    if (data) {
        if (method === 'GET') {
            option.params = {
                ...data,
                ...(params || {})
            };
        }
        else {
            if (Array.isArray(data)) {
                option.data = [...data];
            }
            else {
                option.data = {
                    ...data
                };
            }
            if (params)
                option.params = {
                    ...params
                };
        }
    }
    return option;
};
/**
 * @function getAPIUrl
 * @description returns URL for TE Preferences API depending on environment
 * @param {String} endpoint route to be called
 * @returns {String}
 */
const getAPIUrl = endpoint => {
    if (endpoint.search('http://') > -1 || endpoint.search('https://') > -1)
        return endpoint;
    return `${configService.REACT_APP_BASE_URL}${endpoint}`;
};
const apiRequest = async (_ref) => {
    let { method, endpoint, absoluteUrl = false, data, params = null, requiresAuth = true, headers, successMessage = undefined, errorMessage = undefined, suppressNotification = false, responseType } = _ref;
    const fullUrl = !absoluteUrl ? getAPIUrl(endpoint) : endpoint;
    const option = prepareOption(requiresAuth, {
        method,
        data,
        headers,
        params,
        responseType
    });
    try {
        const response = await axios(fullUrl, option);
        /**
         * When to show success messages:
         * x) For all POST, PATCH, PUT, DELETE calls unless explicitely opted out of (i.e., successMessage = false)
         * x) For GET if explicitely asked for (i.e., successMessage !== undefined && !== false)
         */
        if (method === 'GET' && !!successMessage) {
            if (!suppressNotification) {
                message.success(successMessage);
            }
        }
        if (method !== 'GET' && (successMessage === undefined || !!successMessage) && !suppressNotification) {
            message.success(typeof successMessage === 'string' ? successMessage : 'The operation was successful');
        }
        return response.data;
    }
    catch (error) {
        /**
         * When to show success messages:
         * x) For all failed calls unless explicitely opted out of (i.e., errorMessage = false)
         * x) If errorMessage is specified, show the custom message - otherwise show error.message.toString()
         */
        if (!(error?.response?.data?.status === 401 && method === 'GET') && !suppressNotification) {
            message.error(typeof errorMessage === 'string' ? errorMessage : error.response.data.message);
        }
        throw error;
    }
};
const getAndDeleteDefaults = (method, props) => ({
    method,
    endpoint: props.endpoint,
    absoluteUrl: props.absoluteUrl || false,
    data: props.data || undefined,
    requiresAuth: props.requiresAuth || true,
    headers: props.headers || undefined,
    successMessage: props.successMessage,
    errorMessage: props.errorMessage || undefined,
    suppressNotification: props.suppressNotification || false
});
const postPutPatchDefaults = (method, props) => ({
    method,
    endpoint: props.endpoint,
    absoluteUrl: props.absoluteUrl || false,
    data: props.data || undefined,
    params: props.params || undefined,
    requiresAuth: props.requiresAuth || true,
    headers: props.headers || undefined,
    successMessage: props.successMessage,
    successToastTitle: props.successToastTitle || undefined,
    errorMessage: props.errorMessage || undefined,
    suppressNotification: props.suppressNotification || false,
    responseType: props.responseType || undefined
});
const apiService = {
    get: props => apiRequest(getAndDeleteDefaults('GET', props)),
    post: props => apiRequest(postPutPatchDefaults('POST', props)),
    put: props => apiRequest(postPutPatchDefaults('PUT', props)),
    patch: props => apiRequest(postPutPatchDefaults('PATCH', props)),
    delete: props => apiRequest(getAndDeleteDefaults('DELETE', props))
};
export default apiService;
