export function parseInactiveSince(value) {
    if (typeof value === 'undefined') {
        // No data maintains the current state
        return;
    }
    if (typeof value === 'number') {
        // 0 will activate the object
        return value || null;
    }
    const inactiveValue = Number(value);
    if (inactiveValue === 0) {
        return;
    }
    return isNaN(inactiveValue) ? null : inactiveValue;
}
