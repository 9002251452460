import { createSlice } from '@reduxjs/toolkit';
import { createEmptyReservationMode } from '@timeedit/types';
import _, { keyBy } from 'lodash';
import { createAppAsyncThunk } from 'slices/utils';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
import { getAllData } from 'utils/fetchAllData';
const TEMP_ID = 0;
export const initialState = {
    loading: false,
    hasErrors: false,
    selectedReservationMode: createEmptyReservationMode(),
    allReservationModes: [],
    reservationModesForOtherPages: [],
    map: {},
    firstDrawer: undefined,
    secondDrawer: undefined
};
// Slice
const slice = createSlice({
    name: 'reservationModes',
    initialState,
    reducers: {
        fetchReservationModesRequest: state => {
            isLoadingRequest(state);
        },
        fetchReservationModesSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.allReservationModes = payload.results;
            state.map = keyBy(payload.results, 'id');
            finishedLoadingSuccess(state);
        },
        fetchReservationModesFailure: state => {
            finishedLoadingFailure(state);
        },
        createReservationModeRequest: state => {
            isLoadingRequest(state);
        },
        createReservationModeSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            if (!state.map[TEMP_ID]) {
                state.allReservationModes.unshift(payload);
                state.map[payload.id] = payload;
            }
            finishedLoadingSuccess(state);
        },
        createReservationModeFailure: state => {
            finishedLoadingFailure(state);
        },
        deleteReservationModeRequest: state => {
            isLoadingRequest(state);
        },
        deleteReservationModeSuccess: (state, _ref3) => {
            let { payload: reservationModeId } = _ref3;
            state.allReservationModes = state.allReservationModes.filter(mode => mode.id !== reservationModeId);
            state.selectedReservationMode = createEmptyReservationMode();
            finishedLoadingSuccess(state);
        },
        deleteReservationModeFailure: state => {
            finishedLoadingFailure(state);
        },
        updateReservationModeRequest: state => {
            isLoadingRequest(state);
        },
        updateReservationModeSuccess: (state, _ref4) => {
            let { payload } = _ref4;
            const newReservationModes = [...state.allReservationModes];
            const index = state.allReservationModes.findIndex(mode => mode.id === payload.id);
            newReservationModes[index] = payload;
            state.allReservationModes = newReservationModes;
            state.selectedReservationMode = payload;
            finishedLoadingSuccess(state);
        },
        updateReservationModeFailure: state => {
            finishedLoadingFailure(state);
        },
        setFirstDrawer: (state, _ref5) => {
            let { payload } = _ref5;
            const firstDrawer = state.firstDrawer;
            state.firstDrawer = payload;
            if (!payload && firstDrawer?.id === TEMP_ID) {
                state.allReservationModes = state?.allReservationModes?.filter(item => item.id !== TEMP_ID);
            }
        },
        setSecondDrawer: (state, _ref6) => {
            let { payload } = _ref6;
            state.secondDrawer = payload;
        },
        updateDrawer: (state, _ref7) => {
            let { payload } = _ref7;
            const { isFirstDrawer, key, value } = payload;
            if (isFirstDrawer) {
                state.firstDrawer = {
                    ...state.firstDrawer,
                    [key]: value
                };
            }
            else {
                state.secondDrawer = {
                    ...state.secondDrawer,
                    [key]: value
                };
            }
        },
        duplicateReservationModes: (state, _ref8) => {
            let { payload } = _ref8;
            const newItem = {
                ...payload,
                id: TEMP_ID,
                name: payload.name + '-copy',
                history: []
            };
            state.allReservationModes = state.allReservationModes.filter(reservationMode => reservationMode.id !== TEMP_ID);
            state.firstDrawer = newItem;
            state.allReservationModes.unshift(newItem);
            state.map[TEMP_ID] = newItem;
        },
        discardChanges: (state, _ref9) => {
            let { payload } = _ref9;
            const { isFirstDrawer, id } = payload;
            if (isFirstDrawer) {
                state.firstDrawer = state.map[id];
            }
            else {
                state.secondDrawer = state.map[id];
            }
        }
    }
});
export default slice.reducer;
// Selectors
export const reservationModesLoading = state => state.reservationModes.loading;
export const reservationModesSelector = state => state.reservationModes.allReservationModes;
export const reservationModesMapSelector = state => state.reservationModes.map;
export const selectedFirstDrawerSelector = state => state.reservationModes.firstDrawer;
export const selectedSecondDrawerSelector = state => state.reservationModes.secondDrawer;
// TODO: Replace this with a `selectedIds` property updated when firstDrawer or secondDrawer is updated
export const selectedIdsSelector = state => _.compact([state.reservationModes.firstDrawer, state.reservationModes.secondDrawer]).map(pr => pr.id);
// Actions
export const { fetchReservationModesRequest, fetchReservationModesSuccess, fetchReservationModesFailure, createReservationModeRequest, createReservationModeSuccess, createReservationModeFailure, deleteReservationModeRequest, deleteReservationModeSuccess, deleteReservationModeFailure, updateReservationModeRequest, updateReservationModeSuccess, updateReservationModeFailure, setFirstDrawer, setSecondDrawer, updateDrawer, duplicateReservationModes, discardChanges } = slice.actions;
export const fetchReservationModes = createAppAsyncThunk('reservationModes/fetchReservationModes', async (payload, _ref10) => {
    let { dispatch } = _ref10;
    const { sort, templateType } = payload || {};
    try {
        const params = {
            ...(sort && {
                sort
            }),
            ...(templateType && {
                templateType
            })
        };
        dispatch(fetchReservationModesRequest());
        const reservationModes = await getAllData({
            endpoint: `/reservation-modes?${new URLSearchParams(params)}`
        }, 'get');
        dispatch(fetchReservationModesSuccess(reservationModes));
    }
    catch (e) {
        dispatch(fetchReservationModesFailure());
        return console.error(e);
    }
});
export const createReservationMode = createAppAsyncThunk('reservationModes/createReservationMode', async (reservationMode, _ref11) => {
    let { dispatch } = _ref11;
    try {
        dispatch(createReservationModeRequest());
        const { id, history, ...reservationModeBody } = reservationMode;
        const response = await api.post({
            endpoint: `/reservation-modes`,
            data: reservationModeBody
        });
        dispatch(createReservationModeSuccess(response));
        dispatch(fetchReservationModes());
        dispatch(setFirstDrawer(undefined));
    }
    catch (e) {
        dispatch(createReservationModeFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const createUnsavedReservationMode = () => dispatch => {
    const response = createEmptyReservationMode();
    dispatch(createReservationModeSuccess(response));
    dispatch(setFirstDrawer(response));
};
export const deleteReservationMode = createAppAsyncThunk('reservationModes/deleteReservationMode', async (_ref12, _ref13) => {
    let { isFirstDrawer, reservationModeId } = _ref12;
    let { dispatch } = _ref13;
    try {
        dispatch(deleteReservationModeRequest());
        await api.delete({
            endpoint: `/reservation-modes/${reservationModeId}`
        });
        if (isFirstDrawer === true) {
            dispatch(setFirstDrawer(undefined));
        }
        else if (isFirstDrawer === false) {
            dispatch(setSecondDrawer(undefined));
        }
        dispatch(deleteReservationModeSuccess(reservationModeId));
    }
    catch (e) {
        dispatch(deleteReservationModeFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const updateReservationMode = createAppAsyncThunk('reservationModes/updateReservationMode', async (_ref14, _ref15) => {
    let { isFirstDrawer, reservationMode } = _ref14;
    let { dispatch } = _ref15;
    try {
        dispatch(updateReservationModeRequest());
        const { id, history, ...reservationModeBody } = reservationMode;
        const response = await api.patch({
            endpoint: `/reservation-modes/${reservationMode.id}`,
            data: reservationModeBody
        });
        dispatch(updateReservationModeSuccess(response));
        dispatch(fetchReservationModes());
        if (isFirstDrawer) {
            dispatch(setFirstDrawer(undefined));
        }
        else {
            dispatch(setSecondDrawer(undefined));
        }
    }
    catch (e) {
        dispatch(updateReservationModeFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const getDrawer = isFirstDrawer => (_, getState) => {
    const { reservationModes } = getState();
    const { firstDrawer, secondDrawer } = reservationModes;
    return isFirstDrawer ? firstDrawer : secondDrawer;
};
export const checkDisabled = isFirstDrawer => (dispatch, getState) => {
    try {
        const { reservationModes } = getState();
        const { firstDrawer, secondDrawer, map } = reservationModes;
        if (isFirstDrawer && firstDrawer && map[firstDrawer.id]) {
            return checkSavedDisabled(firstDrawer, map[firstDrawer.id]);
        }
        if (!isFirstDrawer && secondDrawer && map[secondDrawer.id]) {
            return checkSavedDisabled(secondDrawer, map[secondDrawer.id]);
        }
        return true;
    }
    catch (e) {
        console.error(e);
    }
};
