import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import { createAppAsyncThunk } from 'slices/utils';
import { ESeatingMethod, ESeatingMode } from 'types/examV3Settings.type';
export const initialState = {
    loading: false,
    hasErrors: false,
    seatAllocationSettings: {
        seatingMode: {
            mode: ESeatingMode.StudentSpecific,
            allowOverride: false
        },
        seatingMethod: {
            mode: ESeatingMethod.clumped,
            allowOverride: false
        }
    },
    reservationSettings: {
        examReservationMode: undefined,
        sizeReservationTemplate: undefined,
        capacityReservationTemplate: undefined,
        requiredInvigilatorNumber: undefined
    }
};
// Slice
const slice = createSlice({
    name: 'examV3ReservationSettings',
    initialState,
    reducers: {
        fetchReservationSettingsRequest: state => {
            isLoadingRequest(state);
        },
        fetchReservationSettingsSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.reservationSettings = payload;
            finishedLoadingSuccess(state);
        },
        fetchReservationSettingsFailure: state => {
            finishedLoadingFailure(state);
        },
        saveReservationSettingsRequest: state => {
            isLoadingRequest(state);
        },
        saveReservationSettingsSuccess: state => {
            finishedLoadingSuccess(state);
        },
        saveReservationSettingsFailure: state => {
            finishedLoadingFailure(state);
        },
        selectReservationOption: (state, _ref2) => {
            let { payload } = _ref2;
            state.reservationSettings = {
                ...state.reservationSettings,
                ...payload
            };
        },
        fetchAllocationModeRequest: state => {
            isLoadingRequest(state);
        },
        fetchAllocationModeSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            state.seatAllocationSettings = payload;
            finishedLoadingSuccess(state);
        },
        fetchAllocationModeFailure: state => {
            finishedLoadingFailure(state);
        },
        saveAllocationModeRequest: state => {
            isLoadingRequest(state);
        },
        saveAllocationModeSuccess: state => {
            finishedLoadingSuccess(state);
        },
        saveAllocationModeFailure: state => {
            finishedLoadingFailure(state);
        },
        updateSeatAllocateSetting: (state, _ref4) => {
            let { payload } = _ref4;
            state.seatAllocationSettings = {
                ...state.seatAllocationSettings,
                ...payload
            };
        }
    }
});
export default slice.reducer;
// Selectors
export const examV3ReservationSettingsSelector = state => state.examV3Settings.reservationSettings;
export const examV3SettingsLoadingSelector = state => state.examV3Settings.loading;
// Selectors
export const seatAllocationSettingsSelector = state => state.examV3Settings.seatAllocationSettings;
// Actions
export const { 
// reservation settings
fetchReservationSettingsRequest, fetchReservationSettingsSuccess, fetchReservationSettingsFailure, saveReservationSettingsRequest, saveReservationSettingsSuccess, saveReservationSettingsFailure, selectReservationOption, 
// allocation mode
fetchAllocationModeRequest, fetchAllocationModeSuccess, fetchAllocationModeFailure, saveAllocationModeRequest, saveAllocationModeSuccess, saveAllocationModeFailure, updateSeatAllocateSetting } = slice.actions;
export const fetchExamV3ReservationSettings = createAppAsyncThunk('examV3Settings/fetchReservationSetting', async (_, _ref5) => {
    let { dispatch } = _ref5;
    try {
        dispatch(fetchReservationSettingsRequest());
        const result = await api.get({
            endpoint: `/apps/TE_EXAM_V3/reservation-settings`
        });
        dispatch(fetchReservationSettingsSuccess(result?.reservationSettings));
    }
    catch (e) {
        dispatch(fetchReservationSettingsFailure());
        console.error(e);
    }
});
export const saveExamV3ReservationSettings = createAppAsyncThunk('examV3Settings/saveSeatAllocationSettings', async (_, _ref6) => {
    let { dispatch, getState } = _ref6;
    try {
        dispatch(saveReservationSettingsRequest());
        const reservationSettings = examV3ReservationSettingsSelector(getState());
        await api.patch({
            endpoint: '/apps/TE_EXAM_V3/reservation-settings',
            data: reservationSettings
        });
        dispatch(saveReservationSettingsSuccess());
    }
    catch (error) {
        dispatch(saveReservationSettingsFailure());
        console.error(error);
    }
});
export const fetchAllocationMode = createAppAsyncThunk('examV3Settings/fetchSeatAllocationSettings', async (_, _ref7) => {
    let { dispatch } = _ref7;
    try {
        dispatch(fetchAllocationModeRequest());
        const result = await api.get({
            endpoint: `/apps/TE_EXAM_V3/seat-allocation-settings`
        });
        dispatch(fetchAllocationModeSuccess(result.seatAllocationSettings));
    }
    catch (e) {
        dispatch(fetchAllocationModeFailure());
        console.error(e);
    }
});
export const saveAllocationMode = createAppAsyncThunk('examV3Settings/saveSeatAllocationSettings', async (_, _ref8) => {
    let { dispatch, getState } = _ref8;
    try {
        dispatch(saveAllocationModeRequest());
        const seatAllocationSettings = seatAllocationSettingsSelector(getState());
        await api.patch({
            endpoint: '/apps/TE_EXAM_V3/seat-allocation-settings',
            data: {
                seatAllocationSettings
            }
        });
        dispatch(saveAllocationModeSuccess());
    }
    catch (error) {
        dispatch(saveAllocationModeFailure());
        console.error(error);
    }
});
