import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { configService } from 'services/config.service';
const initialState = {
    loading: false,
    hasErrors: false
};
const slice = createSlice({
    name: 'integration',
    initialState,
    reducers: {}
});
export default slice.reducer;
export const clearAllocateCache = () => async () => {
    try {
        await api.post({
            endpoint: `${configService.REACT_APP_PATHWAY_SERVICE_URL}v1/registration-allocation/student-set-group-member/cache`
        });
    }
    catch (e) {
        return console.error(e);
    }
};
