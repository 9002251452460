import { createSlice } from '@reduxjs/toolkit';
import { createRole as create } from '@timeedit/types';
import { createAppAsyncThunk } from 'slices/utils';
import { addEntityToState, deleteEntityFromState, finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, prepEntityForAPICall, stateHasFetchedAllObjectsSuccess, upsertEntity } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0
};
// Slice
const slice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        fetchRolesRequest: state => {
            isLoadingRequest(state);
        },
        fetchRolesSuccess: (state, _ref) => {
            let { payload } = _ref;
            stateHasFetchedAllObjectsSuccess(payload, state, create);
            finishedLoadingSuccess(state);
        },
        fetchRolesFailure: state => {
            finishedLoadingFailure(state);
        },
        createRole: state => {
            addEntityToState(state, {
                id: 0,
                name: 'New role'
            }, create);
            finishedLoadingSuccess(state);
        },
        saveRoleRequest: state => {
            isLoadingRequest(state);
        },
        saveRoleSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            upsertEntity(state, payload, create);
            finishedLoadingSuccess(state);
        },
        saveRoleFailure: state => {
            finishedLoadingFailure(state);
        },
        deleteRoleRequest: state => {
            isLoadingRequest(state);
        },
        deleteRoleSuccess: (state, _ref3) => {
            let { payload: roleId } = _ref3;
            deleteEntityFromState(roleId, state);
            finishedLoadingSuccess(state);
        },
        deleteRoleFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const rolesLoading = state => state.roles.loading;
export const rolesSelector = state => state.roles.results;
export const roleSelector = id => state => id !== undefined ? state.roles.map[id] : undefined;
export const rolesMapSelector = state => state.roles.map;
// Actions
export const { fetchRolesRequest, fetchRolesSuccess, fetchRolesFailure, createRole, deleteRoleRequest, deleteRoleFailure, deleteRoleSuccess, saveRoleRequest, saveRoleSuccess, saveRoleFailure } = slice.actions;
export const fetchRoles = createAppAsyncThunk('roles/fetchRoles', async (_, _ref4) => {
    let { dispatch } = _ref4;
    try {
        dispatch(fetchRolesRequest());
        const result = await api.get({
            endpoint: `/roles`
        });
        dispatch(fetchRolesSuccess(result));
    }
    catch (e) {
        dispatch(fetchRolesFailure());
        console.error(e);
    }
});
export const saveRole = createAppAsyncThunk('roles/saveRole', async (role, _ref5) => {
    let { dispatch } = _ref5;
    try {
        dispatch(saveRoleRequest());
        /**
         * Could be either create new or update
         * Depending on which, we need to use different methods (POST or PATCH)
         */
        if (role.id === 0) {
            // Create a new one
            const preppedRole = prepEntityForAPICall(role, true, true);
            const savedRole = await api.post({
                endpoint: `/roles`,
                data: preppedRole
            });
            dispatch(saveRoleSuccess(savedRole));
            dispatch(deleteRoleSuccess(0));
        }
        else {
            const preppedRole = prepEntityForAPICall(role, true, true);
            const updatedRole = await api.patch({
                endpoint: `/roles/${role.id}`,
                data: preppedRole
            });
            dispatch(saveRoleSuccess(updatedRole));
        }
    }
    catch (error) {
        dispatch(saveRoleFailure());
        console.error(error);
    }
});
export const copyRole = createAppAsyncThunk('roles/copyRole', async (roleId, _ref6) => {
    let { dispatch, getState } = _ref6;
    try {
        const roleToCopy = getState().roles.map[roleId];
        if (!roleToCopy)
            return;
        dispatch(saveRoleRequest());
        const preppedRole = prepEntityForAPICall(roleToCopy, true, true);
        const copiedRole = await api.post({
            endpoint: `/roles`,
            data: {
                ...preppedRole,
                name: `[COPY] ${preppedRole.name}`
            }
        });
        dispatch(saveRoleSuccess(copiedRole));
    }
    catch (e) {
        dispatch(saveRoleFailure());
        console.error(e);
    }
});
export const deleteRole = createAppAsyncThunk('roles/deleteRole', async (roleId, _ref7) => {
    let { dispatch } = _ref7;
    try {
        dispatch(deleteRoleRequest());
        /**
         * No need to send the request to the backend if the entity hasn't been saved
         */
        if (roleId !== 0)
            await api.delete({
                endpoint: `/roles/${roleId}`
            });
        dispatch(deleteRoleSuccess(roleId));
    }
    catch (e) {
        dispatch(deleteRoleFailure());
        console.error(e);
    }
});
