// TYPES
import { EFieldType } from '@timeedit/types/lib/enums/field.enum';
export const NEW_OBJECT_ID = 0;
export const isFieldOnObject = (allFields, id) => {
    let existing = false;
    allFields.forEach(f => {
        if (f.fieldId === id) {
            existing = true;
        }
    });
    return existing;
};
export const isFieldIdOnObject = (allFields, id) => {
    let existing = false;
    allFields.forEach(f => {
        if (f === id) {
            existing = true;
        }
    });
    return existing;
};
export const getNonReferenceFields = (fields, type) => {
    if (type !== undefined) {
        const parentsOnType = type?.parentFields;
        const fieldsOnType = type?.fields;
        const allFields = parentsOnType?.concat(fieldsOnType);
        const getFieldType = fieldId => {
            const result = fields.find(f => f.id === fieldId);
            if (result) {
                return {
                    id: result.id,
                    fieldType: result.fieldType
                };
            }
            return null;
        };
        const fieldWithFieldType = allFields?.map(field => getFieldType(field));
        const excludeReference = fieldWithFieldType?.filter(field => field?.fieldType !== EFieldType.REFERENCE);
        return excludeReference?.map(field => field?.id);
    }
};
export const moveElementInArray = (array, from, to) => {
    const clonedArray = Object.assign([], array);
    const f = clonedArray.splice(from, 1)[0];
    clonedArray.splice(to, 0, f);
    return clonedArray;
};
export const isNewObject = object => object?.id === NEW_OBJECT_ID;
