import { config as mappingConfig } from '@timeedit/registration-shared';
/**
 * Function to validate mapping based on the config for each app.
 * @param objectTypes - List of object types from the mapping.
 * @returns validation - Object with the validation result.
 */
export function validateMapping(objectTypes) {
    const lf = new Intl.ListFormat('en', {
        style: 'long',
        type: 'conjunction'
    });
    const invalidApps = new Set();
    const validation = {
        apps: [],
        valid: true,
        message: ''
    };
    const appValidation = {
        label: 'Registration and Allocate',
        repos: ['te-consume', 'data-manager'],
        configs: [],
        valid: true
    };
    for (const shorthand in mappingConfig) {
        const config = mappingConfig[shorthand];
        const { props } = config;
        const configValidation = {
            props,
            id: undefined,
            shorthand,
            status: 'valid'
        };
        const objectTypeMappings = objectTypes.filter(obj => obj.applicationObjectTypeGroup === props.objectTypeGroup);
        if (objectTypeMappings.length > 1) {
            configValidation.status = 'duplication';
        }
        const objectType = objectTypeMappings[0];
        let id = objectType?.objectTypeId;
        let extid = objectType?.objectTypeExtId;
        let isField = false;
        if ('appProperty' in props) {
            const field = objectType?.fields.find(field => field.appProperty === props.appProperty);
            id = field?.fieldId;
            extid = field?.fieldExtId;
            isField = true;
        }
        const previouslyMapped = extid !== undefined && extid !== null;
        const extIdValid = isField ? true : checkExtIdValidity(extid);
        const mappingValid = id !== undefined && id !== null;
        configValidation.id = id;
        configValidation.extid = extid;
        if (!mappingValid && config.required) {
            validation.valid = false;
            appValidation.valid = false;
            configValidation.status = previouslyMapped ? 'deprecated' : 'invalid';
            invalidApps.add(appValidation.label);
        }
        if (!mappingValid && !config.required) {
            configValidation.status = 'optional';
        }
        if (mappingValid && !extIdValid) {
            configValidation.status = 'bad_extid';
        }
        appValidation.configs.push(configValidation);
    }
    validation.apps.push(appValidation);
    validation.message = `Mapping invalid for ${lf.format(Array.from(invalidApps))}.`;
    return validation;
}
/**
 * ExtIds with dots in them are invalid.
 */
function checkExtIdValidity(extId) {
    return !/\./.test(extId);
}
