export const ALWAYS = 'ALWAYS';
export const SUBMIT_COMPLETED = 'SUBMIT_COMPLETED';
export const NEVER = 'NEVER';
export const RECIPIENT_IS_NOTIFIED = 'RECIPIENT_IS_NOTIFIED';
export const SHOW_REVIEW_LINK_OPTIONS = {
    ALWAYS: {
        value: ALWAYS,
        label: 'Always'
    },
    SUBMIT_COMPLETED: {
        value: SUBMIT_COMPLETED,
        label: "When the submission has a scheduling status of 'scheduled'"
    },
    RECIPIENT_IS_NOTIFIED: {
        value: RECIPIENT_IS_NOTIFIED,
        label: 'When the recipient is notified with the review link'
    },
    NEVER: {
        value: NEVER,
        label: 'Never'
    }
};
