import { assertListWithIdElements, assertWithId } from './utils';
/**
 * standardized way to upsert one entity from a PATCH or POST API call into the redux sate
 */
export function upsertEntity(state, payload, createFn) {
    const result = createFn(payload);
    if (!(assertWithId(result) && assertListWithIdElements(state.results))) {
        return;
    }
    state.map = {
        ...state.map,
        [result.id]: result
    };
    const idx = state.results.findIndex(el => el.id === result.id);
    if (idx < 0) {
        state.results = [...state.results, result];
        return;
    }
    state.results = [...state.results.slice(0, idx), result, ...state.results.slice(idx + 1)];
}
