import { createSlice } from '@reduxjs/toolkit';
import { createEmptyRelationType } from '@timeedit/types';
import _ from 'lodash';
import { createAppAsyncThunk } from 'slices/utils';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const initialState = {
    loading: false,
    hasErrors: false,
    allRelationTypes: [],
    map: {},
    firstRelationType: null,
    secondRelationType: null
};
// Slice
const slice = createSlice({
    name: 'relationTypes',
    initialState,
    reducers: {
        fetchRelationTypesRequest: state => {
            isLoadingRequest(state);
        },
        fetchRelationTypesSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.allRelationTypes = payload.results;
            state.map = _.keyBy(payload.results, 'id');
            finishedLoadingSuccess(state);
        },
        fetchRelationTypesFailure: state => {
            finishedLoadingFailure(state);
        },
        createRelationTypeSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            if (state.allRelationTypes[0]?.id === 0)
                return;
            state.allRelationTypes.unshift(payload);
            state.firstRelationType = payload;
            state.map[payload.id] = payload;
        },
        deleteRelationTypeRequest: state => {
            isLoadingRequest(state);
        },
        deleteRelationTypeSuccess: (state, _ref3) => {
            let { payload: relationTypeId } = _ref3;
            state.allRelationTypes = state.allRelationTypes.filter(relationType => relationType.id !== relationTypeId);
            state.map[relationTypeId] = undefined;
            if (state.firstRelationType?.id === relationTypeId) {
                state.firstRelationType = null;
            }
            if (state.secondRelationType?.id === relationTypeId) {
                state.secondRelationType = null;
            }
            finishedLoadingSuccess(state);
        },
        deleteRelationTypeFailure: state => {
            finishedLoadingFailure(state);
        },
        updateRelationTypeRequest: state => {
            isLoadingRequest(state);
        },
        updateRelationTypeSuccess: (state, _ref4) => {
            let { payload } = _ref4;
            const index = state.allRelationTypes.findIndex(relationType => relationType.id === payload.id);
            state.allRelationTypes[index] = payload;
            state.map[payload.id] = payload;
            finishedLoadingSuccess(state);
        },
        closeRelationType: (state, _ref5) => {
            let { payload: relationTypeId } = _ref5;
            if (state.firstRelationType?.id === relationTypeId) {
                state.firstRelationType = null;
            }
            if (state.secondRelationType?.id === relationTypeId) {
                state.secondRelationType = null;
            }
        },
        updateRelationTypeFailure: state => {
            finishedLoadingFailure(state);
        },
        setFirstRelationType: (state, _ref6) => {
            let { payload } = _ref6;
            const prevRelationType = state.firstRelationType;
            state.firstRelationType = payload;
            if (prevRelationType?.id === 0) {
                state.allRelationTypes = state.allRelationTypes.filter(relationType => relationType.id !== 0);
                state.map[0] = undefined;
            }
        },
        setSecondRelationType: (state, _ref7) => {
            let { payload } = _ref7;
            state.secondRelationType = payload;
        },
        changeRelationType: (state, _ref8) => {
            let { payload } = _ref8;
            const { isFirstRelationType, property, value } = payload;
            if (isFirstRelationType) {
                state.firstRelationType[property] = value;
            }
            else {
                state.secondRelationType[property] = value;
            }
        }
    }
});
export default slice.reducer;
// Selectors
export const relationTypesLoading = state => state.relationTypes.loading;
export const relationTypesSelector = state => state.relationTypes.allRelationTypes;
export const relationTypeMapSelector = state => state.relationTypes.map;
export const selectedRelationTypeIdsSelector = state => {
    const { firstRelationType, secondRelationType } = state.relationTypes;
    return _.compact([firstRelationType, secondRelationType]).map(relationType => relationType.id);
};
export const firstRelationTypeSelector = state => {
    return state.relationTypes.firstRelationType;
};
export const secondRelationTypeSelector = state => {
    return state.relationTypes.secondRelationType;
};
// Actions
export const { fetchRelationTypesRequest, fetchRelationTypesSuccess, fetchRelationTypesFailure, createRelationTypeSuccess, deleteRelationTypeRequest, deleteRelationTypeSuccess, deleteRelationTypeFailure, updateRelationTypeRequest, updateRelationTypeSuccess, updateRelationTypeFailure, setFirstRelationType, setSecondRelationType, changeRelationType, closeRelationType } = slice.actions;
export const fetchRelationTypes = createAppAsyncThunk('relationTypes/fetchRelationTypes', async (_, _ref9) => {
    let { dispatch } = _ref9;
    try {
        dispatch(fetchRelationTypesRequest());
        const relationTypes = await api.get({
            endpoint: `/relation-types`
        });
        dispatch(fetchRelationTypesSuccess(relationTypes));
    }
    catch (e) {
        dispatch(fetchRelationTypesFailure());
        return console.error(e);
    }
});
export const createUnsavedRelationType = () => async (dispatch) => {
    const response = createEmptyRelationType();
    dispatch(createRelationTypeSuccess(response));
};
export const copyRelationType = relationType => dispatch => {
    const response = {
        ...relationType,
        id: 0,
        name: `${relationType.name} (copy)`
    };
    dispatch(createRelationTypeSuccess(response));
};
export const deleteRelationType = createAppAsyncThunk('relationTypes/deleteRelationType', async (relationTypeId, _ref10) => {
    let { dispatch } = _ref10;
    try {
        dispatch(deleteRelationTypeRequest());
        await api.delete({
            endpoint: `/relation-types/${relationTypeId}`
        });
        dispatch(deleteRelationTypeSuccess(relationTypeId));
    }
    catch (e) {
        dispatch(deleteRelationTypeFailure());
        return console.error(e);
    }
});
export const upsertRelationType = createAppAsyncThunk('relationTypes/upsertRelationType', async (relationType, _ref11) => {
    let { dispatch } = _ref11;
    try {
        dispatch(updateRelationTypeRequest());
        const { id, ...relationTypeBody } = relationType;
        if (id !== 0) {
            const response = await api.patch({
                endpoint: `/relation-types/${id}`,
                data: relationTypeBody
            });
            dispatch(updateRelationTypeSuccess(response));
        }
        else {
            await api.post({
                endpoint: `/relation-types`,
                data: relationTypeBody
            });
            dispatch(fetchRelationTypes());
        }
        dispatch(closeRelationType(relationType.id));
    }
    catch (e) {
        dispatch(updateRelationTypeFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const checkRelationTypeSaveDisabled = isFirstDrawer => (_, getState) => {
    const { relationTypes } = getState();
    const { firstRelationType, secondRelationType, map: relationTypeMapping } = relationTypes;
    if (isFirstDrawer && firstRelationType) {
        return checkSavedDisabled(firstRelationType, relationTypeMapping[firstRelationType.id]);
    }
    if (!isFirstDrawer && secondRelationType) {
        return checkSavedDisabled(secondRelationType, relationTypeMapping[secondRelationType.id]);
    }
    return true;
};
export const checkIfFirstRelationTypeExists = () => (_, getState) => !!getState().relationTypes.firstRelationType;
