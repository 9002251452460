export const findAllCombinations = ids => {
    const findCombinations = ids => {
        if (ids.length <= 1)
            return [[-1], ids];
        const currentId = ids.shift();
        const nextCombinations = findCombinations(ids);
        // If some id is missing, we push -1 in place
        return [...nextCombinations.map(combination => [-1, ...combination]), ...nextCombinations.map(combination => [currentId, ...combination])];
    };
    const result = findCombinations(ids);
    // Remove empty combination
    return result.slice(1);
};
