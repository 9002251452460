import { useMemo, useRef } from 'react';
import { useTableDimensions } from './useDimensions';
export const useDefaultTableProps = function () {
    let heightMargin = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 50;
    const contentRef = useRef(null);
    const { height: tableHeight, width: tableWidth } = useTableDimensions(contentRef);
    const scroll = useMemo(() => {
        return {
            x: tableWidth || 700,
            y: tableHeight - heightMargin || 300
        };
    }, [tableHeight, tableWidth, heightMargin]);
    return [contentRef, {
            scroll,
            virtual: true,
            pagination: false,
            rowKey: 'id',
            size: 'small'
        }];
};
