import api from '../services/api.service';
export const getAllData = async function (options) {
    let method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'post';
    const totalValue = [];
    const { results, page, totalPages, limit } = await api[method](options);
    totalValue.push(...results);
    let lastPage = page;
    // Fetch the rest of the data in case there are more than 1 page
    for (let i = page + 1; i <= totalPages; i++) {
        const { results } = await api[method]({
            ...options,
            data: {
                ...(options?.data || {}),
                page: i
            }
        });
        totalValue.push(...results);
        lastPage = i;
    }
    return {
        results: totalValue,
        totalPages,
        limit: limit,
        page: lastPage
    };
};
