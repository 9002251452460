import { createSlice } from '@reduxjs/toolkit';
import { createEmptyAvailabilityRelationType } from '@timeedit/types';
import _ from 'lodash';
import { createAppAsyncThunk } from 'slices/utils';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
// TODO: Figure out the proper type of Limitation
export const initialState = {
    loading: false,
    hasErrors: false,
    allAvailabilityRelations: [],
    limitations: {},
    availabilityRelationMap: {},
    firstAvailabilityRelation: null,
    secondAvailabilityRelation: null
};
// Slice
const slice = createSlice({
    name: 'availabilityTypes',
    initialState,
    reducers: {
        fetchAvailabilityRelationsRequest: state => {
            isLoadingRequest(state);
        },
        fetchAvailabilityRelationsSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.allAvailabilityRelations = payload.results;
            state.availabilityRelationMap = _.keyBy(payload.results, 'id');
            finishedLoadingSuccess(state);
        },
        fetchAvailabilityRelationsFailure: state => {
            finishedLoadingFailure(state);
        },
        deleteAvailabilityRelationRequest: state => {
            isLoadingRequest(state);
        },
        deleteAvailabilityRelationSuccess: (state, _ref2) => {
            let { payload: availabilityRelationId } = _ref2;
            state.allAvailabilityRelations = state.allAvailabilityRelations.filter(availabilityType => availabilityType.id !== availabilityRelationId);
            state.availabilityRelationMap[availabilityRelationId] = undefined;
            finishedLoadingSuccess(state);
        },
        deleteAvailabilityRelationFailure: state => {
            finishedLoadingFailure(state);
        },
        updateAvailabilityRelationRequest: state => {
            isLoadingRequest(state);
        },
        updateAvailabilityRelationSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            const index = state.allAvailabilityRelations.findIndex(availabilityType => availabilityType.id === payload.id);
            state.allAvailabilityRelations[index] = payload;
            state.availabilityRelationMap[payload.id] = payload;
            finishedLoadingSuccess(state);
        },
        updateAvailabilityRelationFailure: state => {
            finishedLoadingFailure(state);
        },
        setFirstAvailabilityRelation: (state, _ref4) => {
            let { payload } = _ref4;
            const prevItem = state.firstAvailabilityRelation;
            state.firstAvailabilityRelation = payload;
            if (prevItem?.id === 0) {
                state.availabilityRelationMap[0] = undefined;
                state.allAvailabilityRelations = state.allAvailabilityRelations.filter(ar => ar.id !== 0);
            }
        },
        setSecondAvailabilityRelation: (state, _ref5) => {
            let { payload } = _ref5;
            state.secondAvailabilityRelation = payload;
        },
        createEmptyAvailabilityRelation: state => {
            if (state.firstAvailabilityRelation?.id === 0)
                return;
            const newItem = createEmptyAvailabilityRelationType();
            state.firstAvailabilityRelation = newItem;
            state.availabilityRelationMap[newItem.id] = newItem;
            state.allAvailabilityRelations.unshift(newItem);
        },
        duplicateAvailabilityRelation: (state, _ref6) => {
            let { payload } = _ref6;
            if (state.firstAvailabilityRelation?.id === 0)
                return;
            const newItem = {
                ...payload,
                id: 0,
                name: `${payload.name} (copy)`,
                extId: ''
            };
            state.firstAvailabilityRelation = newItem;
            state.availabilityRelationMap[newItem.id] = newItem;
            state.allAvailabilityRelations.unshift(newItem);
        },
        changeAvailabilityRelation: (state, _ref7) => {
            let { payload } = _ref7;
            const { isFirstDrawer, key, value } = payload;
            if (isFirstDrawer) {
                state.firstAvailabilityRelation = {
                    ...state.firstAvailabilityRelation,
                    [key]: value
                };
            }
            else {
                state.secondAvailabilityRelation = {
                    ...state.secondAvailabilityRelation,
                    [key]: value
                };
            }
        },
        discardChanges: (state, _ref8) => {
            let { payload } = _ref8;
            const { isFirstDrawer, id } = payload;
            if (isFirstDrawer) {
                state.firstAvailabilityRelation = state.availabilityRelationMap[id];
            }
            else {
                state.secondAvailabilityRelation = state.availabilityRelationMap[id];
            }
        },
        closeDrawer: (state, _ref9) => {
            let { payload: id } = _ref9;
            if (state.firstAvailabilityRelation?.id === id) {
                state.firstAvailabilityRelation = null;
            }
            if (state.secondAvailabilityRelation?.id === id) {
                state.secondAvailabilityRelation = null;
            }
        }
    }
});
export default slice.reducer;
// Selectors
export const availabilityRelationsSelector = state => state.availabilityTypes.allAvailabilityRelations;
export const firstAvailabilityRelationSelector = state => state.availabilityTypes.firstAvailabilityRelation;
export const secondAvailabilityRelationSelector = state => state.availabilityTypes.secondAvailabilityRelation;
export const selectedAvailabilityRelationIdsSelector = state => _.compact([state.availabilityTypes.firstAvailabilityRelation, state.availabilityTypes.secondAvailabilityRelation]).map(ar => ar.id);
export const availabilityRelationMapSelector = state => state.availabilityTypes.availabilityRelationMap;
export const availabilityRelationLoadingSelector = state => state.availabilityTypes.loading;
// Actions
export const { fetchAvailabilityRelationsRequest, fetchAvailabilityRelationsSuccess, fetchAvailabilityRelationsFailure, deleteAvailabilityRelationRequest, deleteAvailabilityRelationSuccess, deleteAvailabilityRelationFailure, updateAvailabilityRelationRequest, updateAvailabilityRelationSuccess, updateAvailabilityRelationFailure, setFirstAvailabilityRelation, setSecondAvailabilityRelation, createEmptyAvailabilityRelation, duplicateAvailabilityRelation, changeAvailabilityRelation, discardChanges, closeDrawer } = slice.actions;
export const fetchAvailabilityRelations = createAppAsyncThunk('availabilityTypes/fetchAvailabilityRelations', async (_, _ref10) => {
    let { dispatch } = _ref10;
    try {
        dispatch(fetchAvailabilityRelationsRequest());
        const availabilityTypes = await api.get({
            endpoint: `/availability-types`
        });
        dispatch(fetchAvailabilityRelationsSuccess(availabilityTypes));
    }
    catch (e) {
        dispatch(fetchAvailabilityRelationsFailure());
        return console.error(e);
    }
});
export const deleteAvailabilityRelation = createAppAsyncThunk('availabilityTypes/fetchAvailabilityRelations', async (availabilityTypeId, _ref11) => {
    let { dispatch } = _ref11;
    try {
        dispatch(deleteAvailabilityRelationRequest());
        await api.delete({
            endpoint: `/availability-types/${availabilityTypeId}`
        });
        dispatch(deleteAvailabilityRelationSuccess(availabilityTypeId));
        dispatch(closeDrawer(availabilityTypeId));
    }
    catch (e) {
        dispatch(deleteAvailabilityRelationFailure());
        return console.error(e);
    }
});
export const saveAvailabilityRelation = createAppAsyncThunk('availabilityTypes/fetchAvailabilityRelations', async (availabilityType, _ref12) => {
    let { dispatch } = _ref12;
    try {
        dispatch(updateAvailabilityRelationRequest());
        const { id, ...availabilityRelationBody } = availabilityType;
        if (id !== 0) {
            const response = await api.patch({
                endpoint: `/availability-types/${id}`,
                data: availabilityRelationBody
            });
            dispatch(updateAvailabilityRelationSuccess(response));
        }
        else {
            await api.post({
                endpoint: `/availability-types`,
                data: availabilityRelationBody
            });
            dispatch(fetchAvailabilityRelations());
        }
        dispatch(closeDrawer(id));
    }
    catch (e) {
        dispatch(updateAvailabilityRelationFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const checkFirstAvailabilityRelationExists = () => (dispatch, getState) => !!getState().availabilityTypes.firstAvailabilityRelation;
export const checkAvailabilityRelationSaveDisabled = isFirstDrawer => (dispatch, getState) => {
    const { availabilityTypes } = getState();
    const { firstAvailabilityRelation, secondAvailabilityRelation, availabilityRelationMap } = availabilityTypes;
    if (isFirstDrawer && firstAvailabilityRelation && availabilityRelationMap[firstAvailabilityRelation.id]) {
        return checkSavedDisabled(firstAvailabilityRelation, availabilityRelationMap[firstAvailabilityRelation.id]);
    }
    if (!isFirstDrawer && secondAvailabilityRelation && availabilityRelationMap[secondAvailabilityRelation.id]) {
        return checkSavedDisabled(secondAvailabilityRelation, availabilityRelationMap[secondAvailabilityRelation.id]);
    }
    return true;
};
