import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api.service';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
export const initialState = {
    loading: false,
    hasErrors: false,
    maintenance: [],
    messageMaintenanceResponse: ''
};
// Slice
const slice = createSlice({
    name: 'maintenance',
    initialState,
    reducers: {
        fetchMaintenanceRequest: state => {
            isLoadingRequest(state);
        },
        fetchMaintenanceSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.maintenance = payload;
            finishedLoadingSuccess(state);
        },
        fetchMaintenanceFailure: state => {
            finishedLoadingFailure(state);
        },
        updateMaintenanceRequest: state => {
            isLoadingRequest(state);
        },
        updateMaintenanceFailure: state => {
            finishedLoadingFailure(state);
        },
        messageMaintenanceSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            state.messageMaintenanceResponse = payload;
        },
        updateMessageMaintenanceSuccess: state => {
            finishedLoadingSuccess(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const maintenanceLoading = state => state.maintenance.loading;
export const messageMaintenanceResponse = state => state.maintenance.messageMaintenanceResponse;
export const maintenanceSelector = state => state.maintenance.maintenance;
// Actions
export const { fetchMaintenanceRequest, fetchMaintenanceSuccess, fetchMaintenanceFailure, updateMaintenanceRequest, updateMaintenanceFailure, messageMaintenanceSuccess, updateMessageMaintenanceSuccess } = slice.actions;
export const fetchMaintenance = () => async (dispatch) => {
    try {
        dispatch(fetchMaintenanceRequest());
        const maintenance = await api.get({
            endpoint: `/system-maintenance`
        });
        dispatch(fetchMaintenanceSuccess(maintenance.results));
    }
    catch (e) {
        dispatch(fetchMaintenanceFailure());
        return console.error(e);
    }
};
export const MAINTENANCE_CLEAR_CACHE_ID = 'maintenance-clear-cache';
export const runMaintenance = maintenanceItemId => async (dispatch) => {
    try {
        dispatch(updateMaintenanceRequest());
        const response = await maintenanceRequest(maintenanceItemId);
        dispatch(messageMaintenanceSuccess(response.message ?? ''));
        dispatch(updateMessageMaintenanceSuccess());
    }
    catch (e) {
        dispatch(updateMaintenanceFailure());
        return console.error(e);
    }
};
const maintenanceRequest = async (maintenanceItemId) => maintenanceItemId === MAINTENANCE_CLEAR_CACHE_ID ? api.post({
    endpoint: `/integration/maintenance/clear-cache`
}) : api.patch({
    endpoint: `/system-maintenance/${maintenanceItemId}`
});
