import { combineReducers } from 'redux';
// SLICES
import uiReducer from './ui.slice';
import authReducer from './auth.slice';
import languagesReducer from './language.slice';
import reservationModeReducer from './reservationMode.slice';
import reservationTemplateReducer from './reservationTemplate.slice';
import fieldReducer from './field.slice';
import relationTypeReducer from './relationType.slice';
import fieldRelationReducer from './fieldRelation.slice';
import availabilityTypeReducer from './availabilityRelation.slice';
import timeRuleReducer from './timeRule.slice';
import periodReducer from './period.slice';
import holidayReservationReducer from './holidayReservation.slice';
import suppportInfoReducer from './supportInfo.slice';
import emailPreferencesReducer from './emailPreferences.slice';
import systemPreferencesReducer from './systemPreferences.slice';
import timeZonesReducer from './timeZone.slice';
import maintenanceReducer from './maintenance.slice';
import systemActionsReducer from './systemActions.slice';
import objectReducer from './object.slice';
import authenticationTokenReducer from './authenticationToken.slice';
import prefWeekNamesSlice from './appsPrefWeekNames';
import appsPrefReviewSettingsSlice from './appsPrefReviewSettings';
import appsReportSourceTypeSlice from './appsReportSourceType';
import membershipsReducer from './memberships.slice';
/**
 * REWORKED
 */
import authConfigReducer from './authConfig.slice';
import userReducer from './user.slice';
import apiKeysReducer from './apiKeys.slice';
import permissionReducer from './permission.slice';
import organizationReducer from './organization.slice';
import organizationHierarchyReducer from './organizationHierarchy.slice';
import rolePermissionsReducer from './rolePermission.slice';
import rolesReducer from './roles.slice';
import paddingRulesReducer from './paddingRules.slice';
import appsIntegrationSettingsReducer from './appsIntegrationSettings.slice';
import importsReducer from './import.slice';
import logsReducer from './logs.slice';
import reservationSettingReducer from './reservationSettings.slice';
import examInCoreReducer from './examInCoreSettings.slice';
import objectRelationshipReducer from './objectRelationships.slice';
import appServiceReducer from './appService.slice';
import integrationReducer from './integration.slice';
import navigationSlice from './navigation.slice';
import objectTypesSlice from './objectTypes.slice';
import dynamicAvailabilityReducer from './dynamicAvailability.slice';
import assignablePermissionReducer from './assignablePermission.slice';
import reportTemplateReducer from './reportTemplate.slice';
import reportingLogsReducer from './reportingLogs.slice';
import examV3SettingsReducer from './examV3Settings.slice';
const rootReducer = combineReducers({
    ui: uiReducer,
    auth: authReducer,
    languages: languagesReducer,
    reservationModes: reservationModeReducer,
    reservationTemplates: reservationTemplateReducer,
    fields: fieldReducer,
    memberships: membershipsReducer,
    relationTypes: relationTypeReducer,
    fieldRelations: fieldRelationReducer,
    availabilityTypes: availabilityTypeReducer,
    timeRules: timeRuleReducer,
    periods: periodReducer,
    holidayReservations: holidayReservationReducer,
    supportInfo: suppportInfoReducer,
    emailPreferences: emailPreferencesReducer,
    systemPreferences: systemPreferencesReducer,
    timeZones: timeZonesReducer,
    maintenance: maintenanceReducer,
    systemActions: systemActionsReducer,
    objects: objectReducer,
    authenticationTokens: authenticationTokenReducer,
    integration: integrationReducer,
    // REWORKED
    assignablePermissions: assignablePermissionReducer,
    permissions: permissionReducer,
    authConfigs: authConfigReducer,
    users: userReducer,
    apiKeys: apiKeysReducer,
    organizations: organizationReducer,
    organizationHierarchy: organizationHierarchyReducer,
    rolePermissions: rolePermissionsReducer,
    roles: rolesReducer,
    paddingRules: paddingRulesReducer,
    appsIntegrationSettings: appsIntegrationSettingsReducer,
    imports: importsReducer,
    logs: logsReducer,
    reservationSettings: reservationSettingReducer,
    examInCoreSettings: examInCoreReducer,
    objectRelationships: objectRelationshipReducer,
    appService: appServiceReducer,
    // APP - PREF
    prefWeekNames: prefWeekNamesSlice,
    appsPrefReviewSettings: appsPrefReviewSettingsSlice,
    appsReportSourceType: appsReportSourceTypeSlice,
    navigation: navigationSlice,
    //
    objectTypes: objectTypesSlice,
    dynamicAvailability: dynamicAvailabilityReducer,
    reportTemplate: reportTemplateReducer,
    reportingLogs: reportingLogsReducer,
    examV3Settings: examV3SettingsReducer
});
export default rootReducer;
