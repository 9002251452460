import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppSelector } from 'slices/store';
import { ENABLE_STUDENT_LOAD, EXAM_SETTINGS_ORG, OBJECT_MANAGER_ORG_IDS, REPORTING_SETTINGS_ORG_IDS } from '../constants/featureFlag.constants';
import { userSelector } from '../slices/auth.slice';
import { organizationSelector } from '../slices/organization.slice';
/**
 * We currently use two types of feature flags:
 * 1. Toggle flags: These are boolean flags that are either true or false.
 * 2. Organization flags: These are flags that are an array of organization IDs.
 */
export const useLaunchDarklyFlag = flagKey => {
    const flags = useFlags();
    return flags[flagKey];
};
export function useFeatureFlag(flagKey) {
    let checkCustomerSignature = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const flag = useLaunchDarklyFlag(flagKey) ?? [];
    const user = useAppSelector(userSelector);
    const organization = useAppSelector(organizationSelector);
    if (typeof flag === 'boolean') {
        return flag;
    }
    if (!checkCustomerSignature) {
        return flag.includes(user?.organizationId);
    }
    return flag.includes(user?.organizationId) || flag.includes(organization?.customerSignature);
}
export const useEnableNewReportingSettings = () => useFeatureFlag(REPORTING_SETTINGS_ORG_IDS);
export const useEnableObjectManager = () => useFeatureFlag(OBJECT_MANAGER_ORG_IDS);
export const useEnableStudentLoad = () => useFeatureFlag(ENABLE_STUDENT_LOAD);
export const useEnableExamV3 = () => useFeatureFlag(EXAM_SETTINGS_ORG, true);
