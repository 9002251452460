import dayjs from 'dayjs';
export const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const localeFormat = 'sv-SE';
// const timeZone: string = 'UTC'; // @todo: Do we need to change this to a let based on users prefs as well?
// // Finding the correct localeFormat based on set country and language for the user
// // @todo: Adjust this to act based on the users' country, when we have it from the rest-api
// const findLocaleFormat = (country: string, language: string) => {
//   localeFormat = `${language}-${country}`;
// };
export const timeConverter = unixTimestamp => {
    if (unixTimestamp === null) {
        return null;
    }
    const a = new Date(unixTimestamp * 1000);
    const dateObject = a.toLocaleString( /* localeFormat, {
                                        timeZone: timeZone,
                                        } */);
    return dateObject;
};
export const timeConverterDate = unixTimestamp => {
    if (unixTimestamp === null) {
        return null;
    }
    const a = new Date(unixTimestamp * 1000);
    const dateObject = a.toLocaleString(localeFormat);
    return dateObject;
};
export const getLocalDisplayFormatFromTimestamp = function (timestamp) {
    let unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'ms';
    if (!timestamp)
        return '';
    let _timestamp;
    if (typeof timestamp === 'string') {
        _timestamp = parseInt(timestamp, 10);
    }
    else {
        _timestamp = timestamp;
    }
    _timestamp = unit === 'sec' ? _timestamp * 1000 : _timestamp;
    return dayjs(_timestamp).format(TIME_FORMAT);
};
export const secondToHourMinute = sec => {
    if (!sec)
        return [undefined, undefined];
    return [Math.floor(sec / 3600), Math.floor(sec / 60) % 60];
};
export const hourMinuteToSecond = _ref => {
    let [hour, min] = _ref;
    if (!hour && !min)
        return undefined;
    return (hour || 0) * 3600 + (min || 0) * 60;
};
export const timeStringToSecond = timeString => {
    return timeString.split(':').reduce((acc, time) => 60 * acc + +time, 0);
};
export const secondToTimeString = (sec, format) => {
    return dayjs().startOf('day').add(sec, 'second').format(format || 'HH:mm:ss');
};
