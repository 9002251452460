import { createSlice } from '@reduxjs/toolkit';
import { createOrganizationNode as createTOrganizationNode, createOrganizationTreeNode } from '@timeedit/types';
import { current } from 'immer';
import { compact, get, keyBy, uniq } from 'lodash';
import { startAppListening } from 'slices/listenerMiddleware';
import { createAppAsyncThunk } from 'slices/utils';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, prepEntityForAPICall } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const NEW_ORG_NODE_ID = 'new_node';
export const initialState = {
    loading: false,
    hasErrors: false,
    firstDrawer: null,
    secondDrawer: null,
    map: {},
    nodes: [],
    selectedOrganizationNodeIds: [],
    tree: {
        id: 'root',
        name: 'root',
        virtualId: 'root',
        children: []
    },
    treeMap: {
        byVirtualId: {},
        virtualIdsById: {}
    }
};
// Slice
const slice = createSlice({
    name: 'organizationHierarchy',
    initialState,
    reducers: {
        organizationHierarchyReducerRequest: state => {
            isLoadingRequest(state);
        },
        organizationHierarchyReducerSuccess: (state, _ref) => {
            let { payload } = _ref;
            const { tree, nodes } = payload;
            const nodeTree = nodes.map(node => createTOrganizationNode(node));
            state.tree = createOrganizationTreeNode(tree);
            state.treeMap = initializeVirtualIdMap(state.tree);
            state.nodes = nodeTree;
            state.map = keyBy(nodeTree, 'id');
            finishedLoadingSuccess(state);
        },
        organizationHierarchyReducerFailure: state => {
            finishedLoadingFailure(state);
        },
        updateDrawer: (state, _ref2) => {
            let { payload } = _ref2;
            const { isFirstDrawer, data } = payload;
            if (isFirstDrawer) {
                state.firstDrawer = data;
            }
            else {
                state.secondDrawer = data;
            }
        },
        addNewNodeLocal: (state, _ref3) => {
            let { payload: { newNode, parentTreeNode } } = _ref3;
            state.map[newNode.id] = newNode;
            state.firstDrawer = newNode;
            updateChildren([state.tree], parentTreeNode, newNode);
        },
        deleteNodeLocal: state => {
            state.map[NEW_ORG_NODE_ID] = undefined;
            deleteChildren([state.tree]);
        },
        onDiscardForm: (state, _ref4) => {
            let { payload } = _ref4;
            const { isFirstDrawer, id } = payload;
            if (isFirstDrawer) {
                state.firstDrawer = state.map[id];
            }
            else {
                state.secondDrawer = state.map[id];
            }
        },
        onRowClick: (state, _ref5) => {
            let { payload } = _ref5;
            const { record } = payload;
            const firstDrawer = state?.firstDrawer ? current(state?.firstDrawer) : null;
            const nodesMap = current(state.map);
            if (firstDrawer && get(firstDrawer, 'key') === record.key) {
                state.firstDrawer = null;
            }
            else {
                if (record.key) {
                    state.firstDrawer = {
                        ...nodesMap[record.value],
                        idTable: record.id
                    };
                }
            }
        },
        handleCompare: (state, _ref6) => {
            let { payload } = _ref6;
            const { record } = payload;
            const firstDrawer = state?.firstDrawer ? current(state?.firstDrawer) : null;
            const nodesMap = current(state.map);
            if (firstDrawer) {
                state.secondDrawer = {
                    ...nodesMap[record.value],
                    idTable: record.id
                };
            }
            else {
                state.firstDrawer = {
                    ...nodesMap[record.value],
                    idTable: record.id
                };
            }
        },
        setSelectedOrganizationNodeIds: (state, _ref7) => {
            let { payload } = _ref7;
            state.selectedOrganizationNodeIds = payload;
        }
    },
    selectors: {
        selectedOrganizationNodeIdsSelector: state => state.selectedOrganizationNodeIds
    }
});
export default slice.reducer;
function initializeVirtualIdMap(node) {
    let map = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        byVirtualId: {},
        virtualIdsById: {}
    };
    map.byVirtualId[node.virtualId] = node.id;
    map.virtualIdsById[node.id] = [...(map.virtualIdsById[node.id] || []), node.virtualId];
    node.children?.forEach(child => initializeVirtualIdMap(child, map));
    return map;
}
// Selectors
export const organizationHierarchyLoading = state => state.organizationHierarchy.loading;
export const organizationTreeSelector = state => state.organizationHierarchy.tree;
export const organizationNodesSelector = state => state.organizationHierarchy.nodes;
export const organizationMapSelector = state => state.organizationHierarchy.map;
export const selectOrganizationNodeLeaves = state => {
    /**
     * Two definitions of leaves:
     * - All tree nodes without children
     * - All nodes with no other node having it as a parent
     * This function implements the second version
     */
    const nodes = state.organizationHierarchy.nodes || [];
    // Get a unique list of all node ids that are parents
    const allParentIds = uniq(nodes.reduce((allKeys, node) => {
        allKeys.push(...node.parentNodeIds);
        return allKeys;
    }, []));
    // Filter through all nodes, excluding items which are in the list of parentIds
    return nodes.filter(node => !allParentIds.includes(node.id || ''));
};
export const selectFirstDrawer = state => state.organizationHierarchy.firstDrawer;
export const selectSecondDrawer = state => state.organizationHierarchy.secondDrawer;
export const selectTreeMap = state => state.organizationHierarchy.treeMap;
export const fetchOrganizationHierarchy = createAppAsyncThunk('organizationHierarchy/fetchOrganizationHierarchy', async (_, _ref8) => {
    let { dispatch } = _ref8;
    try {
        dispatch(organizationHierarchyReducerRequest());
        const organizationHierarchy = await api.get({
            endpoint: `/organization-nodes`
        });
        dispatch(organizationHierarchyReducerSuccess(organizationHierarchy));
    }
    catch (e) {
        dispatch(organizationHierarchyReducerFailure());
        return console.error(e);
    }
});
export const createOrganizationNodeLocal = (parentNode, parentTreeNode) => dispatch => {
    const newNode = {
        id: NEW_ORG_NODE_ID,
        virtualId: NEW_ORG_NODE_ID,
        name: 'New node',
        description: '',
        parentNodeIds: parentNode?.id ? [parentNode?.id] : [],
        isEdited: true
    };
    dispatch(addNewNodeLocal({
        newNode,
        parentTreeNode
    }));
};
export const createOrganization = createAppAsyncThunk('organizationHierarchy/createOrganization', async (newNode, _ref9) => {
    let { dispatch } = _ref9;
    try {
        dispatch(organizationHierarchyReducerRequest());
        const organizationHierarchy = await api.post({
            endpoint: `/organization-nodes`,
            data: {
                name: newNode.name,
                description: newNode.description,
                parentNodeIds: newNode.parentNodeIds,
                extId: newNode.extId
            }
        });
        dispatch(updateDrawer({
            isFirstDrawer: true,
            data: null
        }));
        dispatch(organizationHierarchyReducerSuccess(organizationHierarchy));
    }
    catch (e) {
        dispatch(organizationHierarchyReducerFailure());
        return console.error(e);
    }
});
export const saveOrganizationNode = createAppAsyncThunk('organizationHierarchy/saveOrganizationNode', async (_ref10, _ref11) => {
    let { isFirstDrawer, organizationNode } = _ref10;
    let { dispatch } = _ref11;
    try {
        dispatch(organizationHierarchyReducerRequest());
        /**
         * This will always be a PATCH, since - unlike a lot of the other resources -
         * nodes are created and saved on the server side before being returned to the client
         */
        const preppedOrganizationNode = prepEntityForAPICall(organizationNode, true, true);
        const organizationHierarchy = await api.patch({
            endpoint: `/organization-nodes/${organizationNode.id}`,
            data: preppedOrganizationNode
        });
        dispatch(organizationHierarchyReducerSuccess(organizationHierarchy));
        if (typeof isFirstDrawer === 'boolean') {
            dispatch(updateDrawer({
                isFirstDrawer,
                data: {
                    ...organizationNode,
                    isEdited: false
                }
            }));
        }
    }
    catch (error) {
        dispatch(organizationHierarchyReducerFailure());
        return console.error(error);
    }
});
export const deleteOrganizationNode = createAppAsyncThunk('organizationHierarchy/deleteOrganizationNode', async (_ref12, _ref13) => {
    let { isFirstDrawer, organizationNodeId } = _ref12;
    let { dispatch } = _ref13;
    try {
        dispatch(organizationHierarchyReducerRequest());
        const organizationHierarchy = await api.delete({
            endpoint: `/organization-nodes/${organizationNodeId}`
        });
        dispatch(organizationHierarchyReducerSuccess(organizationHierarchy));
        if (isFirstDrawer !== undefined) {
            dispatch(updateDrawer({
                isFirstDrawer,
                data: null
            }));
        }
    }
    catch (e) {
        dispatch(organizationHierarchyReducerFailure());
        console.error(e);
    }
});
const updateChildren = (tree, parentTree, newNode) => {
    if (!tree) {
        return;
    }
    return tree.find(item => {
        if (item.virtualId === parentTree.key) {
            // @ts-expect-error FIXME state.tree has children, newNode does not
            item.children = [...(item.children || []), newNode];
            return true;
        }
        else {
            return updateChildren(item.children, parentTree, newNode);
        }
    });
};
const deleteChildren = tree => {
    return tree.find((item, index, array) => {
        if (item.virtualId === NEW_ORG_NODE_ID) {
            array.splice(index, 1);
            return true;
        }
        else {
            return deleteChildren(item.children);
        }
    });
};
export const { organizationHierarchyReducerRequest, organizationHierarchyReducerSuccess, organizationHierarchyReducerFailure, updateDrawer, addNewNodeLocal, deleteNodeLocal, onDiscardForm, onRowClick, handleCompare, setSelectedOrganizationNodeIds } = slice.actions;
export const { selectedOrganizationNodeIdsSelector } = slice.selectors;
startAppListening({
    predicate: (_, currentState, previousState) => currentState.organizationHierarchy.firstDrawer !== previousState.organizationHierarchy.firstDrawer || currentState.organizationHierarchy.secondDrawer !== previousState.organizationHierarchy.secondDrawer,
    effect: (_, _ref14) => {
        let { dispatch, getState } = _ref14;
        const { organizationHierarchy: { firstDrawer, secondDrawer } } = getState();
        dispatch(setSelectedOrganizationNodeIds(compact([firstDrawer ? firstDrawer.idTable || `${firstDrawer.id ?? ''}#1` : null, secondDrawer ? secondDrawer.idTable : null])));
    }
});
export const checkDrawerExist = () => (_, getState) => !!getState().organizationHierarchy.firstDrawer || !!getState().organizationHierarchy.secondDrawer;
