/**
 * @function isLoadingRequest
 * @description standardized way of indicating API call is currently loading in redux state
 * @param {IDefaultReducerState} state
 * @returns {void}
 */
export const isLoadingRequest = state => {
    state.loading = true;
    state.hasErrors = false;
};
