import { useEffect, useRef } from 'react';
// This hook will first return the currrent value in ref, and set the new value to ref
// Use case: when we want to check state change of a variable
export const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
